import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// @mui

// ----------------------------------------------------------------------

VIN.propTypes = {
  vin: PropTypes.string.isRequired,
};

export default function VIN({ intl, vin }) {
  return (
    <>
      <Typography variant="body2">{vin}</Typography>
    </>
  );
}
