import { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// @mui
import { Typography, Stack, Box, Link } from '@mui/material';
import { Price } from '../../components/car-labels';
import PageContext from '../../contexts/PageContext';

// ----------------------------------------------------------------------

CarPriceInfo.propTypes = {
  car: PropTypes.object.isRequired,
};

export default function CarPriceInfo({ car }) {
  const navigate = useNavigate();
  const pageContext = useContext(PageContext);
  const { isLoggedIn } = pageContext;
  const isBidAuctionType = ['bid'].includes(car.auctionType)

  return (
    <Box sx={{
      display: 'flex', flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      alignContent: 'baseline',
      alignItems: 'baseline',
      p: 2,
      pt: .5
    }}>

      <Box sx={{ display: 'flex', alignContent: 'right', alignItems: 'end', flexGrow: 2, pr: 2 }}>
        {isLoggedIn && <Typography
          variant="body1"
          // color='text.secondary'
          component={'div'}
          color="black"
          sx={{
            textAlign: 'right',
            flexGrow: 1,
            flexShrink: 0,
          }}
        >
          <b>
            {isBidAuctionType
              ? (<FormattedMessage id="commons.price.priceNotAvailable" />)
              : (<FormattedMessage id="car.price.buyFor" />)
            }
            {` `}
          </b>
        </Typography>
        }
      </Box>

      <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'end' }}>
        <Typography
          variant="h4"
          // color='text.secondary'
          component={'div'}
          color="primary"
          sx={{ textAlign: 'right', width: '100%', flexShrink: 0, }}
        >
          {/* <FormattedMessage id="commons.eur" /> {car.price.selling} <FormattedMessage id="car.price.withVAT" />
           */}
          {isLoggedIn
            ? (
              isBidAuctionType
                ? (<FormattedMessage id="commons.auctiontype.bid" />)
                : <Price price={car.price} />
            )
            : <Link
              href="/login"
              onClick={e => {
                setTimeout(() => {
                  pageContext.setReturnTo(`/car/${car.vin}`);
                }, 1)
              }}>
              <FormattedMessage id="commons.price.loginforprice" />{` `}
            </Link>
          }
        </Typography>
      </Box>
    </Box>
  );
}
