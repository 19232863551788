import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { TextField, Button, Box, Divider, Typography, InputAdornment, IconButton } from '@mui/material';
import { authService } from '../../services';
import Iconify from '../../components/iconify';
import { passwordRegexp } from '../../config/config';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .matches(passwordRegexp, 'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.')
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
  givenName: Yup.string().required('First name is required'),
  familyName: Yup.string().required('Last name is required'),
  companyName: Yup.string(), // .required('Company name e is required'),
  vat: Yup.string(), // .required('VAT is required'),
});

const RegistrationForm = ({ setFormProcessed }) => {
  const navigate = useNavigate();
  const intl = useIntl()
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <Formik
      initialValues={{ email: '', password: '', confirmPassword: '', givenName: '', familyName: '' }}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors = {};
        const isFormFilled = Object.values(values).some(value => value !== '');
        if (isFormFilled) {
          // You can add custom validation here if needed
          // For example, you might want to validate pre-filled values differently
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        // console.log('Form Data:', values);
        const { confirmPassword,
          email,
          givenName,
          familyName,
          password,
          companyName,
          vat
        } = values
        try {
          const response = await authService.signup({
            email,
            givenName,
            familyName,
            password,
            companyName,
            vat
          })
          setSubmitting(false); // Manually set submitting to false after submission
          // console.log('signup response', response)
          enqueueSnackbar(intl.formatMessage({ id: 'page.signup.confirmed-snackbar' }))
          // navigate('/unverified');
          setFormProcessed(true) // navigate from parent component
        } catch (err) {
          navigate('/');
        }
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              name="email"
              type="email"
              label="Email"
              variant="outlined"
              helperText={<ErrorMessage name="email" />}
              error={errors.email}
              touched={touched.email}
            />
          </Box>
          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              name="password"
              // type="password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              variant="outlined"
              helperText={<ErrorMessage name="password" />}
              error={errors.password}
              touched={touched.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              name="confirmPassword"
              // type="password"
              type={showConfirmPassword ? 'text' : 'password'}
              label="Confirm Password"
              variant="outlined"
              helperText={<ErrorMessage name="confirmPassword" />}
              error={errors.confirmPassword}
              touched={touched.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                      <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Divider sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <FormattedMessage id='page.signup.subtitle.contact-info' />
            </Typography>
          </Divider>

          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              name="givenName"
              type="text"
              label="First Name"
              variant="outlined"
              helperText={<ErrorMessage name="givenName" />}
              error={errors.givenName}
              touched={touched.givenName}
            />
          </Box>
          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              name="familyName"
              type="text"
              label="Last Name"
              variant="outlined"
              helperText={<ErrorMessage name="familyName" />}
              error={errors.familyName}
              touched={touched.familyName}
            />
          </Box>

          <Divider sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <FormattedMessage id='page.signup.subtitle.company-info' />
            </Typography>
          </Divider>

          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              name="companyName"
              type="text"
              label="Company Name"
              variant="outlined"
              helperText={<ErrorMessage name="companyName" />}
              error={errors.companyName}
              touched={touched.companyName}
            />
          </Box>
          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              name="vat"
              type="text"
              label="VAT number"
              variant="outlined"
              helperText={<ErrorMessage name="vat" />}
              error={errors.vat}
              touched={touched.vat}
            />
          </Box>
          <Box mt={3}>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              loading={isSubmitting}>
              <FormattedMessage id="page.signup.button.register" />
            </LoadingButton>
          </Box>
        </Form>
      )}
    </Formik>
  )
};

export default RegistrationForm;
