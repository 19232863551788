import { Box } from '@mui/material';
import PropTypes from 'prop-types';
// @mui

// ----------------------------------------------------------------------

Model.propTypes = {
  model: PropTypes.string.isRequired,
};

export default function Model({ intl, model }) {
  return <Box component="span">{model}</Box>;
}
