import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// @mui

// ----------------------------------------------------------------------

Mileage.propTypes = {
  mileage: PropTypes.number.isRequired,
};

export default function Mileage({ intl, mileage }) {
  return (<Box component="span">
    {mileage} < FormattedMessage id="commons.km" />
  </Box>);
}
