import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// @mui

// ----------------------------------------------------------------------

EngineSize.propTypes = {
  engineSize: PropTypes.number,
};

export default function EngineSize({ intl, engineSize }) {
  return (
    <>
      {engineSize} <FormattedMessage id="commons.cc" />
    </>
  );
}
