import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Button,
  IconButton,
  Autocomplete,
  TextField,
  Box,
  Chip,
  useMediaQuery,
} from '@mui/material';

// utils
// component
import Iconify from '../../components/iconify';
import SearchContext from '../../contexts/SearchContext';
import PageContext from '../../contexts/PageContext';
import { fieldsService } from '../../services';
import { SearchOption } from '../../types/SearchOption';
import CarsFilterDialog from './CarsFilterDialog';
// ----------------------------------------------------------------------

const StyledStack = styled('div')(({ theme }) => ({
  // backgroundColor: 'pink',
  width: '100%',
  // [theme.breakpoints.up('md')]: {
  //   width: 'auto',
  // },
  // [theme.breakpoints.down('md')]: {
  //   width: '100%',
  // }
}));
const StyledSearchbar = styled('div')(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }),
  // top: 0,
  // left: 0,
  // zIndex: 99,
  width: '100%',
  display: 'flex',
  // position: 'relative',
  // position: 'absolute',
  // alignItems: 'center',
  // height: isCarsPage ? undefined : APP_BAR_MOBILE,
  // padding: theme.spacing(0, 3),
  // padding: theme.spacing(0, 1),
  // boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    // height: isCarsPage ? undefined : APP_BAR_DESKTOP,
    // padding: theme.spacing(0, 5),
    // padding: theme.spacing(0, 1),
  },
}));

// ----------------------------------------------------------------------

export default function SearchbarMobile({ isCarPage, disabled }) {
  const intl = useIntl();
  const theme = useTheme();
  const pageContext = useContext(PageContext);
  // const { user } = pageContext;

  const searchContext = useContext(SearchContext);
  const [searchFields, setSearchFields] = useState([]); // options
  const [isResetFilterButtonVisible, setIsResetFilterButtonVisible] = useState(false);

  useEffect(() => {
    let cancelled = false;
    const loadSearchFields = async () => {
      // ie: [{field:'make', values:['Audi','Volvo']}]
      try {
        const fields = await fieldsService.getFields();
        if (!cancelled) {
          const options = fields
            .flatMap(({ field, values, isMakeModel }) =>
              values.map((value) => new SearchOption({ field, value, isMakeModel }))
            )
            .sort((a, b) => a.getLabel(intl).localeCompare(b.getLabel(intl)))
            ;
          setSearchFields(options);
        }
      } catch (err) {
        // do nothing
      }
    };
    loadSearchFields() // .then(handleSearch());
    // loadSearchFields().then(handleSearch());
    return () => {
      cancelled = true;
    };
  }, [pageContext.user]);

  useEffect(() => {
    const result = searchContext.searchOptionsFilterBased(searchFields)
    setIsResetFilterButtonVisible(result && result.length > 0)
  }, [searchFields, searchContext.searchOptions])

  // const handleOpen = (e) => {
  //   handleOpenFilter();
  // };

  const handleSearch = async () => {
    if (isCarPage) {
      await searchContext.navigateToSearch();
    } else {
      await searchContext.search();
    }
    // handleClose();
  };

  // const handleNumberOfResultClick = (e) => {
  //   if (isCarPage) {
  //     searchContext.navigateToSearch();
  //   } else if (openFilter) {
  //     handleClose();
  //   } else {
  //     handleOpen();
  //   }
  // };

  const resetAndSearch = async () => {
    await searchContext.reset({ executeSearch: false })
  }

  const handleResetFromAutocomplete = async () => {
    await doResetOnlyAutocomplete()
  }

  const handleResetFromButton = async () => {
    await resetAndSearch()
  }

  const doResetOnlyExtenalFilters = async () => {
    const searchOptionsAutocompleteBased = searchContext.searchOptionsAutocompleteBased(searchFields);
    if (searchOptionsAutocompleteBased.length === 0) {
      await resetAndSearch();
    } else {
      await searchContext.removeSearchOption(searchOptionsAutocompleteBased, { onlyCount: false });
    }
  };
  const doResetOnlyAutocomplete = async () => {
    const searchOptionsFilterBased = searchContext.searchOptionsFilterBased(searchFields);
    if (searchOptionsFilterBased.length === 0) {
      await resetAndSearch();
    } else {
      await searchContext.removeSearchOption(searchOptionsFilterBased, { onlyCount: false });
    }
  };

  // const handleClose = (e) => {
  //   // console.log('SearchBarMobile.handleClose called');
  //   // handleCloseFilter();
  // };


  return (
    // <Card>
    //   <CardContent>
    // <ClickAwayListener onClickAway={handleClose}>
    <>
      <StyledSearchbar>
        <StyledStack
          sx={{
            display: 'flex',
            // alignItems: 'flex-end',
            // justifyContent: 'flex-end',
            // flexGrow: 1,
            // flexWrap: {
            //   xs: 'nowrap',
            //   // sm: 'nowrap'
            // },
            // height: "fit-content",
            py: 1,
            // backgroundColor: 'yellow'
          }}
          direction="row"
          spacing={1}
        >
          <Autocomplete
            disabled={disabled}
            sx={{
              // fontWeight: 'fontWeightBold',
              // flexShrink: { xs: 0, sm: 1 },
              flexShrink: { xs: 1 },
              // flexGrow: 0,
              // backgroundColor: 'red',
              // pr: { xs: 0, md: 1 }
              '& button.MuiButtonBase-root': {
                // make clearable always visible
                visibility: 'visible',
              },
            }}
            id="searchAutocomplete"
            disableClearable={searchContext.searchOptionsAutocompleteBased(searchFields).length === 0}
            multiple
            autoFocus
            fullWidth
            disableunderline="true"
            placeholder={intl.formatMessage({ id: "cars.searchbar.placeholder.searchYourCar" })}
            defaultValue={[]}
            value={searchContext.searchOptionsAutocompleteBased(searchFields)}
            onChange={(event, newValues, reason) => {
              const searchOptionFilterBased = searchContext.searchOptionsFilterBased(searchFields);
              const newValuesWithFilterBasedOptions = [...searchOptionFilterBased, ...newValues];
              switch (reason) {
                case 'removeOption': {
                  searchContext.removeSearchOption(newValuesWithFilterBasedOptions, { onlyCount: false });
                  break;
                }
                case 'clear': {
                  handleResetFromAutocomplete();
                  break;
                }
                case 'selectOption': {
                  searchContext.addSearchOption(newValuesWithFilterBasedOptions, { onlyCount: false });
                  break;
                }
                case 'createOption':
                default:
              }
            }}
            options={searchFields}
            // freeSolo
            getOptionKey={(option) => option && option.getKey()}
            getOptionLabel={(option) => option.getLabel(intl)}
            isOptionEqualToValue={(option, value) =>
              value && value instanceof SearchOption && option.getKey() === value.getKey()
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                sx={{
                  width: '100%',
                  // backgroundColor: 'blue'
                }}
                // label="Type multiple values, ie: Volvo XC60 Diesel, Audi A4, Tesla S 2019"
                placeholder={intl.formatMessage({ id: "cars.searchbar.placeholder.searchYourCar" })}
              // InputProps={{
              //   startAdornment: (
              //     // <IconButton
              //     //   sx={{ pl: 1 }}
              //     //   // variant="contained"
              //     //   color='primary'
              //     //   onClick={handleSearch}
              //     //   // loading={searchContext.isLoading}
              //     //   loadingPosition="start">
              //     //   <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              //     // </IconButton>
              //     <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              //   ),
              //   // endAdornment: (
              //   //   <CarsFilterSidebar
              //   //     hideButton
              //   //     openFilter={openFilter}
              //   //     handleOpenFilter={e => handleOpen()}
              //   //     handleCloseFilter={e => handleClose()}
              //   //   />
              //   // )
              // }}
              // endAdornment={
              //   <InputAdornment position="end">
              //     <IconButton
              //       aria-label="toggle password visibility"
              //     >
              //       <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              //     </IconButton>
              //   </InputAdornment>
              // }
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.getKey()}
                  variant="outlined"
                  label={option.getLabel(intl)}
                  {...getTagProps({ index })}
                />
              ))
            }
          />

          {/* </Box> */}

          {/* {searchContext.searchOptionsFilterBased(searchFields).length > 0 &&
            searchContext.searchOptionsAutocompleteBased(searchFields).length === 0 ? (
            <IconButton
              onClick={handleReset} color='primary'>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          ) : null} */}

          <CarsFilterDialog
            isMobile
            hideButton
          />
        </StyledStack>
      </StyledSearchbar>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          // backgroundColor: 'pink'
        }}
      >
        <Box
          sx={{
            flexShrink: 1,
            // backgroundColor: 'red'
          }}
        >
          <>
            {searchContext.searchOptionsFilterBased(searchFields).map((option, index) => (
              <Chip
                key={option.getKey()}
                variant="outlined"
                color="primary"
                sx={{ mt: 0.3, mr: 0.3 }}
                label={option.getLabel(intl)}
                onDelete={(e) =>
                  searchContext.removeSearchOption(
                    searchContext.searchOptions.filter((o) => option.getKey() !== o.getKey()),
                    {}
                  )
                }
              />
            ))}
            {true && isResetFilterButtonVisible ? (
              // <Box>
              <Button
                onClick={handleResetFromButton}
                color="primary"
                // variant="text"
                sx={{
                  px: 0.5,
                  py: 0,
                  textTransform: 'lowercase',
                  fontSize: undefined,
                }}
              >
                <FormattedMessage
                  id="cars.searchbar.button.resetFilter" />
              </Button>
              // </Box>
            ) : null}
          </>
        </Box>
        {false && isResetFilterButtonVisible ? (
          <Box
            sx={{
              flexShrink: 0,
              // backgroundColor: 'yellow'
            }}
          >
            <IconButton onClick={handleResetFromButton} color="primary">
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Box>
        ) : null}
      </Box>
    </>
    // </ClickAwayListener >
    //   </CardContent>
    // </Card>
  );
}
