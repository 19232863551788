import { FormattedMessage } from 'react-intl';
// @mui
import { Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function KeySpecComponentTv({ intl, label, horizontal = false, small, big, bigger, children }) {
  const variant = bigger ? 'h2' : big ? 'h3' : small ? 'h6' : 'h4';
  // const variant = bigger ? 'h4' : big ? 'h5' : small ? 'body2' : 'h6';
  return (
    <>
      <Stack
        direction={horizontal ? 'row' : 'column'}
        spacing={1}
        sx={{
          py: horizontal ? undefined : 1,
          display: 'block',
          // minWidth: { sm: '12rem', xs: '8rem' }
        }}
      >
        <Typography variant={variant} noWrap>
          {children}
        </Typography>

        {label ? (
          <Typography variant="body1">
            <FormattedMessage id={label} />
          </Typography>
        ) : null}
      </Stack>
    </>
  );
}
