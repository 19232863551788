import { Helmet } from 'react-helmet-async';
import { useIntl, FormattedMessage } from 'react-intl';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
// @mui
import { Container, Typography } from '@mui/material';
// components
import authService from '../services/auth.service';
import PageContext from '../contexts/PageContext';

export default function AuthCallbackPage() {
  const navigate = useNavigate();
  const intl = useIntl();

  const { state } = useParams();

  const pageContext = useContext(PageContext);

  useEffect(() => {
    const loadUserInformation = async () => {
      // console.log(`state: ${state}`)
      try {
        // sleep(100)
        const json = await authService.getTokenFromState(state);
        const { user, tokens } = json;
        pageContext.login(user, tokens);
        navigate(pageContext.getReturnTo());
      } catch (err) {
        // console.log('no refresh token found')
        // navigate(`/login?message=${err}`);
        navigate('/login?message=err');
      }
      // TODO redirect to user page (based on the role or status of last activity)
    };

    loadUserInformation();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'application.name' })}
          {' | '}
          {intl.formatMessage({ id: 'page.title.auth-callback' })}
        </title>
      </Helmet>

      <Container>
        <Typography variant="caption2" sx={{ mb: 5 }}>
          <FormattedMessage id="page.auth-callback.please-wait" />
        </Typography>
      </Container>
    </>
  );
}
