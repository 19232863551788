import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
// @mui

// ----------------------------------------------------------------------

FirstRegistration.propTypes = {
  firstRegistrationDate: PropTypes.string,
  firstRegistrationAsYear: PropTypes.string,
};

export default function FirstRegistration({ intl, firstRegistrationDate, firstRegistrationAsYear }) {
  return (
    <>
      <Box component="span">
        {firstRegistrationDate}
        {firstRegistrationAsYear}
      </Box>
    </>
  );
}
