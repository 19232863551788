import { Box, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
// @mui

// ----------------------------------------------------------------------

Fuel.propTypes = {
  fuel: PropTypes.string.isRequired,
};

export default function Fuel({ intl, fuel }) {
  const displayLabel = fuel && fuel.length > 15 ? `${fuel.substring(0, 15)}..` : fuel
  return <Box component="span" >
    <Tooltip title={fuel}>
      {displayLabel}
    </Tooltip>
  </Box>;
}
