import { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
// @mui
import { Typography, Slider } from '@mui/material';
// components
import SearchContext from '../../contexts/SearchContext';
import { filterUnique } from '../../utils/filterUnique';
import { numFormatter } from '../../utils/numFormatterForFilter';
import { scaleByMarks } from '../../utils/scale';
import { DEFAULT_POWER_RANGE } from '../../config/config';

// ----------------------------------------------------------------------
// kilowatts = hp(I) × 0.7457
// kilowatts = hp(E) × 0.746
// 1 kW = 1.341022 hp(I)

PowerFilter.propTypes = {};

export default function PowerFilter() {
  const intl = useIntl();
  // TODO add switch
  const powerScaleSelected = intl.formatMessage({ id: 'commons.hp' });
  const searchContext = useContext(SearchContext);
  const { powerRange, setPowerRange, setPowerFilters } = searchContext;

  const [powerMin, powerMax] = DEFAULT_POWER_RANGE;

  const powerMarks = [
    {
      value: powerMin,
      scaledValue: 1,
      label: '1',
    },
    {
      value: powerMin + Math.round((powerMax - powerMin) * 0.25),
      scaledValue: 100,
      label: '100',
    },
    {
      value: powerMin + (powerMax - powerMin) * 0.5,
      scaledValue: 180,
      label: '180',
    },
    {
      value: powerMin + (powerMax - powerMin) * 0.75,
      scaledValue: 300,
      label: '300',
    },
    {
      value: powerMax,
      scaledValue: 600,
      label: '600+',
    },
  ];
  const scalePower = scaleByMarks(powerMarks);

  const powerValuetext = (value) => `${value}`;

  const powerLabel = (powerRange) => {
    const powers = powerRange
      .filter(filterUnique)
      .map((x) => scalePower(x))
      .map((x) => numFormatter(x));
    return powers.length > 1
      ? `Power: ${powers.join('->')} ${powerScaleSelected}`
      : `Power: ${powers.join('->')} ${powerScaleSelected}`;
  };

  const handlePowerRangeChange = (event, newRange) => {
    setPowerRange(newRange);
    searchContext.updateRangeAndFilter(newRange, DEFAULT_POWER_RANGE, 'power', scalePower);
  };

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage id="filter.power.title" /> ({powerScaleSelected})
      </Typography>
      <Slider
        valueLabelDisplay="auto"
        min={powerMin}
        max={powerMax}
        step={1}
        valueLabelFormat={numFormatter}
        getAriaLabel={() => 'Registration year range'}
        value={powerRange}
        onChange={handlePowerRangeChange}
        marks={powerMarks}
        scale={scalePower}
        getAriaValueText={powerValuetext}
      />
      {/* <Typography variant="subtitle2" gutterBottom>
        {powerLabel(powerRange)}
      </Typography> */}
    </div>
  );
}
