const config = require('../config/config');
const { get, post } = require('../utils/fetch');

const { BE_URL: beURL } = config;

const login = async (email, password) => post(`${beURL}/auth/login`, { email, password });

const getTokenFromState = async (state) => get(`${beURL}/auth/token-by-state/${state}`);

const getLoggedUser = async () => get(`${beURL}/auth/logged-user`, undefined, { useRefreshToken: true });

const postRefreshTokens = async (refreshToken) => post(`${beURL}/auth/refresh-tokens`, { refreshToken });

const signup = async (values) => post(`${beURL}/auth/register`, values);

const forgotPassword = async (email) => post(`${beURL}/auth/forgot-password`, { email });

const resetPassword = async (password, token) => post(`${beURL}/auth/reset-password?token=${token}`, { password });

const verifyEmail = async (token) => post(`${beURL}/auth/verify-email?token=${token}`);

module.exports = {
  login,
  getTokenFromState,
  postRefreshTokens,
  getLoggedUser,
  signup,
  forgotPassword,
  resetPassword,
  verifyEmail
};
