import { Box } from '@mui/material';
import PropTypes from 'prop-types';
// @mui

// ----------------------------------------------------------------------

Gearbox.propTypes = {
  gearboxType: PropTypes.string.isRequired,
  gearbox: PropTypes.number,
};

export default function Gearbox({ intl, gearboxType, gearbox }) {
  return (
    <Box component="span">
      {/* {gearboxType} {gearbox && gearbox > 0 ? `(${gearbox})` : null} */}
      {gearboxType}
    </Box>
  );
}
