import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
// @mui
import { Typography, CardHeader } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// ----------------------------------------------------------------------

ServiceHistorySection.propTypes = {
  serviceHistory: PropTypes.array,
  showHeader: PropTypes.bool,
  useCardHeader: PropTypes.bool,
};

export default function ServiceHistorySection({ serviceHistory, useCardHeader = false, showHeader = false }) {
  const intl = useIntl();

  const columns4DataGrid = [
    // { field: 'id', headerName: 'ID', width: 70 },
    // { field: '// chassis,', headerName: '// chassis,', width: 150}
    { field: 'date', headerName: intl.formatMessage({ id: 'car.serviceHistory.date' }) },
    { field: 'mileage', headerName: intl.formatMessage({ id: 'car.serviceHistory.mileage' }) },
    { field: 'company', headerName: intl.formatMessage({ id: 'car.serviceHistory.company' }) },
    { field: 'description', headerName: intl.formatMessage({ id: 'car.serviceHistory.description' }), flex: 1 },
    { field: 'price', headerName: intl.formatMessage({ id: 'car.serviceHistory.price' }) },
  ];

  return (
    <>
      {' '}
      {serviceHistory && serviceHistory.length > 0 ? (
        <>
          {showHeader ? (
            <>
              {useCardHeader ? (
                <CardHeader title={intl.formatMessage({ id: 'car.section.title.serviceHistory' })} />
              ) : (
                <Typography variant="h4">
                  <FormattedMessage id="car.section.title.serviceHistory" />
                </Typography>
              )}
            </>
          ) : null}
          <DataGrid
            columns={columns4DataGrid}
            rows={serviceHistory.map((service) => ({ ...service, id: service._id }))}
            // autosizeOptions={{
            //     columns: ['date', 'mileage', 'company', 'description', 'price'],
            //     includeOutliers: true,
            //     includeHeaders: false,
            // }}
            hideFooter
          />
        </>
      ) : null}
    </>
  );
}
