import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  IconButton,
  ClickAwayListener,
  Autocomplete,
  TextField,
  Box,
  Chip,
  ButtonGroup,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';

// utils
// component
import Iconify from '../../components/iconify';
import SearchContext from '../../contexts/SearchContext';
import PageContext from '../../contexts/PageContext';
import { fieldsService } from '../../services';
import { SearchOption } from '../../types/SearchOption';
import CarsFilterSidebar from './CarsFilterSidebar';
import CarsFilterDialog from './CarsFilterDialog';
import useResponsive from '../../hooks/useResponsive';
// ----------------------------------------------------------------------

const StyledStack = styled('div')(({ theme }) => ({
  // backgroundColor: 'pink',
  width: '100%',
  // [theme.breakpoints.up('md')]: {
  //   width: 'auto',
  // },
  // [theme.breakpoints.down('md')]: {
  //   width: '100%',
  // }
}));
const StyledSearchbar = styled('div')(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }),
  // top: 0,
  // left: 0,
  // zIndex: 99,
  width: '100%',
  display: 'flex',
  // position: 'relative',
  // position: 'absolute',
  // alignItems: 'center',
  // height: isCarsPage ? undefined : APP_BAR_MOBILE,
  // padding: theme.spacing(0, 3),
  // padding: theme.spacing(0, 1),
  // boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    // height: isCarsPage ? undefined : APP_BAR_DESKTOP,
    // padding: theme.spacing(0, 5),
    // padding: theme.spacing(0, 1),
  },
}));

// ----------------------------------------------------------------------

export default function SearchbarWeb({ openFilter, handleOpenFilter, handleCloseFilter, isCarPage }) {
  const intl = useIntl();
  const theme = useTheme();
  const isLgDown = useResponsive('down', 'lg');

  const pageContext = useContext(PageContext);
  const { user } = pageContext;
  const location = useLocation();

  const searchContext = useContext(SearchContext);
  const [searchFields, setSearchFields] = useState([]); // options

  const isSearchExecuted = searchContext.totalResults >= 0;

  useEffect(() => {
    let cancelled = false;
    const loadSearchFields = async () => {
      // ie: [{field:'make', values:['Audi','Volvo']}]
      try {
        const fields = await fieldsService.getFields();
        if (!cancelled) {
          const options = fields.flatMap(({ field, values, isMakeModel }) =>
            values.map((value) => new SearchOption({ field, value, isMakeModel }))
          );
          setSearchFields(options);
        }
      } catch (err) {
        // do nothing
      }
    };
    loadSearchFields();
    return () => {
      cancelled = true;
    };
  }, []);

  const handleOpen = (e) => {
    handleOpenFilter();
  };

  const handleSearch = async () => {
    if (isCarPage) {
      searchContext.navigateToSearch();
    } else {
      searchContext.search();
    }
    handleClose();
  };

  const handleNumberOfResultClick = (e) => {
    if (isCarPage) {
      searchContext.navigateToSearch();
    } else if (openFilter) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const handleReset = async () => {
    searchContext.reset({});
  };

  const handleClose = (e) => {
    handleCloseFilter();
  };

  return (
    // <Card>
    //   <CardContent>
    <ClickAwayListener onClickAway={handleClose}>
      <>
        <StyledSearchbar>
          <StyledStack
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              flexGrow: 1,
              flexWrap: {
                xs: 'nowrap',
                // sm: 'nowrap'
              },
              height: 'fit-content',
              py: 1,
              // backgroundColor: 'yellow'
            }}
            direction="row"
            spacing={1}
          >
            <Autocomplete
              sx={{
                fontWeight: 'fontWeightBold',
                // flexShrink: { xs: 0, sm: 1 },
                flexShrink: { xs: 1 },
                flexGrow: 0,
                // backgroundColor: 'red',
                // pr: { xs: 0, md: 1 }
              }}
              id="searchAutocomplete"
              multiple
              autoFocus
              fullWidth
              disableunderline="true"
              placeholder={intl.formatMessage({id: "cars.searchbar.placeholder.searchYourCar"})}
              defaultValue={[]}
              value={searchContext.searchOptionsAutocompleteBased()}
              onChange={(event, newValues, reason) => {
                const newValuesWithFilterBasedOptions = [
                  ...searchContext.searchOptionsFilterBased(searchFields),
                  ...newValues,
                ];
                switch (reason) {
                  case 'removeOption': {
                    searchContext.removeSearchOption(newValuesWithFilterBasedOptions, { onlyCount: false });
                    break;
                  }
                  case 'clear': {
                    searchContext.reset({});
                    break;
                  }
                  case 'selectOption': {
                    searchContext.addSearchOption(newValuesWithFilterBasedOptions, { onlyCount: false });
                    break;
                  }
                  case 'createOption':
                  default:
                }
              }}
              options={searchFields}
              // freeSolo
              getOptionKey={(option) => option && option.getKey()}
              getOptionLabel={(option) => option.getLabel(intl)}
              isOptionEqualToValue={(option, value) =>
                value && value instanceof SearchOption && option.getKey() === value.getKey()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Type multiple values, ie: Volvo XC60 Diesel, Audi A4, Tesla S 2019"
                  placeholder={intl.formatMessage({id: "cars.searchbar.placeholder.searchYourCar"})}
                  // InputProps={{endAdornment: isSmDown ? undefined : (<IconButton
                  //   sx={{ pl: 1, flexGrow: 1, flexShrink: 0 }}
                  //   // variant="contained"
                  //   color='primary'
                  //   onClick={handleSearch}
                  //   // loading={searchContext.isLoading}
                  //   loadingPosition="start">
                  //   <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  // </IconButton>)}}
                />
              )}
              // renderTags={(value, getTagProps) =>
              //   value.map((option, index) => (
              //     <Chip key={option.getKey()} variant="outlined" label={option.getLabel(intl)} {...getTagProps({ index })} />
              //   ))
              // }
            />
            <ButtonGroup
              sx={{
                flexGrow: 1,
                flexShrink: {
                  xs: 0,
                  md: 2,
                },
                pt: 1,
                px: 1,
                pb: 0,
                display: 'inline-flex',
                // backgroundColor: 'blue'
                // flexWrap: 'wrap',
                // alignItems: 'flex-end',
                // justifyContent: 'flex-end'
              }}
            >
              {searchContext.searchOptions.length > 0 ? (
                <IconButton onClick={handleReset} color="primary">
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              ) : null}

              {isLgDown ? (
                <CarsFilterDialog
                  openFilter={openFilter}
                  handleOpenFilter={(e) => handleOpen()}
                  handleCloseFilter={(e) => handleClose()}
                />
              ) : (
                <CarsFilterSidebar
                  openFilter={openFilter}
                  handleOpenFilter={(e) => handleOpen()}
                  handleCloseFilter={(e) => handleClose()}
                />
              )}

              {searchContext.isSearchExecuted ? (
                <LoadingButton
                  sx={{
                    flexGrow: 5,
                    flexShrink: 0,
                    display: {
                      xs: 'none',
                      md: 'block',
                    },
                  }}
                  variant={'contained'}
                  loadingPosition="start"
                  loading={searchContext.isLoading}
                  onClick={handleSearch}
                  startIcon={<SearchIcon />}
                >
                  <FormattedMessage
                    id="cars.searchbar.numberOfResults"
                    values={{
                      numberOfResults: searchContext.displayTotalResults,
                    }}
                  />
                </LoadingButton>
              ) : (
                <LoadingButton
                  sx={{
                    flexGrow: 5,
                    display: {
                      xs: 'none',
                      md: 'block',
                    },
                  }}
                  variant="contained"
                  loadingPosition="start"
                  loading={searchContext.isLoading}
                  onClick={handleSearch}
                  startIcon={<SearchIcon />}
                >
                  <FormattedMessage id="cars.searchbar.search" />
                </LoadingButton>
              )}
            </ButtonGroup>
            {/* </Box> */}
          </StyledStack>
        </StyledSearchbar>
        <Box>
          {searchContext.searchOptions
            .filter((o) => !searchFields.find((sf) => sf.getKey() === o.getKey()))
            .map((option, index) => (
              <Chip
                key={option.getKey()}
                variant="outlined"
                color="primary"
                sx={{ mt: 0.3, mr: 0.3 }}
                label={option.getLabel(intl)}
                onDelete={(e) =>
                  searchContext.removeSearchOption(
                    searchContext.searchOptions.filter((o) => option.getKey() !== o.getKey()),
                    {}
                  )
                }
              />
            ))}
        </Box>
      </>
    </ClickAwayListener>
    //   </CardContent>
    // </Card>
  );
}
