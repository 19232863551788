import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, ButtonGroup, Link, Stack, Typography } from '@mui/material';
import { REACT_APP_TARGET_PLATFORM } from '../../config/config';

// ----------------------------------------------------------------------

const LogoTv = forwardRef(({ disabledLink = false, hideLinks, sx, ...other }, ref) => {
  const intl = useIntl();

  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const WHITE = '#fff';
  const BLACK = '#000';

  const logo = (
    <Box>
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 220,
          height: 40,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          ...sx,
          // backgroundColor: 'red',
        }}
        {...other}
      >
        <img src="/assets/logo-grey.png" alt="logo" height="50" />

        <Stack direction={'column'} justifyContent={'center'} sx={{ px: 1 }}>
          <Typography component="span" variant="h4" color={BLACK} >
            <FormattedMessage id="application.tv.name" />
          </Typography>
          {hideLinks ? null : (
            <Stack direction={'row'} justifyContent={'center'} alignItems={'baseline'} >
              <Typography
                variant='body2'
              >
                <Link
                  variant="text"
                  underline="none"
                  href={intl.formatMessage({ id: "header.logo.web.link" })}
                  onClick={e => e.stopPropagation()}
                  sx={{
                    color: '#000',
                    textTransform: 'uppercase'
                  }}>
                  <FormattedMessage id="header.logo.web.value" />
                </Link>
              </Typography>
              <Typography
                variant='body2'
                sx={{ pl: 1 }}>
                <Link
                  disabled
                  underline="none"
                  variant="text"
                  href={intl.formatMessage({ id: "header.logo.tv.link" })}
                  onClick={e => e.stopPropagation()}
                  sx={{
                    color: '#000',
                    textTransform: 'uppercase'
                  }}>
                  <b>
                    <FormattedMessage id="header.logo.tv.value" />
                  </b>
                </Link>
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

LogoTv.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default LogoTv;
