const formatFuelForTV = (fuel) => {
    if (!fuel) {
        return null;
    }
    if (fuel.toLowerCase().startsWith('hybrid')) {
        return 'Hybrid'
    }
    const displayLabel = fuel && fuel.length > 12 ? `${fuel.substring(0, 12)}..` : fuel
    return displayLabel
}


module.exports = {
    formatFuelForTV
}