const config = require('../config/config');
const { get, post } = require('../utils/fetch');

const { BE_URL: beURL } = config;

// const companyProfile = async (email, values) => post(`${beURL}/reseller/${email}/company-profile`, values);

module.exports = {
  // companyProfile,
};
