import { Box } from '@mui/material';
import PropTypes from 'prop-types';
// @mui

// ----------------------------------------------------------------------

NumberOfPlaces.propTypes = {
  numberOfPlaces: PropTypes.number.isRequired,
};

export default function NumberOfPlaces({ intl, numberOfPlaces }) {
  return <Box component="span">{numberOfPlaces}</Box>;
}
