import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import Carousel from 'react-material-ui-carousel';
import { useTheme } from '@emotion/react';

// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Container,
  Paper,
  Card,
  CardHeader,
  Stack,
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Dialog,
  Divider,
  CardActionArea,
  CardContent,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// components
import {
  EquipmentSection,
  ServiceHistorySection,
  ProblemsSection,
  CarBreadcrumb,
  CarsKeySpecsSection,
  CarFeatureSummaryTable,
  CarCarouselImages,
  CarLocationInfo,
  CarContactInfo,
  CarPriceInfo
} from '../sections/car';

import carService from '../services/cars.service';
import useResponsive from '../hooks/useResponsive';
import PageContext from '../contexts/PageContext';
import { Description } from '../components/car-labels';
import { ShareIcon } from '../components/car-images';

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledCarImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const StyledSection = styled(Box)(({ theme }) => ({
  mt: 4,
  mb: 6,
  // paddingTop: HEADER_MOBILE,
  [theme.breakpoints.up('md')]: {
    // paddingTop: HEADER_DESKTOP,
  },
}));

// ----------------------------------------------------------------------
export default function CarPage() {
  const debugInfo = false
  const useAccordion = false;
  const theme = useTheme();

  const isLgDown = useResponsive('down', 'lg');
  const isSmDown = useResponsive('down', 'sm');

  const intl = useIntl();

  const { vin } = useParams();
  const pageContext = useContext(PageContext)
  const [car, setCar] = useState();
  const [loading, setLoading] = useState(true);

  const [openFilter, setOpenFilter] = useState(false);
  const [openFullscreenImage, setOpenFullscreenImage] = useState(false);
  const [indexFullscreenImage, setIndexFullscreenImage] = useState(0);

  const handleCloseFullscreenImage = () => {
    setOpenFullscreenImage(false);
  };
  const handleOpenFullscreenImage = (index) => {
    setIndexFullscreenImage(index);
    setOpenFullscreenImage(true);
  };
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  let cancelled = false;

  const loadCar = async () => {
    setLoading(true);

    const response = await carService.getCarByVIN(vin);

    if (!cancelled) {
      setCar(response);
    }
  };

  const onClickHandler = (index, isFullScreen = false) => {
    if (isFullScreen) {
      handleCloseFullscreenImage();
    } else {
      handleOpenFullscreenImage(index);
    }
  };

  useEffect(() => {
    if (vin) {
      pageContext.resetReturnTo()
    }

    loadCar().then(() => setLoading(false));
    return () => {
      cancelled = true;
    };
  }, [vin]);

  // const CarCarouselImages = ({ car, onClickHandler, isFullScreen = false }) => {
  //   return (
  //     <Carousel
  //       interval={2500}
  //       animation={isFullScreen ? 'slide' : 'fade'}
  //       indicators={false}
  //       navButtonsAlwaysVisible
  //       navButtonsProps={{
  //         style: {
  //           backgroundColor: 'primary',
  //           opacity: 0.3,
  //         },
  //       }}
  //     // navButtonsAlwaysVisible={!isFullScreen}
  //     // index={isFullScreen ? indexFullscreenImage : 0}
  //     >
  //       {car.images.map((item, i) => {
  //         // const bgImage = Math.abs((index - i) % images.length) < 2 ? `url(${item})` : undefined
  //         const bgImage = `url(${item})`;
  //         return (
  //           <Box
  //             key={item}
  //             sx={
  //               isFullScreen
  //                 ? {
  //                   width: '100vw',
  //                   height: '100vh',
  //                   // backgroundColor: 'yellow',
  //                   backgroundImage: bgImage,
  //                   backgroundPosition: 'center',
  //                   backgroundRepeat: 'no-repeat',
  //                   backgroundSize: 'contain',
  //                 }
  //                 : {
  //                   // height: '60vh',
  //                   height: 'auto',
  //                   width: '100%',
  //                   // backgroundImage: `url(${BE_URL}/images/${item})`,
  //                   // backgroundImage: bgImage,
  //                   backgroundPosition: 'center',
  //                   backgroundRepeat: 'no-repeat',
  //                   backgroundSize: 'cover',
  //                   // backgroundSize: 'contain'
  //                 }
  //             }
  //             onClick={(e) => onClickHandler(i)}
  //           >
  //             {isFullScreen ? null : <img src={item} alt={car.vin} />}
  //           </Box>
  //         );
  //       })}
  //     </Carousel>
  //   );
  // };

  const FullscreenBox = styled('div')(({ theme }) => ({
    p: 1,
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.grey[900],
  }));

  const FullscreenImageDialog = ({ openFullscreenImage, handleCloseFullscreenImage, car }) => (
    <Dialog
      fullScreen
      open={openFullscreenImage}
      onClose={handleCloseFullscreenImage}
    // TransitionComponent={Transition}
    >
      <FullscreenBox sx={{}}>
        <CarCarouselImages car={car} startIndex={indexFullscreenImage} isFullScreen onClickHandler={onClickHandler} />
      </FullscreenBox>
    </Dialog>
  );

  const IconBar = ({ car, children }) => (
    <Box sx={{ display: 'flex', width: '100%' }}>
      {children}
      <Box flexGrow={1} />
      <ShareIcon car={car} useRelative />
    </Box>
  )

  const PriceWrapper = ({ car }) => (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box flexGrow={4} />
      <CarPriceInfo car={car} />
      <Box flexGrow={1} />
    </Box>
  )

  /** @deprecated */
  const DocumentsSection = ({ car }) =>
    car.documents && car.documents.length > 0 ? (
      <StyledSection id="section3">
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            <Typography variant="h5">
              <FormattedMessage id="car.section.title.documents" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DocumentsSection carId={car.id} documents={car.documents} useCardHeader={false} showHeader={false} />
          </AccordionDetails>
        </Accordion>
        {/* <Card>
          <DocumentsSection carId={car.id} documents={car.documents} useCardHeader />
        </Card> */}
      </StyledSection>
    ) : null;



  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'application.name' })}
          {' | '}
          {car && car.vin ? car.vin : intl.formatMessage({ id: 'page.title.car' })}
        </title>
      </Helmet>

      {!loading && (
        <>
          <Container maxWidth="xl">
            <Box sx={{ pb: 3 }}>
              <CarBreadcrumb car={car} />
            </Box>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              <Box sx={{
                display: {
                  xs: 'none',
                  lg: 'block'
                },
                order: {
                  xs: 1,
                  lg: 2,
                },
                width: {
                  xs: '100%',
                  lg: '33%',
                },
                // p: 1,
                // backgroundColor: 'red',
                // height: '1px'
              }}>
                <Card sx={{ mb: 3 }}
                >
                  <CardContent sx={{
                    "&:last-child": {
                      paddingBottom: theme.spacing(1)
                    }
                  }} >
                    <IconBar car={car} />

                    <Typography
                      color="text.primary"
                      variant="h4"
                      sx={{
                        display: { xs: 'none', lg: 'block' },
                        pb: 1,
                      }}
                    >
                      <Description description={car.description} />
                    </Typography>

                    <Box>
                      <PriceWrapper car={car} />

                      <Box sx={{ flexGrow: 1, pt: 1 }} />
                      <CarsKeySpecsSection car={car} basicInfo />
                      <Divider />
                      <Box sx={{ flexGrow: 1, pt: 1 }} />
                      <CarLocationInfo car={car} />
                    </Box>
                  </CardContent>
                </Card>
                <Card >
                  <CardContent sx={{
                    "&:last-child": {
                      paddingBottom: theme.spacing(1)
                    }
                  }}>
                    <CarContactInfo car={car} showHeader hideDivider >
                      {/* <CarLocationInfo car={car} /> */}
                    </CarContactInfo>
                  </CardContent>
                </Card>
              </Box>

              <Box sx={{
                order: {
                  xs: 2,
                  lg: 1,
                },
                width: {
                  xs: '100%',
                  lg: '66%',
                },
              }}>
                <CarCarouselImages car={car} onClickHandler={onClickHandler} />
              </Box>

              {/* // xs-md */}
              <Box
                sx={{
                  // order: 4,
                  display: {
                    xs: 'block',
                    lg: 'none',
                  },
                  pb: 1,
                  order: 3,
                  width: '100%',
                  // backgroundColor: 'pink'
                }}
              >

                <IconBar car={car}>
                  <Typography color="text.primary" variant="h5">
                    <Description description={car.description} />
                    {/* {car.description} */}
                  </Typography>
                </IconBar>
                <PriceWrapper car={car} />

                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                  {debugInfo ? 'hxs' : null}
                  <CarsKeySpecsSection
                    car={car}
                    isMobile
                    basicInfo
                    isSmall
                  />
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
                  {debugInfo ? 'hsm' : null}
                  <CarsKeySpecsSection
                    car={car}
                    basicInfo
                    isSmall
                  />
                </Box>
              </Box>
            </Box >

            <Stack
              spacing={4}
              sx={{
                width: {
                  xs: '100%',
                  lg: '66%',
                },
                pb: 3,
              }
              }
            >
              {true && <StyledSection id="section2">
                {/* // xs-sm */}
                <Box sx={{
                  // display: {
                  //   xs: 'block',
                  //   md: 'none'
                  // }
                  display: 'none'
                }}>
                  <CarsKeySpecsSection
                    car={car}
                    isMobile
                    basicInfo
                    isSmall={false}
                  />
                  {/* <CarLocationInfo car={car} /> */}

                </Box>

                {/* // md */}
                <Box sx={{
                  display: {
                    xs: 'none',
                    md: 'block',
                    lg: 'none'
                  }
                }}>
                  {debugInfo ? 's1md' : null}
                  <CarsKeySpecsSection
                    car={car}
                    showHeader
                    // basicInfo
                    isSmall
                    columns={4}
                  />
                  {/* <CarLocationInfo car={car} /> */}
                </Box>

                {/* // lg-xl */}
                <Box sx={{
                  display: {
                    xs: 'none',
                    lg: 'block',
                  }
                }}>
                  {debugInfo ? 's1lg' : null}
                  <CarsKeySpecsSection
                    car={car}
                    showHeader
                    exludingBasicInfo
                    isSmall={false}
                  />
                </Box>

                {/* 
                <CarsKeySpecsSection
                  car={car}
                  isMobile={isSmDown}
                  showHeader={isLgDown}
                  isSmall={isLgDown}
                  exludingBasicInfo={!isLgDown}
                /> */}
                {/* <Divider /> */}
              </StyledSection>}

              <StyledSection id="section2b" sx={{
                display: {
                  xs: 'block',
                  lg: 'none'
                }
              }}>
                {/* <Divider /> */}
                <CarContactInfo car={car} showHeader />
              </StyledSection>

              <StyledSection id="section3">
                <Divider />
                <CarFeatureSummaryTable car={car} />
              </StyledSection>
              <StyledSection id="section4">
                <Divider />
                {useAccordion ? (
                  <Accordion expanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                      <Typography variant="h5">
                        <FormattedMessage id="car.section.title.equipment" />
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <EquipmentSection optionals={car.optionals} useCardHeader={false} showHeader={false} />
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <EquipmentSection optionals={car.optionals} useCardHeader={false} showHeader />
                )}
                {/* 
                  <Card >
                    <EquipmentSection optionals={car.optionals} useCardHeader />
                  </Card> */}
              </StyledSection>
              {
                car.problems && (
                  <StyledSection id="section5">
                    <Divider />
                    {useAccordion ? (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Typography variant="h5">
                            <FormattedMessage id="car.section.title.problems" />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ProblemsSection problems={car.problems} useCardHeader={false} showHeader={false} />
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <ProblemsSection problems={car.problems} useCardHeader={false} showHeader />
                    )}
                    {/* <Card>
                      <ProblemsSection problems={car.problems} useCardHeader />
                    </Card> */}
                  </StyledSection>
                )
              }

              {
                car.serviceHistory && car.serviceHistory.length > 0 ? (
                  <StyledSection id="section6">
                    <Divider />
                    {useAccordion ? (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Typography variant="h5">
                            <FormattedMessage id="car.section.title.serviceHistory" />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ServiceHistorySection
                            serviceHistory={car.serviceHistory}
                            useCardHeader={false}
                            showHeader={false}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <ServiceHistorySection serviceHistory={car.serviceHistory} useCardHeader={false} showHeader />
                    )}

                    {/* <Card>
                        <ServiceHistorySection serviceHistory={car.serviceHistory} useCardHeader />
                      </Card> */}
                  </StyledSection>
                ) : null
              }
            </Stack >
          </Container >

          {/* <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            <TimeLineWidget car={car} hasProblems={!!car.problems} />
          </Box> */}
        </>
      )
      }
      <FullscreenImageDialog
        car={car}
        openFullscreenImage={openFullscreenImage}
        handleCloseFullscreenImage={handleCloseFullscreenImage}
      />
    </>
  );
}
