import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { TextField, Link, Button, Box, Stack, Typography, InputAdornment, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import { authService } from '../../services';
import Iconify from '../../components/iconify';
import { passwordRegexp } from '../../config/config';

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    // minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
    // padding: theme.spacing(2, 0, 3, 0),
}));

const ResetPasswordForm = ({ token, setFormProcessed }) => {
    const intl = useIntl()
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const initialValues = {
        password: '', confirmPassword: ''
    };

    const validationSchema = Yup.object({
        password: Yup.string()
            .matches(passwordRegexp, 'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.')
            .min(6, 'Password must be at least 6 characters').required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const onSubmit = async (values, { setSubmitting }) => {
        // console.log('Form data', values);
        try {
            await authService.resetPassword(values.password, token)
            enqueueSnackbar(intl.formatMessage({ id: 'page.reset-password.sent-message' }))
        } catch (err) {
            // do nothing
            console.log('error', err)
        }
        setFormProcessed(true)
        setSubmitting(false);
        setTimeout(() => {
            navigate('/')
        }, 2500)
    };

    return (token
        ? (<Box >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ touched, errors, isSubmitting, isValid, dirty }) => (
                    <Form>
                        <Box mb={2}>
                            <Field
                                as={TextField}
                                fullWidth
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                label="Password"
                                variant="outlined"
                                helperText={<ErrorMessage name="password" />}
                                error={errors.password}
                                touched={touched.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box mb={2}>
                            <Field
                                as={TextField}
                                fullWidth
                                name="confirmPassword"
                                type={showConfirmPassword ? 'text' : 'password'}
                                label="Confirm Password"
                                variant="outlined"
                                helperText={<ErrorMessage name="confirmPassword" />}
                                error={errors.confirmPassword}
                                touched={touched.confirmPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                                                <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box mb={2}>
                            <Button type="submit" variant='contained' disabled={isSubmitting || !isValid} sx={{ my: 2 }}>
                                {isSubmitting
                                    ? intl.formatMessage({ id: 'page.reset-password.button.sending' })
                                    : intl.formatMessage({ id: 'page.reset-password.button.send' })
                                }
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>)
        : (<Box >
            <Typography variant="body2" color="text.secondary">
                <FormattedMessage id="page.reset-password.invalid-token" />
            </Typography>
        </Box>)
    )

};

export default ResetPasswordForm;
