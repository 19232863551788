import PropTypes from 'prop-types';
// @mui
import { Card, styled } from '@mui/material';

// ----------------------------------------------------------------------

CarsHeroSection.propTypes = {
  isTv: PropTypes.bool,
  small: PropTypes.bool,
};

// const HeroImg = styled(CardMedia)(({ theme, isTv, small }) => {
const HeroImg = styled('img')(({ theme, isTv, small }) => ({
  height: '100%',
  objectFit: 'contain',
}));

export default function CarsHeroSection({ isTv, small, ...other }) {
  return (
    <Card
      sx={{
        // mt: small ? 0 : 3,
        mb: small ? 0 : 3,
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: small ? '10vh' : '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        // opacity: .7,
        // backgroundImage: 'url(/assets/images/hero/carsHero2.jpg)',
        // backgroundSize: 'cover', // auto|length|cover|contain|initial|inherit;
        opacity: small ? 0.8 : 0.9,
        // backgroundImage: 'url(/assets/images/hero/hero-section-1.jpg)',
        // backgroundImage: 'url(/assets/images/hero/hero-section-2.jpg)',
        backgroundImage: 'url(/assets/images/hero/hero-section-3.webp)',
        // backgroundImage: 'url(/assets/images/hero/cars-hero.jpg)',
        backgroundSize: 'cover', // auto|length|cover|contain|initial|inherit;
      }}
    >
      {/* {small ? null : (
        <Logo />
      )} */}
    </Card>
  );
}
