import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Tooltip, Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Iconify from '../../components/iconify';
// sections
import { LoginFederationLinks, RegistrationForm } from '.';
import { BE_URL } from '../../config/config';


const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    // minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
    // padding: theme.spacing(2, 0, 3, 0),
}));

export default function SignUpSection() {
    const navigate = useNavigate();

    const [formProcessed, setFormProcessed] = useState(false)

    useEffect(() => {
        if(formProcessed) {
            setTimeout(() => {
                navigate('/');
              }, 7500)
        }
    }, [formProcessed])

    return (
        <StyledContent>
            <Typography variant="h4" gutterBottom>
                <FormattedMessage id="page.signup.title" />
            </Typography>

            {formProcessed
                ? (
                    <Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body2" color="text.primary">
                                <FormattedMessage id="page.signup.confirmed.description1" />
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 5 }}>
                            <Typography variant="body2" color="text.secondary">
                                <FormattedMessage id="page.signup.confirmed.description2" />
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body2" color="text.primary">
                                <FormattedMessage id="page.signup.confirmed.description3" />
                            </Typography>
                        </Box>

                        <Button to="/" size="large" variant="contained" component={RouterLink}>
                            Go to Home
                        </Button>
                    </Box >
                )
                : (
                    <>
                        <Typography variant="body2" sx={{ mb: 5 }}>
                            <FormattedMessage id="page.signup.description" />
                            {''}
                            <span>
                                <Link variant="subtitle2" href={'/login'}>
                                    <FormattedMessage id="page.signup.link.signin" />
                                </Link>
                            </span>
                        </Typography>

                        <LoginFederationLinks />

                        <Divider sx={{ my: 3 }}>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                OR
                            </Typography>
                        </Divider>

                        <RegistrationForm setFormProcessed={setFormProcessed} />
                    </>

                )}
        </StyledContent>
    )
}