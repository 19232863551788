export function randomAvatar(avatarNumber) {
  let avatarUrl;
  if (avatarNumber) {
    avatarUrl = `/assets/images/avatars/avatar_${avatarNumber}.jpg`;
  } else {
    const random24 = Math.floor((Math.random() * 47) % 24);
    avatarUrl = `/assets/images/avatars/avatar_${random24}.jpg`;
  }
  // console.log('avatarUrl', avatarUrl)
  return avatarUrl;
}
