import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { useSearchParams } from "react-router-dom";


// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Box, Stack, Button, Tooltip } from '@mui/material';

import { ResetPasswordForm } from '../sections/auth/index'

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  // padding: theme.spacing(2, 0, 3, 0),
}));


// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const intl = useIntl();

  const [searchParams] = useSearchParams();
  const [token, setToken] = useState();
  const [formProcessed, setFormProcessed] = useState(false)

  useEffect(() => {
    if (searchParams && searchParams.has('token')) {
      setToken(searchParams.get('token'))
    }
  }, [searchParams])

  return (
    <>
      <Helmet>
        {intl.formatMessage({ id: 'application.name' })}
        {' | '}
        {intl.formatMessage({ id: 'page.title.reset-password' })}
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Stack spacing={2} sx={{ pt: 2 }}>
              <Box>
                <Typography variant="h4">
                  <FormattedMessage id="page.reset-password.title" />
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" >
                  <FormattedMessage id="page.reset-password.description" />
                </Typography>
              </Box>

              {formProcessed
                ? (<Box>
                  <Typography variant="body2" color="text.secondary">
                    <FormattedMessage id="page.reset-password.sent-message" />
                  </Typography>
                </Box>)
                : (
                  <>
                    <ResetPasswordForm token={token} setFormProcessed={setFormProcessed} />
                    <Box>
                      <Typography variant='h6'>
                        <FormattedMessage id="page.reset-password.requirements.title" />
                      </Typography>
                    </Box>
                    <Box component="div" sx={{ px: 2 }}>
                      <Typography variant="body2" >
                        <ul>
                          <li><FormattedMessage id="page.reset-password.requirements.description1" /></li>
                          <li><FormattedMessage id="page.reset-password.requirements.description2" /></li>
                          <li><FormattedMessage id="page.reset-password.requirements.description3" /></li>
                          <li><FormattedMessage id="page.reset-password.requirements.description4" /></li>
                          <li><FormattedMessage id="page.reset-password.requirements.description5" /></li>
                        </ul>
                      </Typography>
                    </Box>
                  </>
                )
              }

              <Box component="div" sx={{ pt: 2 }}>
                <Typography variant="body2" component="span">
                  <FormattedMessage id="page.reset-password.return-to" />{' '}
                </Typography>
                <Link variant="subtitle2" underline="hover" href={'/login'}>Sign in</Link>
              </Box>
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
