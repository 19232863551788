import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { useTheme } from '@emotion/react';
import { Stack, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

// component
import { HeaderTv } from './header';
import { FooterTv } from './footer';

// ----------------------------------------------------------------------

const ContentStyled = styled(Stack)({
  minWidth: '100%',
  width: '100%',
  maxWidth: '100%',
  minHeight: '100%',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  aspectRatio: 21 / 9,
  // aspectRatio: 16 / 9,
  // backgroundColor: 'pink',
});

const HeaderTvStyled = styled('div')({
  // mt: '30px',
  minHeight: '10%',
  maxHeight: '10%',
  overflow: 'hidden',
  // backgroundColor: 'pink'
  // backgroundColor: 'blue',
});

const FooterTvStyled = styled('div')({
  minHeight: '8%',
  maxHeight: '8%',
  overflow: 'hidden',
  // backgroundColor: 'grey',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '82%',
  maxHeight: '82%',
  overflow: 'none',
  // backgroundColor: 'yellow',
  [theme.breakpoints.up('lg')]: {},
}));

// ----------------------------------------------------------------------

export default function SimpleLayoutTv(props) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  return (
    // <>
    <ContentStyled>
      <HeaderTvStyled>
        <HeaderTv onChangeLanguage={props.onChangeLanguage} currentLanguage={props.currentLanguage} />
      </HeaderTvStyled>
      <Main>
        <Outlet />
      </Main>
      <FooterTvStyled>
        <FooterTv />
      </FooterTvStyled>
    </ContentStyled>
    // </>
  );
}
