import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles';
import {
  Box, Typography
} from '@mui/material';

const Body = styled('div')({
  margin: 0,
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#000',
});

const TVFrame = styled(Box)(({ theme }) => ({
  width: '90vw',
  height: 'calc(90vw * 9 / 16)',
  maxHeight: '90vh',
  maxWidth: 'calc(90vh * 16/ 9)',
  backgroundColor: '#222',
  border: '20px solid #333',
  borderRadius: '20px',
  boxShadow: '0 0 40px rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
}));

const Screen = styled(Box)({
  width: '100%',
  height: '100%',
  // backgroundColor: '#444',
  backgroundColor: '#fff',
  padding: '2%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

const TVLogo = styled(Typography)({
  position: 'absolute',
  bottom: '10px',
  right: '20px',
  fontSize: '1.2rem',
  // color: '#fff',
  color: '#444',
  opacity: 0.8,
});


// ----------------------------------------------------------------------
export default function TvFrame({ children }) {
  return (
    <Body>
      <TVFrame>
        <Screen>
          {children}
{/*           
          <iframe
            sandbox="allow-same-origin"
            src="https://live.carbid.tv"
            // src="https://www.cars-app.be"
            title="carbid.tv"
            style={{
              width: '100%',
              height: '100%',
              border: 'none'
            }}
          /> */}
          <TVLogo>
            carbid.tv
          </TVLogo>
        </Screen>
      </TVFrame>
    </Body>
  )
};
