const config = require('../config/config');
const { get, post, patch } = require('../utils/fetch');

const { BE_URL: beURL } = config;

const toSearchParams = (params) =>  new URLSearchParams(params).toString();

const getResellers = async (params) => get(`${beURL}/resellers?${toSearchParams(params)}`);

const getReseller = async (resellerId) => get(`${beURL}/resellers/${resellerId}`);

const getResellerByLoggedUser = async () => get(`${beURL}/resellers/by-logged-user`);

const postReseller = async (contactInfo) => post(`${beURL}/resellers`, contactInfo);

const patchReseller = async (resellerId, payload) => patch(`${beURL}/resellers/${resellerId}`, payload);

module.exports = {
  getResellers,
  getReseller,
  getResellerByLoggedUser,
  postReseller,
  patchReseller,
};
