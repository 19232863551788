import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Box, Typography, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// components
import Iconify from '../../components/iconify';
import { authService } from '../../services';
import PageContext from '../../contexts/PageContext';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const pageContext = useContext(PageContext);

  const [showPassword, setShowPassword] = useState(false);

  const doLogin = async (email, password) => {
    try {
      const jsonResponse = await authService.login(email, password);
      const { user, tokens, status } = jsonResponse;
      if(status === 'unverified' ) {
        navigate('/unverified');
      } else {
        pageContext.login(user, tokens);
        navigate(pageContext.getReturnTo());
      }
    } catch (error) {
      // console.log('handleClickLogin', error)
      // console.log('handleClickLogin.name', error.name)
      // console.log('handleClickLogin.statusCode', error.statusCode)
      // console.log('handleClickLogin.message', error.message)
      navigate(`/login?err=${error.message}`);
    }
  };


  const initialValues = {
    email: '',
    password: ''
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
    password: Yup.string().required('Required')
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};
          const isFormFilled = Object.values(values).some(value => value !== '');
          if (isFormFilled) {
            // You can add custom validation here if needed
            // For example, you might want to validate pre-filled values differently
          }
          return errors;
        }}
        // onSubmit={onSubmit}
        onSubmit={async (values, { setSubmitting }) => {
          // console.log('Form Data:', values);
          const { email, password } = values
          await doLogin(email, password)
          setSubmitting(false); // Manually set submitting to false after submission
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <Stack spacing={3}>
              <Field
                as={TextField}
                id="email"
                name="email"
                type="email"
                label="Email address"
                helperText={<ErrorMessage name="email" />}
                error={errors.email}
                touched={touched.email ? 'true' : 'false'}
              />

              <Field
                as={TextField}
                id="password"
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                helperText={<ErrorMessage name="password" />}
                error={errors.password}
                touched={touched.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <Box>
                <Typography variant="body2" >
                  <Checkbox name="remember" />
                  Remember me
                </Typography>
              </Box>

              <span>
                <Link variant="subtitle2" underline="hover" href={'/forgot-password'}>
                  Forgot password?
                </Link>
              </span>
            </Stack>

            <span>
              <LoadingButton
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Login
              </LoadingButton>
            </span>
          </Form>
        )}
      </Formik>
    </>
  );
}
