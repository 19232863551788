import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// @mui
import {
  Box,
  Skeleton,
  Stack,
} from '@mui/material';
// components
import { CarTv, CarsListCarousel } from '../sections/cartv';
import SearchContext from '../contexts/SearchContext';
import { INITIAL_DISPLAY_PREFERENCE } from '../config/config';

const StyledCarsListItem = styled('div')(({ theme }) => ({
  // padding: theme.spacing(3),
  // width: '25%',
}));

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

// ----------------------------------------------------------------------
// 'grid' 'list' 'gallery'
export default function CarsPageTv({ isWeb, isMobile }) {
  const theme = useTheme();
  const intl = useIntl();
  // const height = '40wh'

  const searchContext = useContext(SearchContext);
  const [displayPreference, updateDisplayPreference] = useState(INITIAL_DISPLAY_PREFERENCE);
  const navigate = useNavigate();

  const navigateToPage = (car, searchIndex) => {
    // console.log('navigateToPage')
    searchContext.setCurrentIndex(searchIndex);
    navigate(`/car/${car.vin}`);
  };

  useEffect(() => {
    searchContext.simplifiedSearch({ byKey: 'tv' });
  }, []);

  return (
    <>
      <Helmet>
        <title> {intl.formatMessage({ id: 'application.tv.name' })} </title>
      </Helmet>

      <Stack
        // spacing={2}
        sx={{
          px: 4,
          height: '100%',
          // bgcolor: 'yellow'
        }}
      >
        {searchContext.isLoading ? (
          <>
            <Skeleton variant="rectangular" width={'100%'} height={'75%'} />
            <Skeleton variant="rectangular" width={'100%'} height={'25%'} />
          </>
        ) : searchContext.isSearchExecuted ? (
          searchContext.results.length > 0 ? (
            <>
              <Box
                sx={{
                  height: '75%',
                  width: '100%'
                  // bgcolor: 'blue'
                }}
              >
                <CarTv />
              </Box>

              <Box
                sx={{
                  height: '25%',
                  width: '100%',
                  // bgcolor: 'blue',
                  pt: 2
                }}
              >
                <CarsListCarousel />
              </Box>
            </>
          ) : null
        ) : <>
          <Skeleton variant="rectangular" width={'100%'} height={'75%'} />
          <Skeleton variant="rectangular" width={'100%'} height={'25%'} />
        </>}
      </Stack>
    </>
  );
}
