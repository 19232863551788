import { Helmet } from 'react-helmet-async';
import { useIntl, FormattedMessage } from 'react-intl';

// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Box, Stack, Button, Tooltip } from '@mui/material';

import { ForgotPasswordForm } from '../sections/auth/index'

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  // padding: theme.spacing(2, 0, 3, 0),
}));


// ----------------------------------------------------------------------

export default function ForgotPasswordPage() {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        {intl.formatMessage({ id: 'application.name' })}
        {' | '}
        {intl.formatMessage({ id: 'page.title.forgot-password' })}
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Stack spacing={2} sx={{ pt: 2 }}>
              <Box>
                <Typography variant="h4">
                  <FormattedMessage id="page.forgot-password.title" />
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" >
                  <FormattedMessage id="page.forgot-password.description" />
                </Typography>
              </Box>

              <ForgotPasswordForm />

              <Box>
                <Typography variant='h6'>
                  <FormattedMessage id="page.forgot-password.email-not-received.title" />
                </Typography>
              </Box>
              <Box component="div" >
                <Typography variant="body2" >
                  <FormattedMessage id="page.forgot-password.email-not-received.description" />
                </Typography>
              </Box>
              <Box component="div" sx={{ pt: 2 }}>
                <Typography variant="body2" component="span">
                  <FormattedMessage id="page.forgot-password.return-to" />{' '}
                </Typography>
                <Link variant="subtitle2" underline="hover" href={'/login'}>Sign in</Link>
              </Box>
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
