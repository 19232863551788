import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import Marquee from 'react-fast-marquee';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// @mui
import { Card, Skeleton, CardContent, Typography, Box, Stack } from '@mui/material';
import Label from '../../components/label';
import { carsService } from '../../services/index';
import { Price } from '../../components/car-labels';
import { KeySpecsStack } from '../../components/car-images';


// ----------------------------------------------------------------------


// OVERLAY COMPONENTS
function AdsLabel({ intl }) {
  return (
    <Label
      variant="outlined"
      dimension="small"
      color="grey"
      sx={{
        position: 'absolute',
        opacity: .7,
        top: '1rem',
        right: '1.5rem',
      }}
    >
      <i>PROMOTED</i>
    </Label>
  );
}


PriceLabel.propTypes = {
  price: PropTypes.object,
};
function PriceLabel({ intl, price }) {
  if (!price) {
    return null;
  }

  return false && (
    <Box
      sx={{
        backgroundColor: 'background.neutral',
        borderRadius: .3,
        py: 0.3,
        px: 2,
        opacity: .9,
        position: 'absolute',
        bottom: '1.5rem',
        right: '1.5rem',
      }}
    >
      <Typography
        variant="body1"
        component={'span'}
        color="back"
      >
        <b>
          <FormattedMessage id="car.price.buyFor" />{` `}
        </b>
      </Typography>
      <Typography
        variant="h5"

        component={'span'}
        color="primary"
      >
        <Price price={price} />
      </Typography>
    </Box>
  );
}


CarNameLabel.propTypes = {
  description: PropTypes.string.isRequired,
};
function CarNameLabel({ intl, description, big }) {
  if (!description) {
    return null;
  }
  return (
    <Typography
      color="text.primary"
      variant={big ? "h5" : "caption"}
      sx={{
        backgroundColor: 'background.neutral',
        borderRadius: 0.3,
        py: 0.3,
        px: 2,
        opacity: .8,
        position: 'absolute',
        // top: '1.5rem',
        bottom: '1.5rem',
        left: '1.5rem',
      }}
      component={'span'}
    >
      <b>{description}</b>
    </Typography>
  );
}

const HorizontalAdsCard = ({ car, doNavigate }) => {
  const { images, vin, shortDescription, firstRegistrationAsYear, mileage, fuel, price } = car
  return <Card key={vin}
    elevation={3}
    variant="outlined"
    color="background.neutral"
    sx={{ m: 2, p: 1, backgroundColor: 'background.neutral' }}
  >
    <CardContent
      onClick={(e) => doNavigate(vin)}
      sx={{
        height: {
          xs: '10rem',
          sm: '10rem',
          md: '10rem',
        },
        backgroundImage: `url(${images[0]})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      <AdsLabel />
      <CarNameLabel description={shortDescription} />
      <KeySpecsStack car={car} sx={{
        position: 'absolute',
        // opacity: .7,
        top: '2rem',
        // top: big ? '3.75rem' : '3.0rem',
        // top: big ? '4.25rem' : '3.4rem',
        right: '1.5rem',
      }} />
      <PriceLabel price={price} />
    </CardContent>
  </Card>
}

const VerticalAdsCard = ({ car, doNavigate }) => {
  const { images, vin, shortDescription, firstRegistrationAsYear, mileage, fuel, price } = car
  return <Card key={vin}
    elevation={3}
    variant="outlined"
    sx={{ m: 2, p: 1, backgroundColor: 'background.neutral' }}>
    <CardContent
      onClick={(e) => doNavigate(vin)}
      sx={{
        height: {
          md: '16rem',
          xl: '22rem'
        },
        backgroundImage: `url(${images[0]})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      <AdsLabel />
      <CarNameLabel description={shortDescription} big />
      <KeySpecsStack car={car} big sx={{
        position: 'absolute',
        // opacity: .7,
        top: '2rem',
        // top: big ? '3.75rem' : '3.0rem',
        // top: big ? '4.25rem' : '3.4rem',
        right: '1.5rem',
      }} />
      <PriceLabel price={price} />
    </CardContent>
  </Card>
}

// ADS CAROUSEL
AdsCarousel.propTypes = {
  isTv: PropTypes.bool,
  small: PropTypes.bool,
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1400 },
    items: 5,
  },
  desktop2: {
    breakpoint: { max: 1400, min: 10240 },
    items: 4.5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 700 },
    items: 2.8,
  },
  tablet2: {
    breakpoint: { max: 700, min: 580 },
    items: 2.3,
  },
  tablet3: {
    breakpoint: { max: 580, min: 464 },
    items: 1.9,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.3,
  },
};

const responsiveVertical = {
  mobile: {
    breakpoint: { max: 40000, min: 0 },
    items: 1,
  },
};

function AdsCarousel({ cars, verticalOrientation }) {
  const navigate = useNavigate();

  const doNavigate = (vin) => {
    console.log('vin', vin);
    navigate(`/car/${vin}`);
  };

  return cars && cars.length ? <Carousel 
    responsive={verticalOrientation ? responsiveVertical : responsive}
    autoPlay={verticalOrientation}
    autoPlaySpeed={3000}
    infinite
    indicators={false}
    arrows={false}
  >
    {cars && cars.length > 0 ? (
      cars.map((car) => verticalOrientation
        ? (car ? <VerticalAdsCard key={car.vin} car={car} doNavigate={doNavigate} /> : <Skeleton sx={{
          height: {
            xs: '12rem',
            xl: '18rem'
          },
          m: 3,
          width: '10rem',
        }}
        />)
        : <HorizontalAdsCard  key={car.vin} car={car} doNavigate={doNavigate} />)
    ) : (
      <Skeleton
        sx={{
          height: verticalOrientation ? {
            md: '12rem',
            xl: '18rem'
          } : {
            xs: '10rem',
            sm: '12rem',
            md: '14rem',
            lg: '16rem',
            xl: '18rem',
          },
          m: 3,
          width: '60vw',
        }}
      />
    )}
  </Carousel> : null

}


CarsFeaturedSection.propTypes = {
  isTv: PropTypes.bool,
  small: PropTypes.bool,
};

export default function CarsFeaturedSection({ isTv, small, verticalOrientation, ...other }) {
  const [featuredCars, setFeaturedCars] = useState([]);

  useEffect(() => {
    let cancelled = false;
    const loadCars = async () => {
      const { results } = await carsService.getFeaturedCars();
      if (!cancelled) {
        setFeaturedCars(results);
      }
    };
    loadCars();
    return () => {
      cancelled = true;
    };
  }, []);

  return (featuredCars || featuredCars.length > 0) ? (<>
    {verticalOrientation ? (
      <>
        {false && <Typography
          variant="h6"
          component={'div'}
          color="text.disabled"
          sx={{ textAlign: 'center' }}
        >
          <i><FormattedMessage id="car.featured.title" /></i>
        </Typography>}
        <AdsCarousel cars={featuredCars} verticalOrientation />
      </>
    ) : <>
      <AdsCarousel cars={featuredCars} />
    </>}
  </>) : null
}
