import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams, Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import authService from '../services/auth.service';
import PageContext from '../contexts/PageContext';
import { CompanyProfileForm } from '../sections/auth';
import { resellersService } from '../services/index';
import Label from '../components/label/Label';


const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  // padding: theme.spacing(2, 0, 3, 0),
}));

const resellerColorByStatus = (status) => {
  switch (status) {
    case 'APPROVED': return 'primary'
    case 'REJECTED': return 'warning'
    default: return 'default'
  }
}

export default function CompanyProfilePage() {
  const navigate = useNavigate();
  const intl = useIntl();
  const pageContext = useContext(PageContext)
  const [reseller, setReseller] = useState({ contactInfo: {} })

  useEffect(() => {
    const loadResellerByUser = async () => {
      // console.log('loadResellerByUser')
      // console.log('loadResellerByUser.user', pageContext.user)

      const { user } = pageContext
      if (user) {
        try {
          const { id } = user
          console.log('user.id', id)
          const reseller = await resellersService.getResellerByLoggedUser()
          console.log('reseller', reseller)
          if (reseller) {
            setReseller(reseller)
          } else {
            // preload from user data
            setReseller({
              contactInfo: {
                companyName: user.companyName,
                vat: user.vat,
              }
            })
          }
        } catch (err) {
          console.log('err', err)
        }
        // } else {
        //   setTimeout(() => {
        //     navigate('/')
        //   }, 500)
      }
    }

    loadResellerByUser()
  }, [pageContext.user])

  const [formProcessed, setFormProcessed] = useState(false)

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'application.name' })}
          {' | '}
          {intl.formatMessage({ id: 'page.title.company-profile' })}
        </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              <FormattedMessage id='page.company-profile.title' />
            </Typography>

            {formProcessed
              ? (<Stack>
                <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>
                  <FormattedMessage id="page.company-profile.form-processed.message1" />
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  <FormattedMessage id="page.company-profile.form-processed.message2" />
                </Typography>
                <Button to="/" size="large" variant="contained" component={RouterLink}>
                  Go to Home
                </Button>
              </Stack>)
              : (
                <>
                  {reseller.status !== undefined && (
                    <Stack direction={'row'} sx={{ mb: 2 }}>
                      <Typography variant="body2" color="text.primary" sx={{ pr: 1 }}>
                        <FormattedMessage id="page.company-profile.registration-status" />
                      </Typography>
                      <Label color={resellerColorByStatus(reseller.status)}>{reseller.status}</Label>
                    </Stack>)
                  }
                  <CompanyProfileForm reseller={reseller} setFormProcessed={setFormProcessed} />
                </>
              )
            }
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
