const config = require('../config/config');
const { get, getBlob } = require('../utils/fetch');

const { BE_URL: beURL } = config;

/** @Deprecated */
const getCars = async () => get(`${beURL}/cars`);

const getFeaturedCars = async () => get(`${beURL}/cars/featured`);

const countCars = async (params) => {
  const searchParams = new URLSearchParams(params).toString();
  return get(`${beURL}/cars/count?${searchParams}`);
};

const getPaginatedCars = async (page, size, params, sortOptions) => {
  const searchParams = new URLSearchParams(params).toString();
  const sortOptionsUrlParam = sortOptions ? `&sortBy=${sortOptions.join(',')}` : ``;
  return get(`${beURL}/cars?page=${page}&limit=${size}&${searchParams}${sortOptionsUrlParam}`);
};

const getCarByVIN = async (vin) => get(`${beURL}/cars/vin/${vin}`);

const getCarDocument = async (id, documentId) =>
  getBlob(`${beURL}/cars/${id}/documents/${documentId}`, null, { useRefreshToken: false });

module.exports = {
  getCars,
  getFeaturedCars,
  countCars,
  getPaginatedCars,
  getCarByVIN,
  getCarDocument,
};
