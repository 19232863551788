import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, ListItemText, Stack, useMediaQuery } from '@mui/material';
//
import { StyledHeaderItem } from './styles';

// ----------------------------------------------------------------------

HeaderSection.propTypes = {
  data: PropTypes.array,
  userRoles: PropTypes.array,
};

export default function HeaderSection({ data = [], userRoles = [], ...other }) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box {...other}>
      <Stack direction="row" sx={{ p: 1 }}>
        {/* {isSmDown ? null : <Logo />} */}
        {data
          .filter(
            ({ roles }) =>
              (userRoles.length === 0 && roles.includes('guest')) ||
              roles.find((navRole) => userRoles.includes(navRole))
          )
          .map((item) => (
            <NavItem key={item.title} item={item} isSmDown={isSmDown} />
          ))}
      </Stack>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  isSmDown: PropTypes.bool,
};

function NavItem({ item, isSmDown }) {
  const { title, path, icon, info } = item;

  return (
    <StyledHeaderItem
      component={RouterLink}
      to={path}
      sx={{
        p: 1,
        px: isSmDown ? undefined : 3,
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      {/* <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon> */}

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledHeaderItem>
  );
}
