import { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

// @mui
import { Typography, Slider } from '@mui/material';
// components
import { filterUnique } from '../../utils/filterUnique';
import { numFormatter } from '../../utils/numFormatterForFilter';
import { scaleByMarks } from '../../utils/scale';
import SearchContext from '../../contexts/SearchContext';
import { DEFAULT_MILEAGE_RANGE } from '../../config/config';

MileageFilter.propTypes = {};

export default function MileageFilter() {
  const intl = useIntl();
  const searchContext = useContext(SearchContext);
  const { mileageRange, setMileageRange, setMileageFilters } = searchContext;
  const [mileageMin, mileageMax] = DEFAULT_MILEAGE_RANGE;

  const mileageMarks = [
    {
      value: mileageMin,
      scaledValue: 0,
      label: '0',
    },
    {
      value: mileageMin + Math.round((mileageMax - mileageMin) * 0.25),
      scaledValue: 50000,
      label: '50k',
    },
    {
      value: mileageMin + (mileageMax - mileageMin) * 0.5,
      scaledValue: 100000,
      label: '100k',
    },
    {
      value: mileageMin + (mileageMax - mileageMin) * 0.75,
      scaledValue: 200000,
      label: '200k',
    },
    {
      value: mileageMax,
      scaledValue: 500000,
      label: '500k+',
    },
  ];
  const scaleMileage = scaleByMarks(mileageMarks);

  const mileageValuetext = (value) => `${value}`;

  const mileageLabel = (range) => {
    const mileages = range
      .filter(filterUnique)
      .map((x) => scaleMileage(x))
      .map((x) => numFormatter(x));
    return mileages.length > 1 ? `Mileage range: ${mileages.join('->')}` : `Mileage: ${mileages.join('->')}`;
  };

  const handleMileageRangeChange = (event, newRange) => {
    // RX.js
    setMileageRange(newRange);
    searchContext.updateRangeAndFilter(newRange, DEFAULT_MILEAGE_RANGE, 'mileage', scaleMileage);
  };

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage id="filter.mileage.title" />
      </Typography>
      {/* <Box>&nbsp;</Box> */}
      <Slider
        valueLabelDisplay="auto"
        min={mileageMin}
        max={mileageMax}
        step={1}
        valueLabelFormat={numFormatter}
        getAriaLabel={() => 'Mileage range'}
        value={mileageRange}
        onChange={handleMileageRangeChange}
        marks={mileageMarks}
        scale={scaleMileage}
        getAriaValueText={mileageValuetext}
      />
      {/* <Typography variant="subtitle2" gutterBottom>
        {mileageLabel(mileageRange)}
      </Typography> */}
    </div>
  );
}
