import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// @mui
import { Box, Paper, Stack } from '@mui/material';

import KeySpecComponentTv from './KeySpecComponentTv';

// ----------------------------------------------------------------------

CarCardTopTv.propTypes = {
  step: PropTypes.array.isRequired,
};

export default function CarCardTopTv({ step, carLink }) {
  const intl = useIntl();

  // const [make, description, auctionType, countryOfOrigin, vin] = step
  // const [make, description, year, mileage, vin] = step;
  const [make, description, year, mileage, countryOrigin] = step;

  return step && step.length > 0 ? (
    <>
      <Paper
        elevation={4}
        sx={{
          bgcolor: 'primary.light',
          color: '#fff',
        }}
      >
        <Stack direction={'row'} spacing={1}>
          <Box
            sx={{
              px: 3,
              py: 0,
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'flex-start',
              alignItems: 'end',
            }}
          >
            <Box width={'100%'}>
              <KeySpecComponentTv big>{description.value || make.value}</KeySpecComponentTv>
            </Box>

            {/* {false && <Box width={'33%'}>
                            <KeySpecComponentTv
                                label={`car.${countryOfOrigin.label}.label`}
                            >
                                {countryOfOrigin.value}
                            </KeySpecComponentTv >
                        </Box>} */}

            {/* {auctionType.value ? <Box width={'33%'}>
                            <KeySpecComponentTv label={`car.${auctionType.label}.label`}>
                                {auctionType.value}
                                {intl.formatMessage({ id: `car.auctionType.${auctionType.value}.label` })}
                            </KeySpecComponentTv >
                        </Box> : null} */}

            {year.value ? (
              <Box width={'33%'}>
                <KeySpecComponentTv label={`car.${year.label}.label`}>{year.value}</KeySpecComponentTv>
              </Box>
            ) : null}
            {mileage.value ? (
              <Box width={'33%'}>
                <KeySpecComponentTv label={`car.${mileage.label}.label`}>{mileage.value}</KeySpecComponentTv>
              </Box>
            ) : null}
            {/* {vin.value ? (
              <Box width={'33%'}>
                <KeySpecComponentTv small label={`car.${vin.label}.label`}>
                  {vin.value}
                </KeySpecComponentTv>
              </Box>
            ) : null} */}
            {countryOrigin.value ? (
              <Box width={'33%'}>
                <KeySpecComponentTv label={`car.location.label`}>
                  {countryOrigin.value}
                </KeySpecComponentTv>
              </Box>
            ) : null}
          </Box>
        </Stack>
      </Paper>
    </>
  ) : null;
}
