import { Box } from '@mui/material';
import PropTypes from 'prop-types';
// @mui

// ----------------------------------------------------------------------

BodyType.propTypes = {
  bodyType: PropTypes.string.isRequired,
};

export default function BodyType({ intl, bodyType }) {
  return <>{bodyType}</>;
}
