import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { Navigate, useRoutes, useRouteError, Link } from 'react-router-dom';

// layouts
// import DashboardLayout from './layouts/dashboard';
import { SimpleLayoutTv, SimpleLayoutWeb, SimpleLayoutMobile } from './layouts/simple';

import PageContext from './contexts/PageContext';

import CarPage from './pages/CarPage';
import CarsPageTv from './pages/CarsPageTv';
import CarsPageWeb from './pages/CarsPageWeb';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import UnverifiedUserPage from './pages/UnverifiedUserPage';
import AuthCallbackPage from './pages/AuthCallbackPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import CompanyProfilePage from './pages/CompanyProfilePage';
import Page404 from './pages/Page404';
import TvFrame from './pages/TvFrame';

// ----------------------------------------------------------------------
function Error() {
  const error = useRouteError();
  return (
    <div className="container">
      <center>
        <h1>Oops!!!!!</h1>
      </center>
      <h1>Error: {error.message}</h1>
      <pre>
        {error.status} - {error.statusText}
      </pre>

      <Link to="/">Go home!</Link>
    </div>
  );
  // return <Redirect to="/home" />;
}

const ProtectedRoute = ({ children, role }) => {
  const pageContext = useContext(PageContext);

  if (pageContext && !pageContext.isLoggedIn) {
    // console.log('pageContext isLoggedIn', pageContext.isLoggedIn)
    return <Navigate to="/login" />;
  }
  // if (!is2FAVerified) {
  //   return <Navigate to="/verify-2fa" />;
  // }

  return children;
};

const SimpleLayout = ({ isMobile, onChangeLanguage, currentLanguage }) =>
  isMobile ? (
    <SimpleLayoutMobile onChangeLanguage={onChangeLanguage} currentLanguage={currentLanguage} />
  ) : (
    <SimpleLayoutWeb onChangeLanguage={onChangeLanguage} currentLanguage={currentLanguage} />
  );
const CarsPage = ({ isMobile }) => (isMobile ? <CarsPageWeb isMobile={{ isMobile }} /> : <CarsPageWeb />);

export function Router({ onChangeLanguage, currentLanguage, isMobile }) {
  const theme = useTheme();

  // const pageContext = useContext(PageContext)
  // const { isLoggedIn, isMobile, isWeb, isTv } = pageContext

  const routesWeb = useRoutes([
    {
      element: (
        <SimpleLayout isMobile={isMobile} onChangeLanguage={onChangeLanguage} currentLanguage={currentLanguage} />
      ),
      errorElement: <Error />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <CarsPage isMobile={isMobile} /> },
        { path: 'car/:vin', element: <CarPage isMobile={isMobile} /> }, // TODO
        { path: 'login', element: <LoginPage /> },
        { path: 'signup', element: <SignUpPage /> },
        { path: 'forgot-password', element: <ForgotPasswordPage /> },
        { path: 'reset-password', element: <ResetPasswordPage /> },
        { path: 'verify-email', element: <VerifyEmailPage /> },
        { path: 'auth-callback/state/:state', element: <AuthCallbackPage /> },
        // { path: 'user-settings', element: <UserSettingPage /> },
        { path: 'company-profile', element: <CompanyProfilePage /> },
        // {
        //   path: 'company-profile', element: <ProtectedRoute>
        //     <CompanyProfilePage />
        //   </ProtectedRoute>
        // },
        { path: 'unverified', element: <UnverifiedUserPage /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // {
    //   // path: 'auth-callback',
    //   element: <DashboardLayout onChangeLanguage={props.onChangeLanguage} currentLanguage={props.currentLanguage} />,
    //   children: [
    //     { element: <Navigate to={isLoggedIn ? "/cars" : "/home"} />, index: true },
    //     // { path: 'auth-callback/state/:state', element: <AuthCallbackPage /> },
    //     // { path: 'unverified', element: <UnverifiedUserPage /> },
    //   ],
    // },
    // {
    //   path: 'login',
    //   element: <LoginPage />,
    // },
    // {
    //   path: '*',
    //   element: <Navigate to="/404" replace />,
    // },
  ]);

  return routesWeb;
}

export function RouterTv({ onChangeLanguage, currentLanguage }) {
  const routesTv = useRoutes([
    {
      element: <SimpleLayoutTv onChangeLanguage={onChangeLanguage} currentLanguage={currentLanguage} />,
      // element: <TvFrame>
      //   <SimpleLayoutTv onChangeLanguage={onChangeLanguage} currentLanguage={currentLanguage} />
      // </TvFrame>,
      errorElement: <Error />,
      children: [
        // { element: <Navigate to="/home" />, index: true },
        { path: '*', element: <CarsPageTv /> },
        // { path: '/demo', element: <DemoTv /> },
      ],
    },
    { path: 'tv-frame', element: <TvFrame /> },
  ]);

  return routesTv;
}
