import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// @mui
import { Typography, Stack, Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// ----------------------------------------------------------------------

CarLocationInfo.propTypes = {
  car: PropTypes.object.isRequired,
};

export default function CarLocationInfo({ car, iconPrimary }) {
  const displayLocation = car && car.countryOrigin
  return (displayLocation ? (
    <>
      <Box sx={{ display: 'flex', alignContent: 'flex-end', alignItems: 'end' }}>
        <LocationOnIcon color={iconPrimary ? "primary" : "black"} />
        <Typography variant="section2" color="text.secondary" sx={{ textAlign: 'left' }}>
          <FormattedMessage id={`car.countryOrigin.${displayLocation}.label`} />
        </Typography>
      </Box>
    </>
  ) : null
  );
}
