import Joi from 'joi';
// console.log(process.env)

const envVarsSchema = Joi.object()
  .keys({
    REACT_APP_NODE_ENV: Joi.string().valid('production', 'development', 'test'),
    REACT_APP_PORT: Joi.number().default(3000),
    REACT_APP_TARGET_PLATFORM: Joi.string().default('tv'), // mobile, web, tv, (maybe:ßtablet)
    REACT_APP_BE_URL: Joi.string().required().description('BE app url'),
    REACT_APP_REFRESH_TOKEN_INTERVAL: Joi.number().required().description('Refresh token interval in millisec'),
  })
  .unknown();

const { value: envVars, error } = envVarsSchema.prefs({ errors: { label: 'key' } }).validate(process.env);

if (error) {
  throw new Error(`Config validation error: ${error.message}`);
}

// env
export const NODE_ENV = envVars.REACT_APP_NODE_ENV;
export const PORT = envVars.REACT_APP_PORT;
export const BE_URL = envVars.REACT_APP_BE_URL;
export const { REACT_APP_TARGET_PLATFORM } = envVars;
export const REFRESH_TOKEN_INTERVAL = +envVars.REACT_APP_REFRESH_TOKEN_INTERVAL || 61 * 1000;

// ui
export const DEFAULT_SORT_ORDER = REACT_APP_TARGET_PLATFORM === 'tv' ? 'price.selling:desc' : 'price.selling:asc';
// export const INITIAL_DISPLAY_PREFERENCE = 'grid';
export const INITIAL_DISPLAY_PREFERENCE = 'list';
export const APP_BAR_MOBILE = 64;
export const APP_BAR_DESKTOP = 96;
export const NAV_WIDTH = 280;
export const DEFAULT_THEME = 'light';
export const DEFAULT_UI_TARGET = 'tv';

// filters
export const DEFAULT_PRICE_RANGE = [1, 150];
export const DEFAULT_POWER_RANGE = [1, 100];
export const DEFAULT_MILEAGE_RANGE = [0, 100];

const ccMax = 6000;
const ccMin = 0;
export const DEFAULT_CC_RANGE = [ccMin, ccMax];

const co2Max = 6000;
const co2Min = 0;
export const DEFAULT_CO2_RANGE = [co2Min, co2Max];

const firstRegistrationDateMax = new Date().getFullYear();
const firstRegistrationDateMin = firstRegistrationDateMax - 25;
export const DEFAULT_FIRST_REGISTRATION_RANGE = [firstRegistrationDateMin, firstRegistrationDateMax];

export const passwordRegexp = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\\d!@#$%^&*(),.?":{}|<>]{8,}$'

export const email = {
  registration: 'admin@carbid.tv'
}