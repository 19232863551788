import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
// @mui
import { Box, CardHeader, Typography } from '@mui/material';
import { Problem } from '../../components/car-labels/index';
// ----------------------------------------------------------------------

ProblemSection.propTypes = {
  problems: PropTypes.string,
  showHeader: PropTypes.bool,
  useCardHeader: PropTypes.bool,
};

export default function ProblemSection({ problems, useCardHeader = false, showHeader = false }) {
  const intl = useIntl();

  return (
    <>
      {' '}
      {problems ? (
        <>
          {showHeader ? (
            <>
              {useCardHeader ? (
                <CardHeader title={intl.formatMessage({ id: 'car.section.title.problems' })} />
              ) : (
                <Typography variant="h4">
                  <FormattedMessage id="car.section.title.problems" />
                </Typography>
              )}
            </>
          ) : null}
          <Box>
            <Problem problem={problems} />
          </Box>
        </>
      ) : null}
    </>
  );
}
