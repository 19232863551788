import { Helmet } from 'react-helmet-async';
import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
// @mui
import { Box, CircularProgress, Container, LinearProgress, Link, Skeleton, Stack, Typography } from '@mui/material';
// components
import { CarsHeroSection, CarsCardMobile, CarsCardWeb, CarsFeaturedSection } from '../sections/cars';
import {
  CarsSort,
  SearchbarMobile,
} from '../sections/searchbar';
import SearchContext from '../contexts/SearchContext';
import { INITIAL_DISPLAY_PREFERENCE } from '../config/config';
import useResponsive from '../hooks/useResponsive';
import CarsNewsBox from '../sections/info/CarsNewsBox';

// mock
// import CARS from '../_mock/cars';

const News = () => {
  return false && <CarsNewsBox
    title="News Update"
    list={[{
      title: 'Title',
      postedAt: new Date(),
      description: 'a desription',
      image: `/assets/images/covers/cover_${1}.jpg`,
    }, {
      title: 'Title',
      postedAt: new Date(),
      description: 'a desription',
      image: `/assets/images/covers/cover_${2}.jpg`,
    }, {
      title: 'Title',
      postedAt: new Date(),
      description: 'a desription',
      image: `/assets/images/covers/cover_${3}.jpg`,
    }
    ]} />
}

const Empty = () => {
  return <Box
    sx={{
      // height: '10vh'
    }} />
}
// ----------------------------------------------------------------------
// 'grid' 'list' 'gallery'
export default function CarsPageWeb({ isWeb, isMobile }) {
  const theme = useTheme();
  const intl = useIntl();

  const searchContext = useContext(SearchContext);
  const navigate = useNavigate();

  const navigateToPage = (car, searchIndex) => {
    searchContext.setCurrentIndex(searchIndex);
    navigate(`/car/${car.vin}`);
  };

  return (
    <>
      <Helmet>
        {intl.formatMessage({ id: 'application.name' })}
        {' | '}
        {intl.formatMessage({ id: 'page.title.cars' })}
      </Helmet>

      {/* // Featured car: only on mobile */}
      {false && searchContext.isSearchExecuted ? (
        <Box
          sx={{
            display: {
              xs: 'block',
              md: 'none',
              // lg: 'block'
            },
          }}
        >
          <CarsFeaturedSection />
        </Box>
      ) : null}

      <Container maxWidth="xl" sx={{ bgcolor: theme.palette.background.paper }}>
        {/* <Box sx={{
          minHeight: '19vh', display: {
            xs: 'block',
            md: 'none'
          }
        }}>
        </Box> */}

        {/* {isMobile
          ? (<SearchbarMobile
            openFilter={openFilter}
            handleOpenFilter={handleOpenFilter}
            handleCloseFilter={handleCloseFilter} />
          ) : (isSmDown ? (
            <SearchbarMobile
              openFilter={openFilter}
              handleOpenFilter={handleOpenFilter}
              handleCloseFilter={handleCloseFilter} />
          ) : (
            <SearchbarWeb
              openFilter={openFilter}
              handleOpenFilter={handleOpenFilter}
              handleCloseFilter={handleCloseFilter} />
          ))
        } */}

        {/* <Paper elevation={3}  
        bgcolor: theme.palette.background.default
        */}

        <Box
          sx={{
            my: 0,
            p: 0.5,
            width: '100%',
          }}
        >
          {
            searchContext.isSearchExecuted ? (null) : (
              <Stack height={150} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Typography variant='h3' >
                  <FormattedMessage id="page.cars.hero.title1" />
                </Typography>
                <Typography variant='h4' >
                  <FormattedMessage id="page.cars.hero.title2" />
                </Typography>
              </Stack>
            )
          }

          <SearchbarMobile />

          {searchContext.isSearchExecuted ? (null) : (
            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 2,
              }}>
              <Stack sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Typography variant='body1' >
                  <>
                    <FormattedMessage id="page.cars.hero2.description1" />
                    <br />
                    <Link href="/signup">
                      <FormattedMessage id="page.cars.hero2.register-now" />
                    </Link>
                    {' '}
                    <FormattedMessage id="page.cars.hero2.description2" />
                  </>
                </Typography>
              </Stack>

              <Stack width={'100%'} spacing={2} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 2,
                pb: 4,
              }}>
                <Typography variant='h5' >
                  <FormattedMessage id="page.cars.hero3.description1" />
                </Typography>
                <Marquee direction="left" sx={{ width: '100%' }}>
                  <Stack direction={'row'} spacing={4}>
                    {[
                      // 'ATU.jpg',
                      // 'TUV_THURINGEN.jpg',
                      // 'FSP.png',
                      // 'GKK.png',
                      // 'DSO.jpg',
                      // 'TUVNORD.jpg',
                      // 'TUV_HESSEN.jpg',
                      // 'HUSGES.jpg',
                      'MACADAM.png',
                      // 'GTU.jpg',
                      // 'WARRANTY_DIRECT.png',
                      // 'INTERTEK.png',
                      // 'GARVAGH.jpg',
                      'BELCAR.png',
                      'FDM.png',
                      'SGS.jpg',
                      // 'TUV_POL.jpg',
                      // 'TUV_SUD.png',
                      // 'TUV_RHEINLAND.jpg',
                      // 'MOBILITYEXPERT.jpg',
                      'DEKRA.jpg'].map(src => (

                        <img src={`/assets/images/experts/${src}`}
                          alt={src}
                          width={120}
                          height={60}
                          key={src}
                        />
                      ))
                    }
                  </Stack>
                </Marquee>
              </Stack>
            </Stack>
          )
          }

          {searchContext.isSearchExecuted ? (
            <Box sx={{
              // width: '100%', 
              width: {
                xs: '100%',
                lg: '75%',
                xl: '66%',
              },
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  color: 'text.secondary',
                  p: 1,
                }}
              >
                {searchContext.isLoading
                  ? '..'
                  : <FormattedMessage
                    id="cars.searchbar.numberOfResults"
                    values={{
                      numberOfResults: searchContext.displayTotalResults,
                    }}
                  />
                }
              </Typography>
              <CarsSort />
            </Box>
          ) : (<Box
            sx={{
              display: {
                xs: 'block',
                // md: 'none',
                // lg: 'block'
              },
            }}
          >
            <News />
            <CarsFeaturedSection />
          </Box>
          )
          }
        </Box>
        {/* </Paper> */}

        <Box sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          gap: 1,
          opacity: searchContext.isLoading ? .4 : 1,
          pb: 3
        }}>
          {/* // main container */}
          <Box
            sx={{
              width: {
                xs: '100%',
                lg: '75%',
                xl: '66%',
              },
            }}
          >
            {searchContext.isFirstSearchExecuted ? (
              searchContext.results.length > 0 ? (
                <>
                  {/* // Box Mobile */}
                  <Box
                    sx={{
                      display: {
                        xs: 'flex',
                        md: 'none',
                      },
                      flexWrap: 'wrap',
                      gap: 1,
                      // justifyContent: 'space-evenly'
                      // backgroundColor: 'yellow',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    {searchContext.results.map((car, searchIndex) => (
                      <Box
                        onClick={(e) => navigateToPage(car, searchIndex)}
                        sx={{ maxWidth: { xs: '100%', sm: '48%' } }}
                      >
                        <CarsCardMobile car={car} searchIndex={searchIndex} />
                      </Box>
                    ))}
                    {searchContext.results.length % 2 === 1 ? <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }} flexGrow={1} /> : null}
                  </Box>

                  {/* // Box WEB -  */}
                  <Box
                    sx={{
                      display: {
                        xs: 'none',
                        md: 'flex',
                      },
                      flexWrap: 'wrap',
                      flexDirection: 'column',
                      gap: 1,
                      // justifyContent: 'space-evenly'
                      // backgroundColor: 'yellow',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    {searchContext.results.map((car, searchIndex) => (
                      <Box onClick={(e) => navigateToPage(car, searchIndex)}>
                        <CarsCardWeb car={car} searchIndex={searchIndex} />
                      </Box>
                    ))}
                  </Box>
                </>
              ) : (
                <Empty />
              )
            ) : <Empty />
            }
          </Box>
          {/* // Large area on the right */}
          <Box
            sx={{
              width: {
                lg: '25%',
                xl: '33%',
              },
              // backgroundColor: 'pink',
              display: {
                xs: 'none',
                lg: 'block',
              },
              // position: '-webkit-sticky',
              position: 'sticky',
              top: 0,
            }}
          >
            {/* <Marquee direction="left" sx={{ height: '400px', bgcolor: 'pink' }}>
              ADS Section
            </Marquee> */}
            {searchContext.isSearchExecuted ? (<>
              <CarsFeaturedSection verticalOrientation />
              <News />
            </>
            ) : null}
          </Box>
        </Box>
      </Container>
    </>
  );
}
