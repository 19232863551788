import { useContext, useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
// @mui
import { styled } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import { Stack, Typography, Link, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
// components
import SearchContext from '../../../contexts/SearchContext';
import { carsService } from '../../../services/index';

// ----------------------------------------------------------------------

const StyledFooter = styled('footer')(({ theme }) => ({
  height: '100%',
  minHeight: '100%',
  // backgroundColor: 'teal',
  // padding: theme.spacing(3, 3, 0),
  display: 'flex',
  alignContent: 'space-around',
  justifyContent: 'space-between',
}));

// ----------------------------------------------------------------------

export default function Footer() {
  const searchContext = useContext(SearchContext);
  const [optionals, setOptionals] = useState();
  const [car, setCar] = useState();

  useEffect(() => {
    const loadOptionals = async (vin) => {
      const { optionals } = await carsService.getCarByVIN(vin);
      setOptionals(
        optionals
        // .filter(({ starred }) => starred)
        // .sort((a, b) => b.starred ? b : a)
      );
    };
    try {
      if (car) {
        loadOptionals(car.vin);
      }
    } catch (e) {
      // console.log('FooterTv', e)
      // ignored
    }
  }, [car])

  useEffect(() => {
    const car = searchContext.results[searchContext.currentIndex || 0];
    setCar(car)
  }, [searchContext.currentIndex])

  return (
    <>
      <StyledFooter>
        <Marquee>
          <Stack
            direction={'row'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'flex-start',
              flexGrow: 1,
              flexWrap: 'wrap',
              pr: 100,
            }}
            spacing={4}
          >
            {car ? (
              <>
                {optionals ? (
                  <Typography component={'div'} variant="h4" sx={{ pr: 2 }}>
                    Optionals:
                  </Typography>
                ) : null}
                {optionals
                  ? optionals
                    .map((option) => (
                      <Box component="span" key={option.name} sx={{
                        display: 'flex', alignContent: 'end',
                        // px: 1.75,
                        py: .5
                      }}>
                        <Typography variant="h4"
                          color={option.starred ? 'primary' : undefined}>
                          {option.name}
                        </Typography>
                        {option.starred ? (
                          <StarIcon color="primary"
                            fontSize='small'
                            sx={{ pl: .5, pb: .5 }} />
                        ) : null}
                      </Box>
                    ))
                  : null}
              </>) : 
            //   null}
            // {!car &&
              (
                <>
                  <CircleIcon sx={{ color: 'grey.400' }} />

                  <Typography variant="h3">
                    For advertising purposes, please call{' '}
                    <Box color="primary.main" display="inline">
                      +32 (0) 456 682 609
                    </Box>
                    . Our team will assist you with all your advertising needs.
                  </Typography>

                  {/* <CircleIcon sx={{color:'primary.main'}}/> */}
                  <CircleIcon sx={{ color: 'grey.400' }} />

                  <Typography variant="h3">
                    &copy; 2024 CarBid.tv | Powered by{' '}
                    <Link href="https://www.sabiasrl.com" target="_blank">
                      Sabia Srl
                    </Link>
                  </Typography>
                  <CircleIcon sx={{ color: 'grey.400' }} />

                  <Typography variant="h3">
                    For advertising purposes, please call{' '}
                    <Box color="primary.main" display="inline">
                      +32 (0) 456 682 609
                    </Box>
                    . Our team will assist you with all your advertising needs.
                  </Typography>

                  {/* <CircleIcon sx={{color:'primary.main'}}/> */}
                  <CircleIcon sx={{ color: 'grey.400' }} />

                  <Typography variant="h3">
                    &copy; 2024 CarBid.tv | Powered by{' '}
                    <Link href="https://www.sabiasrl.com" target="_blank">
                      Sabia Srl
                    </Link>
                  </Typography>
                </>
              )}
          </Stack>
        </Marquee>
      </StyledFooter>
    </>
  );
}
