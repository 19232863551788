import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
// import { withFocusable, useFocusable } from 'react-spatial-navigation';

// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  useMediaQuery,
  Typography,
  Link,
} from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
//
import LanguagePopover from './LanguagePopover';
import PageContext from '../../../contexts/PageContext';
import SearchContext from '../../../contexts/SearchContext';

import { LogoTv } from '../../../components/logo';
import { SearchbarTvSimplified } from '../../../sections/searchbar';

// ----------------------------------------------------------------------

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  minHeight: '100%',
  height: '100%',
  // backgroundColor: 'orangered',
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  // padding: theme.spacing(3),
  minHeight: '100%',
  height: '100%',
  display: 'flex',
  alignContent: 'space-around',
  justifyContent: 'space-between',
  alignItems: 'center',
  // mt: 55,
  // backgroundColor: 'teal',
  [theme.breakpoints.up('lg')]: {
    // minHeight: 0,
    // padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

HeaderTv.propTypes = {};

export default function HeaderTv({ onChangeLanguage, currentLanguage }) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const pageContext = useContext(PageContext);
  const searchContext = useContext(SearchContext);
  const [user, setUser] = useState();

  useEffect(() => {
    const { user } = pageContext;
    setUser(user);
  }, [pageContext.user]);

  /** @deprecated */
  const HeaderItem = ({ label }) => (
    <Typography variant="h5" sx={{ color: 'text.primary', px: 1 }} key={label}>
      <Link variant='h5' underline='none'>{label}</Link>
    </Typography>
  );

  // const FocusableHeaderItem = useFocusable(HeaderItem)

  return (
    <StyledAppBar position={'static'}>
      <StyledToolbar
        // sx={{
        //   height: '100%',
        // }}
      >
        <Box mt={1}>
          <LogoTv />
        </Box>

        <Box
          sx={{
            flexGrow: 10,
            flexShrink: 0,
            // bgcolor: 'yellow'
          }}
        />

        <Stack sx={{ flexShrink: 0, px: 2 }} direction={'row'} spacing={2}>
          {/* {['Fixed price',
              'Auction',
              'Ex-leasing',
              'Used certified',
              // 'Info',
            ].map(label => (
              // <FocusableHeaderItem label={label} />
              <HeaderItem label={label} />
            )
            )} */}
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <SearchbarTvSimplified />

        <Box sx={{ flexGrow: 1 }} />

        <LanguagePopover onChangeLanguage={onChangeLanguage} currentLanguage={currentLanguage} isTv />

      </StyledToolbar>
    </StyledAppBar>
    // <Toolbar />
  );
}
