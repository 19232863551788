import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Card, Typography, Skeleton, backdropClasses, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

// utils
// components
import SearchContext from '../../contexts/SearchContext';
import { FavouritesIcon, ShareIcon } from '../../components/car-images/index';
import { CarBasicInfoMobile, CarCarouselImages, CarContactInfo, CarsKeySpecsSection, CarPriceInfo } from '../car/index';
import { Description } from '../../components/car-labels';
// ----------------------------------------------------------------------

const StyledCarImg = styled('img')({
  top: 0,
  width: '100%',
  // width: '90vw',
  // width: '400px',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

CarsCardMobile.propTypes = {
  product: PropTypes.object,
};

export default function CarsCardMobile({ car, searchIndex }) {
  const searchContext = useContext(SearchContext);
  const navigate = useNavigate();

  const navigateToPage = () => {
    // console.log('navigateToPage')
    searchContext.setCurrentIndex(searchIndex);
    navigate(`/car/${car.vin}`);
  };

  if (!car) {
    return <Skeleton variant="rectangular" width={210} />;
  }

  const {
    make,
    model,
    description,
    // chassis,
    gearbox,
    firstRegistrationDate,
    firstRegistrationAsDate,
    firstRegistrationAsYear,
    mileage,
    bodyType,
    fuel,
    engineSize,
    co2,
    enginePowerHP,
    enginePowerKW,
    gearboxType,
    emissionClass,
    power,
    doors,
    numberOfPlaces,
    countryOrigin,
    linkToOriginalSite,
    auction,
    color,
    price,
    images,
  } = car;

  return (
    <Card
      sx={{
        p: 0.67,
        mb: 1,
        // backgroundColor: 'red',
        // width: '400px',
        // width: '100%',

        width: {
          // xs: '90vw',
          // sm: '80vw',
          // md: '45vw',
          xs: '100%',
          // sm: '45%',
          // md: '32%',
          // lg: '32%',
          // xl: '15%',
        },
        // maxWidth: '400px'
      }}
    >
      {false && (
        <Typography
          color="text.primary"
          variant="h5"
          noWrap
          sx={{
            width: {
              xs: '100%',
            },
            // display: {
            //   xs: 'none',
            //   sm: 'block'
            // },
            p: 1,
            // backgroundColor: 'yellow'
          }}
        >
          <Description description={car.description} />
          {/* {car.description} */}
        </Typography>
      )}

      {/* // BOX MOBILE - to be continued */}
      <Box sx={{
        pt: '100%',
        position: 'relative'
      }}>

        {true && <CarCarouselImages
          car={car}
          isCarsPage
          numberOfImagesAllowed={5}
          onClickHandler={() => { }}
          sx={{
            top: 0,
            width: '100%',
            height: '100%',
            // objectFit: 'cover',
            objectFit: 'contain',
            position: 'absolute',
          }}
        />}

        <FavouritesIcon car={car} />

        <ShareIcon car={car} />

        {false && images[0] ? (
          <StyledCarImg
            alt={description}
            src={`${images[0]}`}
          // src={`${BE_URL}/images/${images[0]}`}
          // onClick={e => navigateToPage()}
          />
        ) : null}
      </Box>

      <CarBasicInfoMobile car={car} />
      <CarPriceInfo car={car} />
      <CarsKeySpecsSection car={car} mobileBasicInfo isMobile />
      {/* <CarLocationInfo car={car} /> */}
      <CarContactInfo car={car} showHeader isMobile />
    </Card>
  );
}
