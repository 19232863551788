import { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

// @mui
import { Typography, Slider } from '@mui/material';
// components
import SearchContext from '../../contexts/SearchContext';
import { filterUnique } from '../../utils/filterUnique';
import { DEFAULT_CO2_RANGE } from '../../config/config';

// ----------------------------------------------------------------------

CO2Filter.propTypes = {};

export default function CO2Filter() {
  const intl = useIntl();

  const searchContext = useContext(SearchContext);
  const { co2Range, setCO2Range, setCO2Filters } = searchContext;
  const [co2Min, co2Max] = DEFAULT_CO2_RANGE;

  const marks = [
    {
      value: co2Min,
      label: co2Min,
    },
    {
      value: co2Min + ((co2Max - co2Min) * 1) / 3,
      label: co2Min + ((co2Max - co2Min) * 1) / 3,
    },
    {
      value: co2Min + Math.round(((co2Max - co2Min) * 2) / 3),
      label: co2Min + Math.round(((co2Max - co2Min) * 2) / 3),
    },
    {
      value: co2Max,
      label: co2Max,
    },
  ];

  const co2Valuetext = (value) => `${value}`;

  const co2Label = (co2Range) => {
    const co2s = co2Range.filter(filterUnique);
    return `${co2s.join('->')} ${intl.formatMessage({ id: 'commons.co2' })}`;
  };

  const handleCO2RangeChange = (event, newRange) => {
    setCO2Range(newRange);
    searchContext.updateRangeAndFilter(newRange, DEFAULT_CO2_RANGE, 'co2');
  };

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
      <FormattedMessage id="filter.co2.title" />
      </Typography>
      {/* <Box>&nbsp;</Box> */}
      <Slider
        valueLabelDisplay="auto"
        min={co2Min}
        max={co2Max}
        step={100}
        getAriaLabel={() => 'CO2 range'}
        value={co2Range}
        onChange={handleCO2RangeChange}
        marks={marks}
        getAriaValueText={co2Valuetext}
      />
      {/* <Typography variant="subtitle2" gutterBottom>
        {co2Label(co2Range)}
      </Typography> */}
    </div>
  );
}
