import PropTypes from 'prop-types';
import { Avatar, IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
// @mui

// ----------------------------------------------------------------------

FavouritesIcon.propTypes = {
  auctionType: PropTypes.func,
};

export default function FavouritesIcon({ car }) {

  const handleFavourites = (car) => {
    console.log('to vbe implemented');
  };

  return false && car ? (
    <Avatar
      sx={{
        zIndex: 9,
        top: 16,
        right: 16,
        position: 'absolute',
        backgroundColor: "background.neutral"
      }}
    >
      <IconButton
        variant="contained"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleFavourites(car);
        }}
      >
        {car.isFavourite ? <StarIcon /> : <StarBorderIcon />}
      </IconButton>
    </Avatar>
  ) : null;
}
