import { Box } from '@mui/material';
import PropTypes from 'prop-types';
// @mui

// ----------------------------------------------------------------------

Doors.propTypes = {
  doors: PropTypes.number.isRequired,
};

export default function Doors({ intl, doors }) {
  return <>{doors}</>;
}
