import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from "react-router-dom";
// @mui
import { Container, Typography } from '@mui/material';
// components
import authService from '../services/auth.service';
import PageContext from '../contexts/PageContext';

export default function VerifyEmailPage() {
  const navigate = useNavigate();
  const intl = useIntl();

  const [searchParams] = useSearchParams();
  const [token, setToken] = useState();

  useEffect(() => {
    if (searchParams && searchParams.has('token')) {
      setToken(searchParams.get('token'))
    }
  }, [searchParams])

  useEffect(() => {
    const verifyEmailToken = async (token) => {
      try {
        await authService.verifyEmail(token);
        navigate('/');
      } catch (err) {
        // console.log('no refresh token found')
        navigate('/');
      }
    };

    verifyEmailToken(token);
  }, [token]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'application.name' })}
          {' | '}
          {intl.formatMessage({ id: 'page.title.verify-mail' })}
        </title>
      </Helmet>

      <Container>
        <Typography variant="caption2" sx={{ mb: 5 }}>
          <FormattedMessage id="page.verify-mail.please-wait" />
        </Typography>
      </Container>
    </>
  );
}
