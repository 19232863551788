import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Problem.propTypes = {
  problem: PropTypes.object.isRequired,
};

export default function Problem({ intl, problem }) {
  return <>{problem}</>;
}
