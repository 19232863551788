import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';

// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Tooltip } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Iconify from '../../components/iconify';
// sections
import { LoginForm } from '.';
import { BE_URL } from '../../config/config';

const LoginFederationLinks = () => (
    <Stack direction="row" spacing={2}>
    <Button
        fullWidth
        size="large"
        color="inherit"
        variant="outlined"
        href={`${BE_URL}/auth/login/federated/google`}
    >
        <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
    </Button>

    <Button
        fullWidth
        size="large"
        color="inherit"
        variant="outlined"
        href={`${BE_URL}/auth/login/federated/facebook`}
    >
        <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
    </Button>

    {/* <Tooltip describeChild title="Coming soon">
<span>
<Button disabled fullWidth size="large" color="inherit" variant="outlined">
<Iconify icon="ant-design:apple-filled" color="#000" width={22} height={22} />
</Button>
</span>
</Tooltip> */}
</Stack>
)

export default LoginFederationLinks;