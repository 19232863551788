import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Document.propTypes = {
  document: PropTypes.object.isRequired,
};

export default function Document({ intl, document }) {
  return <>{document}</>;
}
