import React from 'react';
import {
  FacebookShare,
  TwitterShare,
  WhatsappShare,
  TelegramShare,
  FacebookMessengerShare,
  EmailShare,
} from 'react-share-kit';

import PropTypes from 'prop-types';
import { Avatar, Dialog, DialogContent, DialogTitle, IconButton, Box } from '@mui/material';
import ShareMUIIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage, useIntl } from 'react-intl';
import { Masonry } from '@mui/lab';
import { useTheme } from '@emotion/react';

// @mui

// ----------------------------------------------------------------------

ShareIcon.propTypes = {
  handleShare: PropTypes.func,
};

export default function ShareIcon({ car, useRelative = false }) {
  const intl = useIntl()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false);

  const handleClose = (e) => {
    if (e) e.stopPropagation()
    setOpen(false);
  };

  const handleShare = () => {
    setOpen(true)
  };


  const ShareDialog = ({ open, onClose }) => {
    const onClickShare = (e) => {
      e.preventDefault();
      e.stopPropagation();
      // handleShare(car);
      onClose(e)
    }
    const applicationName = `${intl.formatMessage({ id: "application.name" })}`
    const title = `${car.shortDescription} @ ${applicationName} `
    const subject = `🏎️ ${title} `
    return (
      <Dialog maxWidth="sm" onClose={onClose} open={open} onClick={e => e.stopPropagation()}>
        <DialogTitle sx={{ display: 'flex', alignContent: 'baseline', color: 'primary.main' }}>
          <ShareMUIIcon sx={{ color: 'primary.main', mr: 1.5 }} />
          <FormattedMessage id="commons.social-share.dialog.title" />
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={e => onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5, justifyContent: 'center' }} >
            {/* <Masonry columns={3} spacing={2} > */}
            <FacebookShare
              round
              url={car.url}
              title={title}
              onClick={onClickShare}
            />
            <WhatsappShare
              round
              url={car.url}
              onClick={onClickShare}
              title={title}
              separator=" 🚘 "
            />
            <EmailShare
              round
              url={car.url}
              title={title}
              subject={subject}
              body={`${title} \n`}
            />
            <TelegramShare
              round
              url={car.url}
              title={subject}
              onClick={onClickShare}
            />

            <TwitterShare
              round
              url={car.url}
              onClick={onClickShare}
              title={`${subject} \n`}
              hashtags={[car.make, car.model, applicationName]}
            />
            {/* </Masonry> */}
          </Box>
        </DialogContent>
      </Dialog>
    );
  }


  return car ? (<>
    <Avatar
      sx={useRelative ? {
         backgroundColor: "background.neutral"
      } : {
        zIndex: 9,
        top: 16,
        left: 16,
        // right: 16,
        position: 'absolute',
        backgroundColor: "background.neutral"
      }}
    >
      <IconButton
        variant="contained"
        color="primary"
        // color="secondary"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleShare(car);
        }}
      >
        <ShareMUIIcon sx={{ color: 'primary.main' }} />
      </IconButton>
    </Avatar>

    <ShareDialog
      open={open}
      car={car}
      onClose={handleClose} />
  </>
  ) : null;
}
