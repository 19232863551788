import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// @mui

// ----------------------------------------------------------------------

CO2.propTypes = {
  co2: PropTypes.string.isRequired,
};

export default function CO2({ intl, co2 }) {
  return (
    <>
      <>
        {co2} <FormattedMessage id="commons.gkm" />
      </>
    </>
  );
}
