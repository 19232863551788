// component
import SvgColor from '../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'login',
  //   path: '/login',
  //   roles: ['guest'],
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'home',
  //   path: '/dashboard/home',
  //   roles: ['guest', 'user', 'admin'],
  //   // icon: icon('ic_blog'),
  // },
  // {
  //   title: 'home guest',
  //   path: '/dashboard/home',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'car',
  //   path: '/car/652dad5b3e62c0b102289e82',
  //   icon: icon('ic_disabled'),
  // },
  // {
  //   title: 'home',
  //   path: '/home',
  //   roles: ['guest', 'user', 'admin'],
  //   // icon: icon('ic_blog'),
  // },
  // {
  //   title: 'cars',
  //   path: '/cars',
  //   roles: ['user', 'admin'],
  //   // icon: icon('ic_cart'),
  // },
  // {
  //   title: 'FAQ',
  //   path: '/blog',
  //   roles: ['guest', 'user', 'admin'],
  //   // icon: icon('ic_blog'),
  // },
  // {
  //   title: 'products',
  //   path: '/dashboard/products',
  //   icon: icon('ic_disabled'),
  // },
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: icon('ic_disabled'),
  // },
  // {
  //   title: 'users',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
