import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
// @mui
import { Typography, Slider } from '@mui/material';
// components
import SearchContext from '../../contexts/SearchContext';
import { filterUnique } from '../../utils/filterUnique';
import { numFormatter } from '../../utils/numFormatterForFilter';
import { scaleByMarks } from '../../utils/scale';
import { DEFAULT_PRICE_RANGE } from '../../config/config';

// ----------------------------------------------------------------------

PriceFilter.propTypes = {};

export default function PriceFilter() {
  const searchContext = useContext(SearchContext);

  const [priceMin, priceMax] = DEFAULT_PRICE_RANGE;

  const priceMarks = [
    {
      value: priceMin,
      scaledValue: 1000,
      label: '1k',
    },
    {
      value: 25,
      scaledValue: 5000,
      label: '5k',
    },
    {
      value: 50,
      scaledValue: 10000,
      label: '10k',
    },
    {
      value: 75,
      scaledValue: 25000,
      label: '25k',
    },
    {
      value: 100,
      scaledValue: 50000,
      label: '50k',
    },
    {
      value: 125,
      scaledValue: 100000,
      label: '100k',
    },
    {
      value: priceMax,
      scaledValue: 250000,
      label: '250k',
    },
  ];
  const scalePrice = scaleByMarks(priceMarks);

  const priceValuetext = (value) => `${value} &eur;`;

  const priceRangeLabel = (priceRange) => {
    const prices = priceRange.filter(filterUnique).map((x) => scalePrice(x));
    return prices.length > 1 ? `Price range: ${prices.join('->')}` : `Price: ${prices.join('->')}`;
  };

  const handlePriceRangeChange = (event, newRange) => {
    searchContext.setPriceRange(newRange);
    searchContext.updateRangeAndFilter(newRange, DEFAULT_PRICE_RANGE, 'price', scalePrice);
  };

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage id="filter.price.title" />
      </Typography>
      <Slider
        valueLabelDisplay="auto"
        min={priceMin}
        step={1}
        max={priceMax}
        valueLabelFormat={numFormatter}
        marks={priceMarks}
        scale={scalePrice}
        getAriaLabel={() => 'Price range'}
        value={searchContext.priceRange}
        onChange={handlePriceRangeChange}
        getAriaValueText={priceValuetext}
        aria-labelledby="non-linear-slider"
      />
      {/* <Typography variant="subtitle2" gutterBottom>
        {priceRangeLabel(searchContext.priceRange)}
      </Typography> */}
    </div>
  );
}
