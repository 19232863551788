import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { init, useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';

// @mui
import { useTheme } from '@emotion/react';
import { Box, useMediaQuery } from '@mui/material';

// component

// ----------------------------------------------------------------------
init({
  // options
});
export default function DemoTv(props) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const location = useLocation();

  // const { refYellow, focusedYellow } = useFocusable();
  // const { refRed, focusedRed } = useFocusable();
  // const { refBrown, focusedBrown } = useFocusable();

  const [selectedAsset, setSelectedAsset] = useState(null);

  const onAssetPress = useCallback((asset) => {
    console.log('onAssetPress', asset);
    setSelectedAsset(asset);
  }, []);

  function Button({ label, onEnterPress }) {
    const { ref, focused, focusSelf } = useFocusable({ onEnterPress });

    useEffect(() => {
      if (selectedAsset === label) {
        focusSelf();
      }
    }, [focusSelf]);

    return (
      <Box
        ref={ref}
        focused={focused}
        label={label}
        sx={{
          backgroundColor: focused ? 'yellow' : undefined,
        }}
        // onEnterPress={e => console.log(label)}
        // onEnterRelease={onAssetPress}
      >
        Press me {label}
      </Box>
    );
  }

  function Wrapper({ focusKey: menuFocusKey, children }) {
    const { ref, focusSelf, focusKey, hasFocusedChild } = useFocusable({
      focusable: true,
      saveLastFocusedChild: false,
      trackChildren: true,
      autoRestoreFocus: true,
      isFocusBoundary: false,
      focusKey: menuFocusKey,
      preferredChildFocusKey: null,
      // onEnterPress: onAssetPress,
      onEnterPress: () => {},
      onEnterRelease: () => {},
      onArrowPress: () => true,
      onFocus: () => {},
      onBlur: () => {},
    });

    useEffect(() => {
      if (!selectedAsset) {
        focusSelf();
      }
    }, [focusSelf]);

    return (
      <FocusContext.Provider value={focusKey}>
        <Box ref={ref} hasFocusedChild={hasFocusedChild}>
          {children}
          <Button label={1} onEnterPress={(e) => onAssetPress(1)} />
          <Button label={2} onEnterPress={(e) => onAssetPress(2)} />
          <Button label={3} onEnterPress={(e) => onAssetPress(3)} />
          <Button label={4} onEnterPress={(e) => onAssetPress(4)} />
          <Button label={5} onEnterPress={(e) => onAssetPress(5)} />
        </Box>
      </FocusContext.Provider>
    );
  }

  return (
    <>
      <Wrapper focusKey="FOCUS_KEY">Last pressed {selectedAsset}</Wrapper>
    </>
  );
}
