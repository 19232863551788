import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
// @mui
import { Box, Divider, Typography } from '@mui/material';
import {
  Fuel,
  EngineSize,
  CO2,
  EnginePower,
  Doors,
  NumberOfPlaces,
  CountryOrigin,
  EmissionClass,
  Gearbox,
  Mileage,
  FirstRegistration,
  KeySpecComponent,
} from '../../components/car-labels/index';
// ----------------------------------------------------------------------

CarsKeySpecsSection.propTypes = {
  car: PropTypes.object.isRequired,
};

const CarsKeySpecComponent = ({ intl, label, title, icon, isDetail, isSmall, isMobile, children }) => {
  const labelVariant = isMobile ? 'body1' : (isSmall ? 'body2' : 'body1')

  return <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      // px: 1,
    }}
  >
    {icon ? (
      <Box
        sx={{
          // backgroundColor: 'red',
          flexShrink: 0,
          minWidth: '3rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src={`/assets/icons/${icon}`} alt={icon} height={24} />
      </Box>
    ) : null}
    {label ? (
      <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
          color: 'primary.main',
          flexShrink: 0,
          minWidth: '3rem',
        }}
      >
        {intl.formatMessage({ id: label }) ? <FormattedMessage id={label} defaultMessage={label} /> : label}
      </Typography>
    ) : null}
    <Box
      component="div"
      sx={{
        width: '100%',
        // backgroundColor: 'violet',
        flexGrow: 1,
        flexShrink: 1,
        display: 'flex',
        flexWrap: 'wrap',
        height: isSmall ? '2.5rem' : '4rem',
        alignContent: 'center',
      }}
    >
      {title ? (
        <Typography
          variant={labelVariant}
          sx={{
            color: 'primary',
            flexShrink: 0,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            pr: 1,
            fontWeight: 'bold',
          }}
        >
          {intl.formatMessage({ id: title }) ? <FormattedMessage id={title} defaultMessage={title} /> : title}
        </Typography>
      ) : null}

      <Typography
        variant={labelVariant}
        sx={{
          flexShrink: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </Typography>
    </Box>
  </Box>
};

const KeySpecsConstants = {
  mileage: 'mileage',
  firstRegistration: 'firstRegistration',
  engine: 'engine',
  gearbox: 'gearbox',
  fuel: 'fuel',
  power: 'power',
  co2: 'co2',
  emissionClass: 'emissionClass',
  co2EmissionClass: 'co2EmissionClass',
  numberOfPlaces: 'numberOfPlaces',
  doors: 'doors',
  countryOrigin: 'countryOrigin',
}

const mobileBasicInfoKeys = [
  KeySpecsConstants.firstRegistration,
  KeySpecsConstants.mileage,
  KeySpecsConstants.fuel,
  KeySpecsConstants.countryOrigin,

  // KeySpecsConstants.engine,
  // KeySpecsConstants.gearbox,
  // KeySpecsConstants.power,
]
const basicInfoKeys = [
  KeySpecsConstants.firstRegistration,
  KeySpecsConstants.mileage,
  KeySpecsConstants.engine,
  KeySpecsConstants.fuel,
  KeySpecsConstants.gearbox,
  KeySpecsConstants.power,
]

const excludingBasicInfoKeys = [
  // KeySpecsConstants.mileage,
  // KeySpecsConstants.firstRegistration,
  // KeySpecsConstants.power,
  KeySpecsConstants.co2,
  KeySpecsConstants.emissionClass,
  // KeySpecsConstants.co2EmissionClass,
  KeySpecsConstants.numberOfPlaces,
  KeySpecsConstants.doors,
  // KeySpecsConstants.countryOrigin,
]

const blackListedKeys = [
  // KeySpecsConstants.mileage,
  // KeySpecsConstants.firstRegistration,
  KeySpecsConstants.co2EmissionClass,
  KeySpecsConstants.countryOrigin,
]

const availableKeys = Object.keys(KeySpecsConstants).filter(key => !blackListedKeys.includes(key))

// KeySpecsConstants.mileage,
// KeySpecsConstants.firstRegistration,
// KeySpecsConstants.engine,
// KeySpecsConstants.gearbox,
// KeySpecsConstants.fuel,
// KeySpecsConstants.power,
// KeySpecsConstants.co2,
// KeySpecsConstants.emissionClass,
// KeySpecsConstants.co2EmissionClass,
// KeySpecsConstants.numberOfPlaces,
// KeySpecsConstants.doors,
// KeySpecsConstants.countryOrigin,

const allElements = (car) => ([
  // {
  //     // label: 'price',// + intl.formatMessage({ id: 'commons.eur' }),
  //     label: 'car.price.withVAT',
  //     visible: car.price && car.price.selling,
  //     component: <Box>
  //         {`${car.price.selling} ${intl.formatMessage({ id: 'commons.eur' })}`}
  //     </Box>
  // },
  {
    key: KeySpecsConstants.mileage,
    icon: 'mileage.svg',
    title: 'car.mileage.label',
    visible: car.mileage,
    component: <Mileage mileage={car.mileage} />
  },
  {
    key: KeySpecsConstants.firstRegistration,
    icon: 'calendar.svg',
    title: 'car.firstRegistrationAsYear.label',
    visible: car.firstRegistrationDate,
    component: <FirstRegistration firstRegistrationDate={car.firstRegistrationDate} />
  },
  {
    key: KeySpecsConstants.engine,
    icon: 'engine.svg',
    title: 'car.engine.label',
    // title: 'car.engine.label',
    visible: car.engineSize || car.enginePowerKW || car.enginePowerHP,
    component: (
      <Box>
        {car.engineSize ? <EngineSize engineSize={car.engineSize} /> : null}
        {/* <Box sx={{ display: 'flex', flexWrap: 'nowrap' }} >
                      {car.enginePowerKW ? <><EnginePower powerValue={car.enginePowerKW} symbol="kw" />&nbsp;</> : null}
                      {car.enginePowerHP ? <EnginePower powerValue={car.enginePowerHP} symbol="hp" /> : null}
                      </Box> */}
      </Box>
    ),
  },
  {
    key: KeySpecsConstants.gearbox,
    icon: 'gearbox.svg',
    title: 'car.gearbox.label',
    visible: car.gearboxType,
    component: <Gearbox gearboxType={car.gearboxType} gearbox={car.gearbox} />,
  },
  {
    key: KeySpecsConstants.fuel,
    icon: 'fuel.svg',
    title: 'car.fuel.label',
    visible: car.fuel,
    component: <Fuel fuel={car.fuel} />,
  },
  {
    key: KeySpecsConstants.power,
    icon: 'power.svg',
    title: 'car.power.label',
    visible: car.enginePowerKW || car.enginePowerHP,
    component: (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        <EnginePower powerValue={car.enginePowerHP} symbol="hp" powerValue2={car.enginePowerKW} symbol2="kw" />
        {/* {car.enginePowerKW ? (
          <>
            <EnginePower powerValue={car.enginePowerKW} symbol="kw" />
            {' '}
          </>
        ) : null}
        {car.enginePowerHP ? <EnginePower powerValue={car.enginePowerHP} symbol="hp" /> : null} */}
      </Box>
    ),
  },
  {
    key: KeySpecsConstants.co2,
    icon: 'eco.svg',
    title: 'car.co2.label',
    visible: car.co2,
    component: (
      <Box>
        {car.co2 ? <CO2 co2={car.co2} /> : null}
      </Box>
    ),
  },
  {
    key: KeySpecsConstants.emissionClass,
    icon: 'plug.svg',
    // icon: 'green.png',
    // icon: 'eco.svg',
    // label: 'car.co2.label',
    title: 'car.emissionClass.label',
    visible: car.emissionClass,
    component: (
      <Box>
        {car.emissionClass ? <EmissionClass emissionClass={car.emissionClass} /> : null}
      </Box>
    ),
  },
  {
    key: KeySpecsConstants.co2EmissionClass,
    // icon: 'plug-green',
    // icon: 'green.png',
    icon: 'eco.svg',
    // label: 'car.co2.label',
    title: 'car.co2.label',
    visible: car.emissionClass || car.co2,
    component: (
      <Box>
        {car.emissionClass ? <EmissionClass emissionClass={car.emissionClass} /> : null}
        {car.co2 ? <CO2 co2={car.co2} /> : null}
      </Box>
    ),
  },

  // {
  //     icon: 'bodyType',
  //     visible: car.bodyType,
  //     component: <BodyType bodyType={car.bodyType} />
  // },
  {
    key: KeySpecsConstants.numberOfPlaces,
    icon: 'seat.svg',
    title: 'car.numberOfPlaces.label',
    visible: car.numberOfPlaces,
    component: <NumberOfPlaces numberOfPlaces={car.numberOfPlaces} />,
  },
  {
    key: KeySpecsConstants.doors,
    icon: 'doors.svg',
    title: 'car.doors.label',
    visible: car.doors,
    component: <Doors doors={car.doors} />,
  },
  {
    key: KeySpecsConstants.countryOrigin,
    icon: 'location.svg',
    title: 'car.location.label',
    visible: car.countryOrigin,
    component: <CountryOrigin countryOrigin={car.countryOrigin} />,
  },
  // {
  //     label: 'color',
  //     visible: car.color,
  //     component: <Color color={car.color} />
  // },
  // {
  //     icon: 'global',
  //     visible: car.color,
  //     component: <CountryOrigin countryOrigin={car.countryOrigin} />
  // },
  // {
  //     label: 'auctionType',
  //     visible: car.auctionType,
  //     component:  <AuctionType auctionType={car.auctionType} />
  // },
  // {
  //   key: 'vin',
  //   label: 'car.vin.label',
  //   visible: car.vin,
  //   component: <VIN vin={car.vin} />
  // }
])

export default function CarsKeySpecsSection({
  car,
  basicInfo,
  onlyLocation,
  mobileBasicInfo,
  exludingBasicInfo,
  // allowedKeys = [],
  showHeader,
  isMobile,
  isSmall = true,
  columns
}) {
  const intl = useIntl();

  const [keySpecs, setKeySpecs] = useState([]);

  const getWidthComponent = (columns, isSmall, isMobile) => {
    if (columns) {
      switch (columns) {
        case 4: return '25%'
        case 1: return '33'
        case 2: return '50%'
        default:
      }
    }
    if (isMobile) {
      return '50%'
    }
    if (isSmall) {
      return '33%'
    }
    return '25%'
  }
  const widthComponent = getWidthComponent(columns, isSmall, isMobile)

  useEffect(() => {
    if (car) {

      const allowedKeys = onlyLocation
        ? [KeySpecsConstants.countryOrigin]
        : (basicInfo || mobileBasicInfo
          ? basicInfo ? basicInfoKeys : mobileBasicInfoKeys
          : (exludingBasicInfo
            ? excludingBasicInfoKeys
            : availableKeys))

      // console.log('keySpecs.allowedKeys', allowedKeys)

      const allowedElements = allowedKeys.length > 0
        ? allElements(car).filter(({ key }) => allowedKeys.includes(key))
        : allElements(car)

      const keySpecs = allowedElements.map((x) => ({ ...x })).filter(({ visible }) => visible)
      setKeySpecs(keySpecs);
    }
  }, [car]);
  return (
    <>
      {showHeader ? (
        <>
          <Divider />
          <Typography variant="h5" sx={{ pb: 2 }}>
            <FormattedMessage id="car.section.title.basicOverview" />
          </Typography>
        </>
      ) : null}
      <Box
        sx={{
          width: '100%',
          // backgroundColor: 'blue',
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
        }}
      >
        {keySpecs.length > 0
          ? keySpecs.map(({ key, icon, title, label, component }, index) => (
            <Box
              key={key}
              sx={{
                width: widthComponent,
                height: isMobile || isSmall ? '4rem' : '5rem'
              }}
            >
              <CarsKeySpecComponent key={key} intl={intl} title={title} icon={icon} label={label} isSmall={isSmall} isMobile={isMobile}>
                {component}
              </CarsKeySpecComponent>
            </Box>
          ))
          : null}
      </Box>
    </>
  );
}
