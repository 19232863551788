const config = require('../config/config');
const { get, getResponse } = require('../utils/fetch');

const { BE_URL: beURL } = config;

const getFields = async (autocomplete = true) =>
  // console.log('config', config)
  get(`${beURL}/fields${autocomplete ? '?autocomplete=true' : ''}`);

const getFieldById = async (id) =>
  // console.log('config', config)
  get(`${beURL}/fields/${id}`);

const getFieldByKey = async (key) =>
  // console.log('config', config)
  get(`${beURL}/fields/key/${key}`);

module.exports = {
  getFields,
  getFieldById,
  getFieldByKey,
};
