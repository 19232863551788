import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

// @mui
import {
  Typography,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead,
} from '@mui/material';
import {
  FirstRegistration,
  Mileage,
  Fuel,
  EngineSize,
  CO2,
  EnginePower,
  Doors,
  NumberOfPlaces,
  Color,
  CountryOrigin,
  BodyType,
  EmissionClass,
  Gearbox,
  Make,
  Model,
  VIN,
} from '../../components/car-labels/index';
// ----------------------------------------------------------------------

CarFeatureSummaryTable.propTypes = {
  car: PropTypes.object.isRequired,
};

export default function CarFeatureSummaryTable({ car }) {
  const intl = useIntl();

  const rows = [
    // { value: car.description, header: intl.formatMessage({ id: 'car.featureSummary.table.description' }) },
    {
      value: <FirstRegistration firstRegistrationDate={car.firstRegistrationDate} />,
      header: intl.formatMessage({ id: 'car.featureSummary.table.firstRegistrationDate' }),
    },
    { value: <Make make={car.make} />, header: intl.formatMessage({ id: 'car.featureSummary.table.make' }) },
    { value: <Model model={car.model} />, header: intl.formatMessage({ id: 'car.featureSummary.table.model' }) },
    {
      value: <Mileage mileage={car.mileage} />,
      header: intl.formatMessage({ id: 'car.featureSummary.table.mileage' }),
    },
    {
      value: <EngineSize engineSize={car.engineSize} />,
      header: intl.formatMessage({ id: 'car.featureSummary.table.engineSize' }),
    },
    {
      value: <EnginePower powerValue={car.enginePowerHP} symbol="hp" />,
      header: intl.formatMessage({ id: 'car.featureSummary.table.enginePowerHP' }),
    },
    {
      value: <EnginePower powerValue={car.enginePowerKW} symbol="kw" />,
      header: intl.formatMessage({ id: 'car.featureSummary.table.enginePowerKW' }),
    },
    { value: <Fuel fuel={car.fuel} />, header: intl.formatMessage({ id: 'car.featureSummary.table.fuel' }) },
    { value: <CO2 co2={car.co2} />, header: intl.formatMessage({ id: 'car.featureSummary.table.co2' }) },
    {
      value: <EmissionClass emissionClass={car.emissionClass} />,
      header: intl.formatMessage({ id: 'car.featureSummary.table.emissionClass' }),
    },
    {
      value: <Gearbox gearboxType={car.gearboxType} gearbox={car.gearbox} />,
      header: intl.formatMessage({ id: 'car.featureSummary.table.gearbox' }),
    },
    {
      value: <BodyType bodyType={car.bodyType} />,
      header: intl.formatMessage({ id: 'car.featureSummary.table.bodyType' }),
    },
    { value: <Color color={car.color} />, header: intl.formatMessage({ id: 'car.featureSummary.table.color' }) },
    { value: <Doors doors={car.doors} />, header: intl.formatMessage({ id: 'car.featureSummary.table.doors' }) },
    {
      value: <NumberOfPlaces numberOfPlaces={car.numberOfPlaces} />,
      header: intl.formatMessage({ id: 'car.featureSummary.table.numberOfPlaces' }),
    },
    {
      value: <CountryOrigin countryOrigin={car.countryOrigin} />,
      header: intl.formatMessage({ id: 'car.featureSummary.table.countryOrigin' }),
    },
    { value: <VIN vin={car.vin} />, header: 'VIN', width: 70 },
    // { value: <Auction auction={car.auction} />, header: intl.formatMessage({ id: 'car.featureSummary.table.auction' }) },
    // { value: <AuctionType auctionType={car.auctionType} />, header: intl.formatMessage({ id: 'car.featureSummary.table.auctionType' }) },
    // { value: car.price.selling, header: intl.formatMessage({ id: 'car.featureSummary.table.price.selling' }) },
    // { value: car.price.old, header: intl.formatMessage({ id: 'car.featureSummary.table.price.discounted' }) },
  ].filter(({ value }) => value);

  return (
    <TableContainer
      sx={{
        width: {
          xs: '100%',
          md: '66%',
        },
      }}
    >
      <Table>
        <TableHead>
          <TableRow colSpan={2}>
            <Typography variant="h5">
              <FormattedMessage id="car.featureSummary.table.title" />
            </Typography>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ header, value }) => (
            <TableRow key={header} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell size='small' component="td" scope="row">
                {header}
              </TableCell>
              <TableCell size='small' component="td" scope="row">
                {value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
