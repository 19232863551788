import React, { useContext, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { TextField, Link, Button, Box, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { authService } from '../../services';


const ForgotPasswordForm = () => {
    const intl = useIntl()
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [formProcessed, setFormProcessed] = useState(false)

    const initialValues = {
        email: ''
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email format')
            .required('Email is required')
    });

    const onSubmit = async (values, { setSubmitting }) => {
        // console.log('Form data', values);
        try {
            await authService.forgotPassword(values.email)
            enqueueSnackbar(intl.formatMessage({ id: 'page.forgot-password.email-sent-snackbar', values: { email: values.email } }))
        } catch (err) {
            // do nothing
            console.log('error', err)
        }
        setFormProcessed(true)
        setSubmitting(false);
        setTimeout(() => {
            navigate('/')
        }, 2500)
    };

    return (formProcessed
        ? (<Box sx={{ pb: 2 }}>
            <Typography variant="body2" color="text.secondary">
                <FormattedMessage id="page.forgot-password.email-sent" />
            </Typography>
        </Box>)
        : (<Box sx={{ pb: 4 }}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ touched, errors, isSubmitting, isValid, dirty }) => (
                    <Form>
                        <Field
                            as={TextField}
                            fullWidth
                            name="email"
                            type="email"
                            label="Email"
                            variant="outlined"
                            helperText={<ErrorMessage name="email" />}
                            error={errors.email}
                            touched={touched.email && touched.email.toString()}
                        />
                        <Button type="submit" variant='contained' disabled={isSubmitting || !isValid} sx={{ my: 2 }}>
                            {isSubmitting
                                ? intl.formatMessage({ id: 'page.forgot-password.button.sending' })
                                : intl.formatMessage({ id: 'page.forgot-password.button.send' })
                            }
                        </Button>
                    </Form>
                )}
            </Formik>
        </Box>)
    );
};

export default ForgotPasswordForm;
