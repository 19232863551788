import { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link, Box, Breadcrumbs, Paper, IconButton, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SearchIcon from '@mui/icons-material/Search';
import { Make, Model } from '../../components/car-labels/index';
import SearchContext from '../../contexts/SearchContext';

// @mui

// ----------------------------------------------------------------------

CarBreadcrumb.propTypes = {
  car: PropTypes.object.isRequired,
};

export default function CarBreadcrumb({ intl, car }) {
  const searchContext = useContext(SearchContext);

  return (
    <Paper elevation={3} sx={{ my: 2, p: 1, width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <Breadcrumbs
          flexShrink={0}
          aria-label="breadcrumb"
          sx={{
            flexGrow: 1,
            alignContent: 'center',
            display: 'inline-flex',
          }}
        >
          <Link underline="hover" color="inherit" variant="h6"
            onClick={(e) => searchContext.navigateToSearch()}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <SearchIcon fontSize={'medium'} />
            <FormattedMessage id="car.breadcrumb.searchResults" />
          </Link>
          <Link underline="hover" color="inherit" variant="h6"
            onClick={(e) => searchContext.navigateToSimplifiedSearch({
              byMakeModel: {
                make: car.make
              }
            })} >
            <Make make={car.make} />
          </Link>
          <Link underline="hover" color="inherit" variant="h6"
            onClick={(e) => searchContext.navigateToSimplifiedSearch({
              byMakeModel: {
                make: car.make,
                model: car.model
              }
            })}>
            <Model model={car.model} />
          </Link>
        </Breadcrumbs>

        {/* <Box sx={{ flexGrow: 1, color: 'blue' }} /> */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
          {searchContext.results.length > 0 ? (
            <Box
              flexShrink={0}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* <Box sx={{ flexShrink: 1, flexGrow: 1, color: 'red' }} /> */}
              {/* <Box sx={{ flexGrow: 1, color: 'red' }} /> */}
              <IconButton color="text.secondary" disableRipple onClick={(e) => searchContext.navigateToPrevious()}>
                <NavigateBeforeIcon />
              </IconButton>
              <Typography component="span" sx={{ color: 'text.secondary' }}>
                {searchContext.currentIndex + 1} / {searchContext.results.length}
              </Typography>
              <IconButton color="text.secondary" disableRipple onClick={(e) => searchContext.navigateToNext()}>
                <NavigateNextIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Paper >
  );
}
