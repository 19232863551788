import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import Carousel from 'react-multi-carousel';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import 'react-multi-carousel/lib/styles.css';
// @mui
import {
  Box,
} from '@mui/material';
// components
import SearchContext from '../../contexts/SearchContext';
import CarsListItem from './CarsListItem';

// ----------------------------------------------------------------------
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

CarsListCarousel.propTypes = {
  product: PropTypes.object,
};

export default function CarsListCarousel({ car, searchIndex }) {
  const theme = useTheme();
  const searchContext = useContext(SearchContext);
  const carouselRef = useRef(null);
  // const [selectedAsset, setSelectedAsset] = useState(0);
  const menuFocusKey = 'CARS_LIST';
  const { ref, focusSelf, focusKey, hasFocusedChild } = useFocusable({
    focusable: true,
    saveLastFocusedChild: false,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    focusKey: menuFocusKey,
    preferredChildFocusKey: null,
    // onEnterPress: onAssetPress,
    onEnterPress: () => { },
    onEnterRelease: () => { },
    onArrowPress: () => true,
    onFocus: () => { },
    onBlur: () => { },
  });

  const onAssetPress = (asset) => {
    const { current } = carouselRef;
    if(current.state.isMoving){
      return
    }
    // console.log('onAssetPress', asset);
    // setSelectedAsset(asset);
    searchContext.setCurrentIndex(asset);
    // setSelectedAsset(asset);
  };

  // const onAssetPress = useCallback((asset) => {
  //   // console.log('onAssetPress', asset);
  //   // setSelectedAsset(asset);
  //   searchContext.setCurrentIndex(asset);
  //   setSelectedAsset(asset);
  // }, []);

  // useEffect(() => {
  //   if (!selectedAsset) {
  //     focusSelf();
  //   }
  // }, [focusSelf]);

  useEffect(() => {
    // it is needed when the user clicks on a ListItem
    const { current } = carouselRef;
    if (current.state.currentSlide !== searchContext.currentIndex) {
      current.goToSlide(searchContext.currentIndex, { skipAfterChange: false });
    }
  }, [searchContext.currentIndex]);

  return searchContext.results.length > 0 ? (
    <Box
    // direction="row"
    // spacing={2}
    // sx={{
    //   height: '20%',
    // }}
    >
      <FocusContext.Provider value={focusKey}>
        <Carousel
          ref={carouselRef}
          responsive={responsive}
          autoPlay
          arrows={false}
          focusOnSelect
          // infinite
          // ssr
          autoPlaySpeed={30000}
          beforeChange={() => carouselRef.current.setState({ isMoving: true })}
          // afterChange={() => this.setState({ isMoving: false })}
          afterChange={(previousSlide, { currentSlide, onMove }) => {
            carouselRef.current.setState({ isMoving: false })
            if (currentSlide !== searchContext.currentIndex) {
              searchContext.setCurrentIndex(currentSlide);
            }
            // setSelectedAsset(currentSlide);
          }}
        >
          {searchContext.results.map((car, index) => (
            <CarsListItem
              key={car.vin}
              car={car}
              index={index}
              // selectedAsset={selectedAsset}
              selectedAsset={searchContext.currentIndex}
              onEnterPress={(e) => onAssetPress(index)}
              onFocus={(e) => onAssetPress(index)}
              onClick={(e) => onAssetPress(index)}
            />
          ))}
        </Carousel>
      </FocusContext.Provider>
    </Box>
  ) : null;
}
