import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';

// @mui
import { Box } from '@mui/material';


// ----------------------------------------------------------------------

CarCardTopTv.propTypes = {
  vin: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
};

export default function CarCardTopTv({ vin, images }) {
  const intl = useIntl();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(0);
  }, [vin]);

  return images && images.length > 0 ? (
    <>
      <Carousel
        key={vin}
        interval={2500}
        animation="fade"
        indicators={false}
        index={currentIndex}
        next={(now, previous) => setCurrentIndex(now)}
        prev={(now, previous) => setCurrentIndex(now)}
      >
        {images.map((item, index) => {
          const bgImage = Math.abs((currentIndex - index) % images.length) < 2 ? `url(${item})` : undefined;
          return (
            <Box
              key={item}
              sx={{
                height: '60vh',
                // minHeight: '100%',
                // height: 'calc(90vw * (9 / 16) * (0.5))',
                // height: 'calc(90vw * (9 / 16) * (1))',
                // backgroundImage: `url(${BE_URL}/images/${item})`,
                backgroundImage: bgImage,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                // backgroundSize: 'contain'
              }}
            >
              {/* index: {index}
                                <br />
                                currentIndex: {currentIndex} */}

              {/* 
                                <LazyLoadImage
                                    key={index}
                                    src={item}
                                    alt={`Gallery image ${index}`}
                                    placeholderSrc={preloadedImages[index] || "placeholder.png"}
                                /> */}
            </Box>
          );
        })}
      </Carousel>
    </>
  ) : null;
}
