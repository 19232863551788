import ApiError from './ApiError';
import { getAccessToken, getRefreshToken } from '../services/asyncStorage.service';

const addTokenAuthorizationHeader = async (headers, useRefreshToken = false) => {
  const token = useRefreshToken ? getRefreshToken() : getAccessToken();
  if (token) {
    return {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return headers;
};

const handleGetResponse = async (response) => {
  // console.log(`handleGetResponse: ${response}`)
  // console.log(`handleGetResponse.JSON: ${JSON.stringify(response)}`)
  if (response.ok) {
    const json = await response.json();
    // console.log(`response(json): ${JSON.stringify(json)}`)
    return json;
  }
  const json = await response.json();
  // throw ApiError(response.code, response.message);
  throw new ApiError(json.code, json.message);
};

const handlePostResponse = async (response) => {
  // console.log(`handlePostResponse: ${JSON.stringify(response)}`)
  // console.log('handlePostResponse.ok:', response.ok)
  if (response.ok) {
    try {
      const json = await response.json();
      if (json) {
        // console.log(`handlePostResponse.ok.json: ${json}`)
        return json
      }
    } catch (err) {
      // do nothing
    }
    try {
      const text = await response.text();
      if (text) {
        // console.log(`handlePostResponse.ok.text: ${text}`)
        return text
      }
    } catch (err) {
      // do nothing
    }
    return null
  }
  const json = await response.json();
  // console.log(`handlePostResponse.error.json: ${json}`)
  // console.log(`handleGetResponse.error.JSON: ${JSON.stringify(json)}`)
  // console.log(`handlePostResponse.error.json.code: ${json.code}`)
  // console.log(`handlePostResponse.error.json.message: ${json.message}`)
  throw new ApiError(json.code, json.message);
  // throw Error(response.code, response.message);
};

const handleError = async (error) => {
  // console.log(`handleError.error: ${error}`);
  // console.log(`handleError.error.name: ${error.name}`);
  // console.log(`handleError.error.statusCode: ${error.statusCode}`);
  // console.log(`handleError.error.message: ${error.message}`);
  throw new ApiError(error.statusCode, error.message);
};

export const getResponse = async (url, data, options) => {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  // console.log('url', url)
  // console.log('data', data)
  // console.log('options && options.useRefreshToken', options && options.useRefreshToken)
  headers = await addTokenAuthorizationHeader(headers, options && options.useRefreshToken);
  // console.log('headers', headers)
  return fetch(url, {
    method: 'GET',
    headers,
    body: data ? JSON.stringify(data) : undefined,
  });
};

export const getBlob = async (url) => {
  let headers = {};
  //   'Accept': 'application/json',
  //   'Content-Type': 'application/json',
  // }
  // console.log('url', url);
  // console.log('options && options.useRefreshToken', options && options.useRefreshToken)
  headers = await addTokenAuthorizationHeader(headers);
  // console.log('getBlob - headers', headers)
  return fetch(url, {
    method: 'GET',
    headers,
  });
};

export const get = async (url, data, options) => getResponse(url, data, options)
  .then((response) => handleGetResponse(response))
  .catch((err) => handleError(err));
// .then(response => response.json())

export const post = async (url, data) => {
  const headers = await addTokenAuthorizationHeader({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })
  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  }).then((response) => handlePostResponse(response))
    .catch((err) => handleError(err));
}
// return handleResponse(response)
// // .then(handleResponse)
// .then(response => response.json)

export const patch = async (url, data) => {
  const headers = await addTokenAuthorizationHeader({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })
  return fetch(url, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(data),
  }).then((response) => handlePostResponse(response))
    .catch((err) => handleError(err));
}