import { Box } from '@mui/material';
import PropTypes from 'prop-types';
// @mui

// ----------------------------------------------------------------------

Description.propTypes = {
  make: PropTypes.string.isRequired,
};
const MAX_LENGTH = 32
export default function Description({ intl, description, shortDescription }) {
  const descriptionLabel = description && description.length > MAX_LENGTH ? `${description.substring(0, MAX_LENGTH - 1)}..` : description

  return descriptionLabel
    ? <Box component="span">{descriptionLabel}</Box>
    : (shortDescription
      ? <Box component="span">{shortDescription}</Box>
      : null
    );
}
