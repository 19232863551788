import { Box } from '@mui/material';
import PropTypes from 'prop-types';
// @mui

// ----------------------------------------------------------------------

Color.propTypes = {
  color: PropTypes.number.isRequired,
};

export default function Color({ intl, color }) {
  return <>{color}</>;
}
