import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// @mui
import { Typography, Paper, Box, Link, Stack, Divider } from '@mui/material';
import { Email, Phone } from '@mui/icons-material';
import CarLocationInfo from './CarLocationInfo';

// ----------------------------------------------------------------------

CarContactInfo.propTypes = {
  car: PropTypes.object.isRequired,
};

function LogoContact({ image, sx, isMobile }) {
  return <Box sx={sx}>
    <img src={image} alt="contact-logo" />
  </Box>
}

export default function CarContactInfo({ car, children, showHeader, hideDivider, isMobile }) {
  const { reseller } = car
  const fontVariant = isMobile ? 'body2' : 'body'
  return (reseller ? (
    <>
      {showHeader ? <>
        {hideDivider ? null : <Divider />}
        <Typography
          variant={isMobile ? "caption" : "h5"}
          color={isMobile ? "disabled" : "black"}
        >
          <b>
            <FormattedMessage id="car.section.title.contactInfo" />
          </b>
        </Typography></>
        : null}
      <Box sx={{ display: 'flex', alignContent: 'flex-end', alignItems: 'center' }}>
        {reseller.logoUrl ? <LogoContact image={reseller.logoUrl} sx={{
          flexGrow: 1,
          maxWidth: isMobile ? 70 : 100
        }}
          isMobile={isMobile} /> : null}
        <Stack direction={'column'} sx={{ p: 1, flexGrow: 10 }}>
          {reseller.name ? <Typography component='div' variant={fontVariant} color="text.secondary" sx={{ textAlign: 'left' }}>
            {reseller.link ? <Link href={reseller.link} color="primary" sx={{ textDecoration: 'none' }} target="_blank">{reseller.name}</Link> : reseller.name}
          </Typography> : null}
          {!isMobile && reseller.addressLine1 ? <Typography component='div' variant={fontVariant} color="text.secondary" sx={{ textAlign: 'left' }}>
            {reseller.addressLine1}
          </Typography> : null}
          {!isMobile && reseller.addressLine2 ? <Typography component='div' variant={fontVariant} color="text.secondary" sx={{ textAlign: 'left' }}>
            {reseller.addressLine2}
          </Typography> : null}

          {reseller.phone || reseller.email ? (
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexGrow: 1,
              gap: 1,
              alignItems: 'center',
              mt: isMobile ? 0.5 : 2
            }}>
              {[{
                value: reseller.phone,
                href: `tel:${reseller.phone}`,
                icon: <Phone color='primary' />
              }, {
                value: reseller.email,
                href: `mailto:${reseller.email}`,
                icon: <Email color='primary' />

              }]
                .filter(({ value }) => !!value)
                .map(({ value, href, icon }) => <>
                  <Box key={value} sx={{ display: 'flex', alignItems: 'end', gap: .5 }}>
                    {icon}
                    <Typography component='span' variant={fontVariant} color="primary" sx={{ textAlign: 'left' }}>
                      <Link underline='none' href={href}>
                        {value}
                      </Link>
                    </Typography>
                  </Box>
                </>)
              }
            </Box>) : null}
        </Stack>
      </Box>
      {children
        ? <>
          <Divider sx={{ mt: 1 }} />
          <Box sx={{ p: .5 }}>
            {children}
          </Box>
        </>
        : null}
      {/* 
        name
  addressLine1
  addressLine2
location
  phone
  email
link
  ogoPath */}
    </>
  ) : null
  );
}
