import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { carsService, fieldsService } from '../services';
import {
  DEFAULT_CC_RANGE,
  DEFAULT_CO2_RANGE,
  DEFAULT_FIRST_REGISTRATION_RANGE,
  DEFAULT_MILEAGE_RANGE,
  DEFAULT_POWER_RANGE,
  DEFAULT_PRICE_RANGE,
  DEFAULT_SORT_ORDER,
} from '../config/config';
import { SearchOption } from '../types/SearchOption';
import PageContext from './PageContext';

const SEARCH_OPTIONS_DELAY = 800

const SearchContext = React.createContext({
  search: async () => { },
  simplifiedSearch: async () => { },
  searchWithSortOrder: async () => { },
  addSearchOption: async () => { },
  reset: async () => { },

  results: [],
  totalResults: {},
  displayTotalResults: null,
  currentIndex: null,
  setCurrentIndex: () => { },

  isSearchExecuted: false,
  isFirstSearchExecuted: false,
  isLoading: false,

  navigateToSearch: () => { },
  navigateToSimplifiedSearch: () => { },
  navigateToPrevious: () => { },
  navigateToNext: () => { },
  handlePrevious: () => { },
  handleNext: () => { },

  // sort options
  sortOrder: [],
  setSortOrder: () => { },

  searchOptions: [],
  setSearchOptions: () => { },
  searchOptionsFilterBased: [],
  searchOptionsAutocompleteBased: [],

  getOptionsByField: () => { },
  fuelOptions: [],
  colorOptions: [],
  bodyTypeOptions: [],
  gearboxTypeOptions: [],
  emissionClassOptions: [],

  updateCheckboxAndFilter: () => { },
  updateRangeAndFilter: () => { },
  removeSearchOption: () => { },

  // range filters
  priceRange: [],
  setPriceRange: () => { },
  clearPriceFilter: () => { },

  firstRegistrationYearRange: [],
  setFirstRegistrationYearRange: () => { },
  clearFirstRegistrationYearFilter: () => { },

  ccRange: [],
  setCCRange: () => { },
  clearCCRangeFilter: () => { },

  mileageRang: [],
  setMileageRange: () => { },
  clearMileageFilter: () => { },

  co2Range: [],
  setCO2Range: () => { },
  clearCO2Filter: () => { },

  powerRange: [],
  setPowerRange: () => { },
  clearPowerFilter: () => { },
});

export const SearchContextProvider = (props) => {
  const navigate = useNavigate();
  const intervalRef = useRef();
  const pageContext = useContext(PageContext);

  const [isLoading, setLoading] = useState();
  const [isSearchExecuted, setIsSearchExecuted] = useState(false);
  const [isFirstSearchExecuted, setIsFirstSearchExecuted] = useState(false);

  const [currentIndex, setCurrentIndex] = useState();
  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState(-1);
  const displayTotalResults = useMemo(() => totalResults > 0 ? totalResults : 0, [totalResults])

  const [paginationParams, setPaginationParams] = useState({ pageNbr: 1, pageSize: 50 });
  const [sortOrder, setSortOrder] = useState([DEFAULT_SORT_ORDER]);

  const [searchOptions, setSearchOptions] = useState([]); // ie: { field, value, isMakeModel } // used for the autocomplete
  // Range options are working with an idle timeout. The follow sate save the latest searchOptions array available for the callback
  const [delayedSearchOptionHitCount, setDelayedSearchOptionHitCount] = useState(0)
  const searchOptionsFilterBased = useCallback(
    (searchFields) => searchOptions.filter((o) => !(searchFields || []).find((sf) => sf.getKey() === o.getKey())),
    [searchOptions]
  );
  const searchOptionsAutocompleteBased = useCallback(
    (searchFields) => searchOptions.filter((o) => (searchFields || []).find((sf) => sf.getKey() === o.getKey())),
    [searchOptions]
  );

  // default values
  const [fuelOptions, setFuelOptions] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const [bodyTypeOptions, setBodyTypeOptions] = useState([]);
  const [gearboxTypeOptions, setGearboxTypeOptions] = useState([]);
  const [emissionClassOptions, setEmissionClassOptions] = useState([]);

  // range filters
  const [priceRange, setPriceRange] = useState(DEFAULT_PRICE_RANGE);
  const clearPriceFilter = () => {
    setPriceRange(DEFAULT_PRICE_RANGE);
  };

  const [firstRegistrationYearRange, setFirstRegistrationYearRange] = useState(DEFAULT_FIRST_REGISTRATION_RANGE);
  const clearFirstRegistrationYearFilter = () => {
    setFirstRegistrationYearRange(DEFAULT_FIRST_REGISTRATION_RANGE);
  };

  const [ccRange, setCCRange] = useState(DEFAULT_CC_RANGE);
  const clearCCRangeFilter = () => {
    setCCRange(DEFAULT_CC_RANGE);
  };

  const [mileageRange, setMileageRange] = useState(DEFAULT_MILEAGE_RANGE);
  const clearMileageFilter = () => {
    setMileageRange(DEFAULT_MILEAGE_RANGE);
  };

  const [co2Range, setCO2Range] = useState(DEFAULT_CO2_RANGE);
  const clearCO2Filter = () => {
    setCO2Range(DEFAULT_CO2_RANGE);
  };

  const [powerRange, setPowerRange] = useState(DEFAULT_POWER_RANGE);
  const clearPowerFilter = () => {
    setPowerRange(DEFAULT_POWER_RANGE);
  };

  // options
  const getOptionsByField = (fieldName) => {
    switch (fieldName) {
      case 'fuel':
        return fuelOptions;
      case 'bodyType':
        return bodyTypeOptions;
      case 'color':
        return colorOptions;
      case 'gearboxType':
        return gearboxTypeOptions;
      case 'emissionClass':
        return emissionClassOptions;
      default:
        return [];
    }
  };

  // search
  const doSearch = async ({ searchOptionsOverride, sortOrderOverride, onlyCount }) => {
    // console.log('doSearch', onlyCount)
    setDelayedSearchOptionHitCount(0)
    setIsFirstSearchExecuted(true);
    setLoading(true);

    let effectiveSearchOptions;
    if (searchOptionsOverride) {
      effectiveSearchOptions = searchOptionsOverride;
    } else {
      effectiveSearchOptions = searchOptions;
    }
    const calculatedMappedValue = effectiveSearchOptions
      // .map(option => typeof option === 'string' ? { field: 'text', value: option } : option)
      .flatMap(({ field, value }) => [[field, value]]); // ie: [field, value]

    try {
      if (onlyCount) {
        const response = await carsService.countCars(calculatedMappedValue);
        const { totalResults } = response;
        setTotalResults(totalResults);
        console.log('countCars', totalResults);
      } else {
        const response = await carsService.getPaginatedCars(
          paginationParams.pageNbr,
          paginationParams.pageSize,
          calculatedMappedValue,
          sortOrderOverride || sortOrder
        );

        const { results, page, limit, totalPages, totalResults } = response;

        setResults(results);
        const index = results && results.length > 0 ? 0 : null;
        setCurrentIndex(index);
        setTotalResults(totalResults);

      }
    } catch (err) {
      // do nothing
    }
    setIsSearchExecuted(true);
    setLoading(false);
  };

  useEffect(() => {
    // console.info('isLoading', isLoading)
  }, [isLoading])

  const search = async () => {
    await doSearch({});
  };

  const simplifiedSearch = async ({ byKey, byMakeModel, featured }) => {
    const convertParams = (flatParam) => flatParam.split('&')
      .map(token => token.split('='))

    // remove all special property (key: vendorType, auctionType, etc... ) - remove the filter
    let response
    if (byKey) {
      switch (byKey) {
        case 'tv': {
          const params = convertParams('firstRegistrationYearMin=2018&mileageMax=100000&priceMin=7600&priceMax=100000')
          response = await carsService.getPaginatedCars(0, 100, params, sortOrder);
          break;
        }
        // case 'fixedPrice': {

        // }
        // case 'auctions': {

        // }
        // case 'exLeasing': {

        // }
        // case 'usedCertified': {

        // }
        default: {
          // do nothing
        }
      }
    }

    // remove all autocomplete properties and replace with mark/model - keeps the filter
    if (byMakeModel) {
      // setSearchOptions([previousValue => previousValue.filter({field})])
      setSearchOptions([])
      const { make, model } = byMakeModel
      if (!model) {
        const searchOptions = [new SearchOption({ field: 'make', value: make, isMakeModel: true })];
        addSearchOption(searchOptions, { onlyCount: false })
      } else {
        const searchOptions = [(new SearchOption({ field: make, value: model, isMakeModel: true }))]
        addSearchOption(searchOptions, { onlyCount: false })
      }
    }

    // remove all search options and use featured endpoint
    if (featured) {
      // TODO
    }


    if (response) {
      const { results, page, limit, totalPages, totalResults } = response;

      setResults(results);
      const index = results && results.length > 0 ? 0 : null;
      setCurrentIndex(index);
      setTotalResults(totalResults);
      setIsSearchExecuted(true);
      setLoading(false);
    }

  }

  const searchWithSortOrder = (sortOrder) => {
    setSortOrder(sortOrder);
    doSearch({ sortOrderOverride: sortOrder });
  };

  const reset = async ({ executeSearch = false }) => {
    clearPriceFilter();
    clearFirstRegistrationYearFilter();
    clearCCRangeFilter();
    clearMileageFilter();
    clearCO2Filter();
    clearPowerFilter();

    setSearchOptions([]);
    setResults([]);
    setCurrentIndex(null);
    setTotalResults(-1);
    setIsSearchExecuted(false);
    setIsFirstSearchExecuted(false);

    if (executeSearch) {
      doSearch({ searchOptionsOverride: [] })
    }
  };

  // searchbar
  const addSearchOption = async (searchOptions, { onlyCount = true }) => {
    setSearchOptions(searchOptions);
    await doSearch({ searchOptionsOverride: searchOptions, onlyCount });
  };

  const removeSearchOption = async (newValues, { onlyCount = true }) => {
    const removedItems = searchOptions.filter(({ field }) => !newValues.map(({ field }) => field).includes(field));
    if (removedItems.length === 1) {
      const [removedItem] = removedItems;
      switch (removedItem && removedItem.field) {
        case 'priceMin': {
          setPriceRange([DEFAULT_PRICE_RANGE[0], priceRange[1]]);
          break;
        }
        case 'priceMax': {
          setPriceRange([priceRange[0], DEFAULT_PRICE_RANGE[1]]);
          break;
        }
        case 'price': {
          setPriceRange(DEFAULT_PRICE_RANGE);
          break;
        }

        case 'firstRegistrationYearMin': {
          setFirstRegistrationYearRange([DEFAULT_FIRST_REGISTRATION_RANGE[0], firstRegistrationYearRange[1]]);
          break;
        }
        case 'firstRegistrationYearMax': {
          setFirstRegistrationYearRange([firstRegistrationYearRange[0], DEFAULT_FIRST_REGISTRATION_RANGE[1]]);
          break;
        }
        case 'firstRegistrationYear': {
          setFirstRegistrationYearRange(DEFAULT_FIRST_REGISTRATION_RANGE);
          break;
        }

        case 'ccMin': {
          setCCRange([DEFAULT_CC_RANGE[0], ccRange[1]]);
          break;
        }
        case 'ccMax': {
          setCCRange([ccRange[0], DEFAULT_CC_RANGE[1]]);
          break;
        }
        case 'cc': {
          setCCRange(DEFAULT_CC_RANGE);
          break;
        }

        case 'mileageMin': {
          setMileageRange([DEFAULT_MILEAGE_RANGE[0], mileageRange[1]]);
          break;
        }
        case 'mileageMax': {
          setMileageRange([mileageRange[0], DEFAULT_MILEAGE_RANGE[1]]);
          break;
        }
        case 'mileage': {
          setMileageRange(DEFAULT_MILEAGE_RANGE);
          break;
        }

        case 'co2Min': {
          setCO2Range([DEFAULT_CO2_RANGE[0], co2Range[1]]);
          break;
        }
        case 'co2Max': {
          setCO2Range([co2Range[0], DEFAULT_CO2_RANGE[1]]);
          break;
        }
        case 'co2': {
          setCO2Range(DEFAULT_CO2_RANGE);
          break;
        }

        case 'powerMin': {
          setPowerRange([DEFAULT_POWER_RANGE[0], powerRange[1]]);
          break;
        }
        case 'powerMax': {
          setPowerRange([powerRange[0], DEFAULT_POWER_RANGE[1]]);
          break;
        }
        case 'power': {
          setPowerRange(DEFAULT_POWER_RANGE);
          break;
        }

        default:
          console.warn(`item not recognized`, removedItem);
      }
    }

    await addSearchOption(newValues, { onlyCount: false });
  };

  useEffect(() => {
    if (delayedSearchOptionHitCount > 0) {
      setLoading(true)
    }
    const interval = setInterval(() => updateLatestDelayedSearchOption(), SEARCH_OPTIONS_DELAY);
    intervalRef.current = interval;
    return () => clearInterval(interval);
  }, [delayedSearchOptionHitCount]);

  const updateLatestDelayedSearchOption = async () => {
    if (delayedSearchOptionHitCount > 0) {
      setLoading(true)
      await addSearchOption(searchOptions, { onlyCount: true });
      setLoading(false)
    }
  };

  // range filters
  const updateRangeAndFilter = (range, defaultInterval, field, scale = (x) => x) => {
    /**
     *
     * @param {*} prefix : ie: 'power
     * @returns remove power, powerMax, powerMin from searchOptions
     */
    const deleteSearchOptionsStartsWith = (prefix) => {
      // TODO effective prefix for power is a special case: powerHP need to delete also powerKW
      const effectivePrefix = prefix.startsWith('power') ? 'power' : prefix;
      const reducedOption = searchOptions.filter(({ field }) => !field.startsWith(effectivePrefix));
      setSearchOptions(reducedOption);
      return reducedOption;
    };

    /**
     * TODO implement unit test
     *
     * defaultInterval: [1999, 2024]
     * field: 'firstRegistrationYear'
     * scale: x => x
     *
     * range: [2022, 2023]
     * output:
     * [{field: 'firstRegistrationYearMin', value: 2022},
     *  {field: 'firstRegistrationYearMax', value: 2023}]
     *
     * range: [2022, 2022]
     * [{field: 'firstRegistrationYear', value: 2022}]
     *
     * range: [1999, 2023]
     * [{field: 'firstRegistrationYearMax', value: 2023}]
     *
     * range: [1999, 2024]
     * []
     *
     * */
    const toSearchOptions = (range, defaultInterval, field, scale) => {
      const values = [];
      const isSameRange = range[0] === range[1];
      const isSameOfDefault = (index) => range[index] === defaultInterval[index];
      const isDefaultMin = isSameOfDefault(0);
      const isDefaultMax = isSameOfDefault(1);
      if (isSameRange) {
        values.push(new SearchOption({ field: `${field}`, value: scale(range[0]) }));
      } else {
        if (!isDefaultMin) {
          values.push(new SearchOption({ field: `${field}Min`, value: scale(range[0]) }));
        }
        if (!isDefaultMax) {
          values.push(new SearchOption({ field: `${field}Max`, value: scale(range[1]) }));
        }
      }
      return values;
    };

    const newSearchOptions = [
      ...deleteSearchOptionsStartsWith(field),
      ...toSearchOptions(range, defaultInterval, field, scale),
    ];

    setSearchOptions(newSearchOptions);
    setDelayedSearchOptionHitCount(prevState => prevState + 1)
  }

  // TODO needs to work like addSearchOption
  const updateCheckboxAndFilter = (field, value) => {
    const getNewState = (previousState, selectedSearchOption) => {
      let returnState;
      if (!previousState.map((x) => x.getKey()).includes(selectedSearchOption.getKey())) {
        returnState = [...previousState, selectedSearchOption];
      } else {
        returnState = previousState.filter((x) => x.getKey() !== selectedSearchOption.getKey());
      }
      return returnState;
    };

    const selectedSearchOption = new SearchOption({ field, value });
    const newSearchOptions = getNewState(searchOptions, selectedSearchOption);

    setSearchOptions(newSearchOptions);
    setDelayedSearchOptionHitCount(prevState => prevState + 1)
  };

  // navigations
  const navigateToSearch = async (searchOptionsOverride) => {
    // if (searchOptions) {
    //     const mappedValues = searchOptions && searchOptions.flatMap(({ field, value }) => [[field, value]])
    //     console.log('search handler.mappedValues', mappedValues)
    //     setMappedValues(mappedValues)
    //     await doSearch(mappedValues)
    // } else {
    await doSearch({ searchOptionsOverride });
    // }
    navigate(`/`);
  };
  const navigateToSimplifiedSearch = async (value) => {
    await simplifiedSearch(value);
    navigate(`/`);
  };
  const doNavigate = (newIndex) => {
    const { vin } = results[newIndex];
    navigate(`/car/${vin}`);
  };
  const handlePrevious = (doNavigate) => {
    if (results && results.length > 0) {
      if (currentIndex > 0 && currentIndex <= results.length - 1) {
        const newIndex = currentIndex - 1;
        setCurrentIndex(newIndex);
        if (doNavigate) {
          doNavigate(newIndex);
        }
      }
    }
  };
  const navigateToPrevious = () => {
    handlePrevious(doNavigate);
  };

  const handleNext = (doNavigate) => {
    if (results && results.length > 0) {
      if (currentIndex >= 0 && currentIndex < results.length - 1) {
        const newIndex = currentIndex + 1;
        setCurrentIndex(newIndex);
        if (doNavigate) {
          doNavigate(newIndex);
        }
      }
    }
  };
  const navigateToNext = () => {
    handleNext(doNavigate);
  };

  useEffect(() => {
    let cancelled = false;
    const loadFields = async () => {
      const loadSerchOptionByField = async (fieldName) => {
        const result = await fieldsService.getFieldByKey(fieldName).catch((_) => ({ values: [] }));
        return result.values // .map(({ value }) => value);
      };

      // const [
      //   searchOptionsFuel,
      //   searchOptionsColor,
      //   searchOptionsBodyType,
      //   searchOptionsGearboxType,
      //   searchOptionsEmissionClass,
      // ] = await Promise.all([
      //   loadSerchOptionByField('fuel'),
      //   loadSerchOptionByField('color'),
      //   loadSerchOptionByField('bodyType'),
      //   loadSerchOptionByField('gearboxType'),
      //   loadSerchOptionByField('emissionClass'),
      // ]);

      // if (!cancelled) {
      //   setFuelOptions(searchOptionsFuel);
      //   setColorOptions(searchOptionsColor);
      //   setBodyTypeOptions(searchOptionsBodyType);
      //   setGearboxTypeOptions(searchOptionsGearboxType);
      //   setEmissionClassOptions(searchOptionsEmissionClass);
      // }

      await loadSerchOptionByField('fuel')
        .then(searchOptionsFuel => {
          if (!cancelled) {
            setFuelOptions(searchOptionsFuel)
          }
        });
      await loadSerchOptionByField('color')
        .then(searchOptionsColor => {
          if (!cancelled) {
            setColorOptions(searchOptionsColor)
          }
        });
      await loadSerchOptionByField('bodyType')
        .then(searchOptionsBodyType => {
          if (!cancelled) {
            setBodyTypeOptions(searchOptionsBodyType)
          }
        });
      await loadSerchOptionByField('gearboxType')
        .then(searchOptionsGearboxType => {
          if (!cancelled) {
            setGearboxTypeOptions(searchOptionsGearboxType)
          }
        });
      await loadSerchOptionByField('emissionClass')
        .then(searchOptionsEmissionClass => {
          if (!cancelled) {
            setEmissionClassOptions(searchOptionsEmissionClass)
          }
        });

    };
    loadFields().then(() => { });
    return () => {
      cancelled = true;
    };
  }, [pageContext.user]);

  const contextValue = {
    search,
    simplifiedSearch,
    searchWithSortOrder,
    addSearchOption,
    removeSearchOption,
    updateCheckboxAndFilter,
    updateRangeAndFilter,
    reset,

    isLoading,
    isSearchExecuted,
    isFirstSearchExecuted,
    getOptionsByField,

    searchOptions,
    setSearchOptions,
    searchOptionsFilterBased,
    searchOptionsAutocompleteBased,

    results,
    totalResults,
    displayTotalResults,
    currentIndex,
    setCurrentIndex,

    navigateToSearch,
    navigateToSimplifiedSearch,
    navigateToPrevious,
    navigateToNext,
    handlePrevious,
    handleNext,

    sortOrder,
    setSortOrder,

    // checkbox
    fuelOptions,
    colorOptions,
    bodyTypeOptions,
    gearboxTypeOptions,
    emissionClassOptions,

    // ranges
    priceRange,
    setPriceRange,
    clearPriceFilter,

    firstRegistrationYearRange,
    setFirstRegistrationYearRange,
    clearFirstRegistrationYearFilter,

    ccRange,
    setCCRange,
    clearCCRangeFilter,

    mileageRange,
    setMileageRange,
    clearMileageFilter,

    co2Range,
    setCO2Range,
    clearCO2Filter,

    powerRange,
    setPowerRange,
    clearPowerFilter,
  };

  return <SearchContext.Provider value={contextValue}>{props.children}</SearchContext.Provider>;
};

export default SearchContext;
