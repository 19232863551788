import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';

// @mui
import {
  Box,
  Card,
  Typography,
  Skeleton,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import SearchContext from '../../contexts/SearchContext';

// ----------------------------------------------------------------------

const StyledCarImg = styled('img')({
  objectFit: 'cover',
});

// ----------------------------------------------------------------------

CarsListItem.propTypes = {
  car: PropTypes.object,
};

export default function CarsListItem({ car, index, onEnterPress, onFocus, onClick, selectedAsset }) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const searchContext = useContext(SearchContext);
  const [expanded, setExpanded] = useState(false);
  const { ref, focused, focusSelf } = useFocusable({
    onEnterPress,
    onFocus
  });

  useEffect(() => {
    if (selectedAsset === index) {
      focusSelf();
    }
  }, [focusSelf, selectedAsset]);

  // const handleClick = () => {
  //   searchContext.setCurrentIndex(index);
  // };

  if (!car) {
    return <Skeleton variant="rectangular" width={'100%'} />;
  }

  const isSelected = searchContext.currentIndex === index;
  const isPreloadedRight = index - searchContext.currentIndex < 5;
  const isPreloadedLeft = (index - searchContext.currentIndex) % searchContext.results.length > -1;
  const isPreloaded = isPreloadedRight && isPreloadedLeft;

  const { make, model, shortDescription, price, images } = car;

  return (
    <Box
      sx={{
        flexShrink: 0,
        display: 'flex',
        alignItems: 'stretch'
      }}
      onClick={e => onClick()}
    // onClick={handleClick}
    >
      <Card
        elevation={3}
        variant="elevation"
        ref={ref}
        sx={{
          width: '96%',
          aspectRatio: 16 / 9,
          border: isSelected ? '4px solid gold' : focused ? '4px solid #76B0F1' : '4px solid transparent',
          mb: 0, pb: 0
        }}
      >
        <CardContent
          sx={{
            // height: 'fit-content',
            height: '100%',
            // minHeight: '100%',
            // maxHeight: '100%',
            // backgroundImage: `url(${BE_URL}/images/${images[0]})`,
            backgroundImage: `url(${images[0]})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              backgroundColor: 'white',
              opacity: 0.7,
              p: 1,
            }}
            component={'span'}
          >
            {shortDescription}
          </Typography>
          {/* <CarsListItemPrice price={price} /> */}
          <br />
          {/* {price && <Typography variant="h5" sx={{
            backgroundColor: 'white',
            opacity: .7,
            p: 1,
            mt: 1
          }} component={'span'}>
            {price.selling}
          </Typography>
          } */}
        </CardContent>
      </Card>
    </Box>
  );
}
