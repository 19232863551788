import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';

// @mui
import { Typography, CardHeader, IconButton, Box } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import { Masonry } from '@mui/lab';

// ----------------------------------------------------------------------

EquipmentSection.propTypes = {
  optionals: PropTypes.array,
  showHeader: PropTypes.bool,
  useCardHeader: PropTypes.bool,
};

const useStyles = styled((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column wrap',
    gap: '0 30px',
    // backgroundColor: "pink",
    // height: 100, // set the height limit to your liking
    overflow: 'auto',
  },
  item: {
    width: 'auto',
    padding: 0,
    // width: '100px',
    // maxWidth: '100px'
  },
  icon: {
    minWidth: '30px',
  },
}));

export default function EquipmentSection({ optionals, useCardHeader = false, showHeader = false }) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const getOptionsBox = (optionals) => (
    <>
      {optionals.map((option, index) => (
        <Box component="span" key={index} sx={{
          display: 'flex', alignContent: 'end',
          px: 1.75,
          py: .5
        }}>
          <Typography variant="body2" color={option.starred ? 'primary' : undefined}>
            {option.name}
          </Typography>
          {option.starred ? (
            <StarIcon color="primary" sx={{ pl: .5 }} />
          ) : null}
        </Box>
        // <ListItem key={index}>
        //   {option.starred ? (
        //     // <ListItemIcon className={classes.icon}>
        //     //     {/* <ListItemAvatar>
        //     //                         <Avatar sx={{ width: 24, height: 24 }} color="primary"> */}
        //     //     {/* <StarBorderIcon /> */}
        //     //     <StarIcon color="primary" />
        //     //     {/* </Avatar>
        //     //             </ListItemAvatar> */}
        //     // </ListItemIcon>
        //     <IconButton edge="start">
        //       <StarIcon color="primary" />
        //     </IconButton>
        //   ) : null}
        //   <ListItemText secondary={<Typography variant="body2">{option.name}</Typography>} />
        // </ListItem>
      ))}
    </>
  );

  return (
    <>
      {' '}
      {optionals && optionals.length > 0 ? (
        <>
          {showHeader ? (
            <>
              {useCardHeader ? (
                <CardHeader title={intl.formatMessage({ id: 'car.section.title.equipment' })} />
              ) : (
                <Typography variant="h5">
                  <FormattedMessage id="car.section.title.equipment" />
                </Typography>
              )}
            </>
          ) : null}

          <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={0}>
            {getOptionsBox(optionals)}
          </Masonry>
          {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          </Box> */}

        </>
      ) : null}
    </>
  );
}
