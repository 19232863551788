import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Tooltip } from '@mui/material';

import { SignInSection, SignUpSection } from '../sections/auth/index'

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));



// ----------------------------------------------------------------------

export default function SignUpPage() {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        {intl.formatMessage({ id: 'application.name' })}
        {' | '}
        {intl.formatMessage({ id: 'page.title.signup' })}
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <SignUpSection />
        </Container>
      </StyledRoot>
    </>
  );
}
