import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { QrCode2 } from '@mui/icons-material';

// @mui
import { Box, Paper } from '@mui/material';
import KeySpecComponentTv from './KeySpecComponentTv';

// ----------------------------------------------------------------------

CarCardContactInfoTv.propTypes = {
  step: PropTypes.array.isRequired,
};

export default function CarCardContactInfoTv({ intl, step }) {
  const [price, carLink, logo, contactName, contactPhone] = step;

  function LogoContact({ image }) {
    return <img src={image} alt="contact-logo" width={'44%'} height={'44%'} />;
  }

  return step && step.length > 0 ? (
    <>
      <Paper
        elevation={4}
        sx={{
          // p: 3,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          mt: 1,
        }}
      >
        <Box
          sx={{
            width: '20%', // for real QR code
            // width: '10%', // for real QR code
            p: 3,
          }}
        >
          {carLink && carLink.value ? (
            <QRCode
              // size={256}
              style={{
                height: '100%',
                maxWidth: '100%',
                width: '100%',
              }}
              value={carLink.value}
            // viewBox={`0 0 256 256`}
            />
          ) : (
            <QrCode2 />
          )}
          {/* // <Skeleton height={80} width={'100%'} animation={false} />} */}
        </Box>
        <Box
          // width={'47%'}
          width={'50%'}
          sx={{ textAlign: 'center' }}
        >
          <KeySpecComponentTv big>{price.value}</KeySpecComponentTv>
        </Box>
        <Box width={'30%'}>
          <KeySpecComponentTv small>
            {logo ? <LogoContact image={logo.value} /> : null}
            {contactName.value}
            <br />
            {contactPhone.value}
          </KeySpecComponentTv>
          {/* <KeySpecComponentTv small
                    >
                    </KeySpecComponentTv > */}
        </Box>
      </Paper>
    </>
  ) : null;
}
