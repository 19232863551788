import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';

import KeySpecComponentTv from './KeySpecComponentTv';
// ----------------------------------------------------------------------

CarStep1Tv.propTypes = {
  step: PropTypes.array.isRequired,
};

export default function CarStep1Tv({ intl, step, boxWidth = '33%' }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          alignItems: 'end',
          px: 3,
        }}
      >
        {step
          .filter(({ value }) => value)
          .map(({ label, value }) => (
            <Box width={boxWidth} key={label}>
              <KeySpecComponentTv small={['vin'].includes(label)} label={`car.${label}.label`}>{value}</KeySpecComponentTv>
            </Box>
          ))}
      </Box>
    </>
  );
}
