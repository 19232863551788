import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Tooltip } from '@mui/material';

import { SignInSection } from '../sections/auth/index'

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));



// ----------------------------------------------------------------------

export default function LoginPage() {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState();

  useEffect(() => {
    if (searchParams && searchParams.has('err')) {
      setError(searchParams.get('err'))
    }
  }, [searchParams])

  return (
    <>
      <Helmet>
        {intl.formatMessage({ id: 'application.name' })}
        {' | '}
        {intl.formatMessage({ id: 'page.title.login' })}
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <SignInSection error={error}/>
        </Container>
      </StyledRoot>
    </>
  );
}
