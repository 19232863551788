const scaleByMarks = (marks) => (value) => {
  const previousMarkIndex = Math.floor(value / 25);
  const previousMark = marks[previousMarkIndex];
  const remainder = value % 25;
  if (remainder === 0) {
    return previousMark.scaledValue;
  }
  const nextMark = marks[previousMarkIndex + 1];
  const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
  return remainder * increment + previousMark.scaledValue;
};

module.exports = {
  scaleByMarks,
};
