import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

EnginePower.propTypes = {
  powerValue: PropTypes.number.isRequired,
  symbol: PropTypes.number.isRequired,
};

export default function EnginePower({ intl, powerValue, symbol, powerValue2, symbol2 }) {
  return (
    <>
      <Box component="span">
        {powerValue ? <>{`${powerValue} `}<FormattedMessage id={`commons.${symbol}`} /></> : null}
        {powerValue2 ? <>{` (${powerValue2} `}<FormattedMessage id={`commons.${symbol2}`} />{`)`}</> : null}
      </Box>
    </>
  );
}
