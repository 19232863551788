import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// @mui

// ----------------------------------------------------------------------

CountryOrigin.propTypes = {
  countryOrigin: PropTypes.string.isRequired,
};

export default function CountryOrigin({ intl, countryOrigin }) {
  return <>{countryOrigin ? <FormattedMessage id={`car.countryOrigin.${countryOrigin}.label`} /> : null}</>;
}
