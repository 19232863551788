import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTheme } from '@emotion/react';
// @mui
import {
  Stack,
  useMediaQuery,
} from '@mui/material';

// components
import Scrollbar from '../../components/scrollbar';
import SearchContext from '../../contexts/SearchContext';
import {
  FirstRegistrationYearFilter,
  PriceFilter,
  PowerFilter,
  CCRangeFilter,
  CO2Filter,
  MileageFilter,
  CheckboxFilter,
  CheckboxColorFilter,
} from '../filter';

// ----------------------------------------------------------------------

CarsFilterContent.propTypes = {
  openFilter: PropTypes.bool,
  handleOpenFilter: PropTypes.func,
  handleCloseFilter: PropTypes.func,
  hideIcon: PropTypes.bool,
  hideButton: PropTypes.bool,
};

export default function CarsFilterContent({
  isMobile,
  hideButton,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  hideIcon,
}) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const searchContext = useContext(SearchContext);

  return (
    <>
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3 }}>
          {/* // PRICES */}
          <PriceFilter />

          {/* // FIRST REGISTRATION YEAR */}
          <FirstRegistrationYearFilter />

          {/* // MILEAGE */}
          <MileageFilter />

          {/* // POWER */}
          <PowerFilter />

          {/* // CC */}
          <CCRangeFilter />

          {/* // BODY TYPE */}
          <CheckboxFilter fieldName="bodyType" />

          {/* // FUEL */}
          <CheckboxFilter fieldName="fuel" />

          {/* // COLOR */}
          <CheckboxColorFilter fieldName="color" />

          {/* // GEARBOX */}
          <CheckboxFilter fieldName="gearboxType" />

          {/* // EMISSION CLASS */}
          <CheckboxFilter fieldName="emissionClass" />

          {/* // CO2 */}
          <CO2Filter />
        </Stack>
      </Scrollbar>
    </>
  );
}
