import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// @mui
import { Box, Card, Link, Typography, Stack, Skeleton, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrencyEuro } from '../../utils/formatNumber';
// components
import Label from '../../components/label';
import { ColorPreview } from '../../components/color-utils';
import SearchContext from '../../contexts/SearchContext';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

CarsMobile.propTypes = {
  product: PropTypes.object,
};

/**
 * @deprecated
 */ 
export default function CarsMobile({ car, searchIndex }) {
  const searchContext = useContext(SearchContext);
  const navigate = useNavigate();

  const navigateToPage = () => {
    // console.log('navigateToPage')
    searchContext.setCurrentIndex(searchIndex);
    navigate(`/car/${car.vin}`);
  };

  if (!car) {
    return <Skeleton variant="rectangular" width={210} />;
  }

  const {
    make,
    model,
    shortDescription,
    description,
    // chassis,
    gearbox,
    firstRegistrationDate,
    firstRegistrationAsDate,
    firstRegistrationAsYear,
    mileage,
    bodyType,
    fuel,
    engineSize,
    co2,
    enginePowerHP,
    enginePowerKW,
    gearboxType,
    emissionClass,
    power,
    doors,
    numberOfPlaces,
    countryOrigin,
    linkToOriginalSite,
    auction,
    color,
    price,
    images,
  } = car;

  return (
    <Card>
      <Box sx={{ width: '100vw' }}>
        <Box
          spacing={2}
          sx={{
            zIndex: 9,
            top: 2,
            left: 4,
            position: 'absolute',
            // display: 'flex',
            // flexDirection: 'column',
            // justifyItems: 'flex-start',
          }}
        >
          {mileage && (
            <Label
              variant="filled"
              color={'primary'}
              sx={{
                textTransform: 'uppercase',
              }}
            >
              {`${mileage}`} <FormattedMessage id="commons.km" />
            </Label>
          )}
          <Box />
          {firstRegistrationAsYear && (
            <Label
              variant="filled"
              color={'secondary'}
              sx={{
                mt: 1,
                textTransform: 'uppercase',
              }}
            >
              {`${firstRegistrationAsYear}`}
            </Label>
          )}
        </Box>
        <CardMedia
          component="img"
          image={`${images[0]}`}
          // image={`${BE_URL}/images/${images[0]}`}
          alt={description}
        />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover" onClick={(e) => navigateToPage()}>
          <Typography variant="subtitle2" noWrap>
            {shortDescription}
          </Typography>
          {/* <Typography variant="subtitle2" noWrap>
            Year {firstRegistrationAsYear} - {`${mileage} km`}
          </Typography> */}
          <Typography variant="subtitle2" noWrap>
            {engineSize} CC - {enginePowerHP} Hp ({enginePowerKW} kW) - {gearboxType}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {emissionClass} - {co2} g/km CO2
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {color ? <ColorPreview colors={[color]} /> : <span>{' '}</span>}
          <Typography variant="subtitle1">
            {price && price.discounted ? (
              <>
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    color: 'text.disabled',
                    textDecoration: 'line-through',
                  }}
                >
                  {fCurrencyEuro(price.final)} <FormattedMessage id="commons.eur" />
                </Typography>
                {' '}
                {fCurrencyEuro(price.discounted)} <FormattedMessage id="commons.eur" />
              </>
            ) : (
              fCurrencyEuro(price.final)
            )}{' '}
            <FormattedMessage id="commons.eur" />
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
