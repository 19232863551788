import React, { useEffect, useRef, useState } from 'react';
import { authService } from '../services';
import { REFRESH_TOKEN_INTERVAL, DEFAULT_UI_TARGET } from '../config/config';
import {
  getRefreshToken,
  storeAccessToken,
  storeRefreshToken,
  removeTokens,
  storeUITarget,
  getUITarget,
  storeReturnTo,
  removeReturnTo,
  getReturnToFromStorage,
} from '../services/asyncStorage.service';

const PageContext = React.createContext({
  user: {},
  isLoggedIn: false,
  isPageContextLoaded: false,
  getReturnTo: () => { },
  setReturnTo: () => { },
  resetReturnTo: () => { },
  // accessToken: "",
  login: () => { },
  logout: () => { },
  isWeb: false,
  isMobile: false,
  isTv: false,
  changeUITarget: () => { },
});

export const PageContextProvider = (props) => {
  // AUTH
  const [accessToken, setAccessToken] = useState();
  const [user, setUser] = useState();
  const [isPageContextLoaded, setPageContextLoaded] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const intervalRef = useRef();

  const getReturnTo = () => {
    return getReturnToFromStorage() || '/'
  }
  const setReturnTo = (returnTo) => {
    storeReturnTo(returnTo)
  }
  const resetReturnTo = () => {
    removeReturnTo()
  }

  const logoutHandler = () => {
    // console.log('logout handler')

    setPageContextLoaded(false)
    setUser(null);
    removeTokens();
    setAccessToken(null);
    setTimeout(() => {
      setPageContextLoaded(true)
    })
  };

  const loginHandler = (loggedInUser, tokens) => {
    // console.log('login handler', loggedInUser)
    if (!user) {
      setUser(loggedInUser);
    }
    // setRefreshToken(tokens.refresh.token);
    setAccessToken(tokens.access.token);
    storeRefreshToken(tokens.refresh.token);
    storeAccessToken(tokens.access.token);
  };

  const doRefreshTheTokens = async () => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      const tokens = await authService.postRefreshTokens(refreshToken);
      // setRefreshToken(tokens.refresh.token);
      setAccessToken(tokens.access.token);
      storeRefreshToken(tokens.refresh.token);
      storeAccessToken(tokens.access.token);
    }
  };

  const refreshTheUser = async () => {
    try {
      if (getRefreshToken()) {
        const loggedInUser = await authService.getLoggedUser();
        setUser(loggedInUser);
      } else {
        setUser(null)
      }
    } catch (err) {
      // do nothing
    }
  };

  const getNewToken = async () => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      // console.log('new token callback')
      try {
        await doRefreshTheTokens();
      } catch (err) {
        // console.log('token not refreshed')
        logoutHandler();
      }
    } else {
      // console.log('session unauthenticated')
    }
  };

  useEffect(() => {
    const interval = setInterval(() => getNewToken(), REFRESH_TOKEN_INTERVAL);
    intervalRef.current = interval;
    return () => clearInterval(interval);
  }, [accessToken]);

  useEffect(() => {
    setIsUserLoggedIn(!!user)
  }, [user]);

  // UI CONTEXT
  const [isWeb, setIsWeb] = useState();
  const [isTv, setIsTv] = useState();
  const [isMobile, setIsMobile] = useState();

  const resetFlags = () => {
    setIsWeb(false);
    setIsTv(false);
    setIsMobile(false);
  };
  const changeUITarget = async (uiTarget) => {
    switch (uiTarget) {
      case 'mobile': {
        resetFlags(false);
        setIsMobile(true);
        break;
      }
      case 'web': {
        resetFlags(false);
        setIsWeb(true);
        break;
      }
      case 'tv':
        resetFlags(false);
        setIsTv(true);
        break;
      default: {
        // do nothing
      }
    }
    await storeUITarget(uiTarget);
    // console.log('uiTarget', uiTarget)
    // console.log('setIsWeb', isWeb)
    // console.log('setIsTv', isTv)
    // console.log('setIsMobile', isMobile)
  };

  useEffect(() => {
    // AUTH CONTEXT
    setPageContextLoaded(false)
    const initContext = async () => {
      await getNewToken();
      await refreshTheUser();
      setPageContextLoaded(true)
    };
    initContext();

    // UI CONTEXT
    const initializeUiTarget = async () => {
      const uiTarget = (await getUITarget()) || DEFAULT_UI_TARGET;
      changeUITarget(uiTarget);
    };
    initializeUiTarget();
  }, []);

  const contextValue = {
    user,
    isLoggedIn: isUserLoggedIn,
    isPageContextLoaded,
    getReturnTo,
    setReturnTo,
    resetReturnTo,
    login: loginHandler,
    logout: logoutHandler,

    isWeb,
    isMobile,
    isTv,
    changeUITarget,
  };

  return <PageContext.Provider value={contextValue}>{props.children}</PageContext.Provider>;
};

export default PageContext;
