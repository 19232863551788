import { FormattedMessage } from 'react-intl';
import Marquee from 'react-fast-marquee';
// @mui
import { styled } from '@mui/material/styles';
import { Divider, Stack, Typography, Link, Box } from '@mui/material';
// components
import { Logo } from '../../../components/logo';

// ----------------------------------------------------------------------

const StyledFooter = styled('footer')(({ theme }) => ({
  bottom: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  minHeight: '30%',
  position: 'relative',
  backgroundColor: theme.palette.grey[900],
  padding: theme.spacing(3, 3, 0),
}));

// ----------------------------------------------------------------------

export default function Footer() {
  return (
    <>
      <StyledFooter>
        {/* <Container maxWidth="xl" sx={{
          px: 4,
        }} > */}
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          sx={{
            // height: '20vh',
            // backgroundColor: 'red'
            mb: 6,
          }}
        >
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '50%',
              },
            }}
          >
            <Logo hideLinks/>
          </Box>
        </Box>

        <Marquee>
          <Typography variant="h4" color="white">
            <FormattedMessage id="footer.advertising.contactText" />{' '}
            <Box color="primary.main" display="inline">
              <FormattedMessage id="footer.advertising.phoneNumber" />
            </Box>
            . <FormattedMessage id="footer.advertising.assistanceText" />
          </Typography>
        </Marquee>

        <Divider
          sx={{
            mt: 3,
            mb: 3,
          }}
        />

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            flexGrow: 1,
            flexWrap: 'wrap',
          }}
          spacing={4}
        >
          <Stack spacing={3} color={'white'}>
            <Typography variant="body2" noWrap>
              &copy; CarBid.tv 2024 | All right reserved.
            </Typography>
          </Stack>
          <Stack spacing={3} color={'white'}>
            <Typography variant="body2" noWrap>
              Powered by{' '}
              <Link href="https://www.sabiasrl.com" target="_blank">
                Sabia Srl
              </Link>
            </Typography>
          </Stack>
        </Box>
        {/* </Container> */}
      </StyledFooter>
    </>
  );
}
