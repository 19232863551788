import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import typographyTv from './typographyTv';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import { REACT_APP_TARGET_PLATFORM } from '../config/config';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children, isWeb, isMobile }) {
  const isTv = REACT_APP_TARGET_PLATFORM === 'tv';

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography: isTv ? typographyTv : typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    []
  );

  const themeLight = createTheme(themeOptions);
  const themeDark = createTheme(themeOptions);
  let theme = themeLight;
  theme.components = componentsOverride(theme);

  const setLightTheme = () => {
    theme = themeLight;
  };
  const setDarkTheme = () => {
    theme = themeDark;
  };

  const propagatedChldren = { ...children, setLightTheme, setDarkTheme };
  return (
    <StyledEngineProvider injectFirst>
      {theme ? (
        <MUIThemeProvider theme={theme === 'light' ? themeLight : themeDark}>
          <CssBaseline />
          <GlobalStyles />
          {propagatedChldren}
        </MUIThemeProvider>
      ) : null}
    </StyledEngineProvider>
  );
}
