import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

// @mui
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
// components
import SearchContext from '../../contexts/SearchContext';

CheckboxFilter.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default function CheckboxFilter({ fieldName }) {
  const intl = useIntl();
  const searchContext = useContext(SearchContext);
  const [options, setOptions] = useState([]);

  const handleChange = (event) => {
    const fuelName = event.target.name;
    searchContext.updateCheckboxAndFilter(fieldName, fuelName);
  };

  const checkedOptions = searchContext.searchOptions.filter(({ field }) => field === fieldName).map(({ value }) => value) || [];

  useEffect(() => {
    // const options = searchContext.getOptionsByField(fieldName);
    switch (fieldName) {
      case 'fuel':
        setOptions(searchContext.fuelOptions);
        break;
      case 'bodyType':
        setOptions(searchContext.bodyTypeOptions);
        break;
      case 'gearboxType':
        setOptions(searchContext.gearboxTypeOptions);
        break;
      case 'emissionClass':
        setOptions(searchContext.emissionClassOptions);
        break;
      default:
    }
  }, [
    searchContext.fuelOptions,
    searchContext.bodyTypeOptions,
    searchContext.gearboxTypeOptions,
    searchContext.emissionClassOptions
  ])

  return options && options.length > 0 ? (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage id={`filter.${fieldName}.title`} />
      </Typography>
      <FormGroup>
        {options.map((item) => (
          <FormControlLabel
            key={item}
            control={<Checkbox />}
            onChange={handleChange}
            label={item}
            name={item}
            checked={checkedOptions.includes(item)}
          />
        ))}
      </FormGroup>
    </div>
  ) : (
    false
  );
}
