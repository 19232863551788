const removableFieldNameOptions = ['make', 'bodyType', 'fuel', 'gearboxType', 'color'];
const translatableFieldNameOption = [
  'price',
  'priceMin',
  'priceMax',
  'power',
  'powerMin',
  'powerMax',
  'cc',
  'ccMin',
  'ccMax',
  'mileage',
  'mileageMax',
  'mileageMin',
  'firstRegistrationYear',
  'firstRegistrationYearMin',
  'firstRegistrationYearMax',
  'co2',
  'co2Min',
  'co2Max',
];

export const SearchOption = class {
  constructor({ field, value, isMakeModel }) {
    this.field = field;
    this.value = value;
    this.isMakeModel = isMakeModel;
  }

  getKey() {
    return `${this.field}: ${this.value}`;
  }

  getLabel(intl) {
    if (removableFieldNameOptions.includes(this.field)) {
      return `${this.value}`;
    }
    if (translatableFieldNameOption.includes(this.field)) {
      let symbol;
      if (this.field.startsWith('price')) {
        symbol = intl.formatMessage({ id: 'commons.eur' });
      } else if (this.field.startsWith('power')) {
        symbol = intl.formatMessage({ id: 'commons.hp' });
      } else if (this.field.startsWith('cc')) {
        symbol = intl.formatMessage({ id: 'commons.cc' });
      } else if (this.field.startsWith('co2')) {
        symbol = intl.formatMessage({ id: 'commons.gkm' });
      }

      const translatedValue = intl.formatMessage(
        {
          id: `searchBar.autocomplete.chips.${this.field}`,
        },
        { value: `${this.value}`, symbol }
      );
      return translatedValue;
    }
    return `${this.field}: ${this.value}`;
  }
};
