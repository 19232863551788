import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';

// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Tooltip } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Iconify from '../../components/iconify';
// sections
import { LoginFederationLinks, LoginForm } from '.';
import { BE_URL } from '../../config/config';


const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    // minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
    // padding: theme.spacing(2, 0, 3, 0),
}));

export default function SignInSection({ error }) {
    
    return (
        <StyledContent>
            <Typography variant="h4" gutterBottom>
                Sign in
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
                Don’t have an account? {''}
                <span>
                    <Link variant="subtitle2" href={'/signup'}>Sign up</Link>
                </span>
            </Typography>

            <LoginFederationLinks />

            <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    OR
                </Typography>
            </Divider>

            <LoginForm />

            {error ? <Typography variant="body2" sx={{ mt: 1 }} color={'error'}>
                {error}
            </Typography> : null}
        </StyledContent>
    )
}