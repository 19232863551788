import { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

// @mui
import { Typography, Slider } from '@mui/material';
// components
import SearchContext from '../../contexts/SearchContext';
import { filterUnique } from '../../utils/filterUnique';
import { DEFAULT_CC_RANGE } from '../../config/config';
// ----------------------------------------------------------------------

CCRangeFilter.propTypes = {};

export default function CCRangeFilter() {
  const intl = useIntl();

  const searchContext = useContext(SearchContext);
  const { ccRange, setCCRange } = searchContext;
  const [ccMin, ccMax] = DEFAULT_CC_RANGE;

  const marks = [
    {
      value: ccMin,
      label: ccMin,
    },
    {
      value: ccMin + ((ccMax - ccMin) * 1) / 3,
      label: ccMin + ((ccMax - ccMin) * 1) / 3,
    },
    {
      value: ccMin + Math.round(((ccMax - ccMin) * 2) / 3),
      label: ccMin + Math.round(((ccMax - ccMin) * 2) / 3),
    },
    {
      value: ccMax,
      label: ccMax,
    },
  ];

  const ccValuetext = (value) => `${value}`;
  const ccLabel = (ccRange) => {
    const ccs = ccRange.filter(filterUnique);
    return `${ccs.join('->')} ${intl.formatMessage({ id: 'commons.cc' })}`;
  };

  const handleCCRangeChange = (event, newRange) => {
    setCCRange(newRange);
    searchContext.updateRangeAndFilter(newRange, DEFAULT_CC_RANGE, 'cc');
  };

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage id="filter.engineSize.title" />
      </Typography>
      {/* <Box>&nbsp;</Box> */}
      <Slider
        valueLabelDisplay="auto"
        min={ccMin}
        max={ccMax}
        step={100}
        getAriaLabel={() => 'Cubic capacity range'}
        value={ccRange}
        onChange={handleCCRangeChange}
        marks={marks}
        getAriaValueText={ccValuetext}
      />
      {/* <Typography variant="subtitle2" gutterBottom>
        {ccLabel(ccRange)}
      </Typography> */}
    </div>
  );
}
