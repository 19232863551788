import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@mui/material';
// @mui

// ----------------------------------------------------------------------
Price.propTypes = {
  price: PropTypes.object,
};
export default function Price({ intl, price, enablePriceWithVAT = true }) {
  const [showPriceWithVat, setShowPriceWithVat] = useState(null)

  const togglePrice = (e) => {
    if (enablePriceWithVAT) {
      e.preventDefault();
      e.stopPropagation();
      setShowPriceWithVat(!showPriceWithVat);
    }
  }

  if (!price) {
    return null;
  }

  return (
    <Box component={'span'} onClick={togglePrice} sx={enablePriceWithVAT ? { cursor: 'pointer' } : undefined}>
      {showPriceWithVat && price.vat ? (
        <Box component={'span'}>
          <FormattedMessage id="commons.eur" /> {price.vat.it} <FormattedMessage id="car.price.inclVAT" />
        </Box>
      ) : (
        <Box component={'span'}>
          <FormattedMessage id="commons.eur" /> {price.final} <FormattedMessage id="car.price.withVAT" />
        </Box>
      )}
    </Box>
  );
}
