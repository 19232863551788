import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  TextField,
  Typography,
  Box,
  Stack,
  Chip,
  Link,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';

// utils
// component
import SearchContext from '../../contexts/SearchContext';
import PageContext from '../../contexts/PageContext';
import { fieldsService } from '../../services';
import { SearchOption } from '../../types/SearchOption';
// ----------------------------------------------------------------------

const StyledStack = styled('div')(({ theme }) => ({
  // backgroundColor: 'pink',
  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
const StyledSearchbar = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '40vw',
  [theme.breakpoints.up('md')]: {},
}));

// ----------------------------------------------------------------------

export default function SearchbarTvSimplified() {
  const showHeaderItems = false
  const showAutocomplete = true
  
  const intl = useIntl();
  const theme = useTheme();
  const pageContext = useContext(PageContext);
  const { user } = pageContext;
  const location = useLocation();

  const searchContext = useContext(SearchContext);
  const [modal, setModal] = useState([]); // options
  const [searchFields, setSearchFields] = useState([]); // options

  const isSearchExecuted = searchContext.totalResults >= 0;

  useEffect(() => {
    let cancelled = false;
    const loadSearchFields = async () => {
      // ie: [{field:'make', values:['Audi','Volvo']}]
      try {
        const fields = await fieldsService.getFields();
        if (!cancelled) {
          const options = fields.flatMap(({ field, values, isMakeModel }) =>
            values.map((value) => new SearchOption({ field, value, isMakeModel }))
          );
          setSearchFields(options);
        }
      } catch (err) {
        // do nothing
      }
    };
    loadSearchFields();
    return () => {
      cancelled = true;
    };
  }, []);

  const handleSearch = async () => {
    searchContext.search();
  };

  const handleNext = async () => {
    searchContext.handleNext();
  };

  const handleReset = async () => {
    searchContext.reset({});
  };

  const openModal = () => {
    setModal(true);
  };
  const handleClose = () => {
    setModal(false);
  };

  const HeaderItem = ({ label, value }) => (
    <Typography variant="h5" sx={{ color: 'text.primary', px: 3 }} key={value}>
      <Link variant='h5' underline='none' onClick={searchContext.simplifiedSearch({ byKey: value })}>{label}</Link>
    </Typography>
  );

  return (
    <>
      <StyledSearchbar>
        {showHeaderItems ?
          (
            <Stack direction={'row'}>
              {
                [
                  { label: 'Fixed price', value: 'fixedPrice' },
                  { label: 'Auction', value: 'auctions' },
                  { label: 'Ex-leasing', value: 'exLeasing' },
                  { label: 'Used certified', value: 'usedCertified' },
                  // {label: 'Info', value: ''},
                ].map(({ label, value }) => (
                  // <FocusableHeaderItem label={label} />
                  <HeaderItem label={label} value={value} />
                ))
              }
            </Stack>
          ) : null}
        {showAutocomplete ? (
          <Stack direction="row">
            <Box sx={{ flexGrow: 1, pr: 1 }}>
              <Autocomplete
                id="searchAutocomplete"
                size="medium"
                multiple
                autoFocus
                fullWidth
                disableunderline="true"
                placeholder={intl.formatMessage({ id: "cars.searchbar.placeholder.searchYourCar" })}
                defaultValue={[]}
                value={searchContext.searchOptions}
                onChange={(event, newValues, reason) => {
                  switch (reason) {
                    case 'removeOption': {
                      searchContext.removeSearchOption(newValues, { onlyCount: false });
                      break;
                    }
                    case 'clear': {
                      searchContext.reset({ executeSearch: true });
                      break;
                    }
                    case 'selectOption': {
                      searchContext.addSearchOption(newValues, { onlyCount: false });
                      break;
                    }
                    case 'createOption':
                    default:
                  }
                }}
                options={searchFields}
                // freeSolo
                getOptionKey={(option) => option && option.getKey()}
                getOptionLabel={(option) => option.getLabel(intl)}
                isOptionEqualToValue={(option, value) =>
                  value && value instanceof SearchOption && option.getKey() === value.getKey()
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // label="Type multiple values, ie: Volvo XC60 Diesel, Audi A4, Tesla S 2019"
                    label="Search car by brand or model"
                    placeholder={intl.formatMessage({ id: "cars.searchbar.placeholder.searchYourCar" })}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option}
                      variant="outlined"
                      sx={{ height: 'auto', fontSize: 22 }}
                      label={option.getLabel(intl)}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                sx={{
                  fontWeight: 'fontWeightBold',
                  flexShrink: 1,
                  flexGrow: 0,
                  // pr: { xs: 0, md: 1 }
                }}
                ListboxProps={{
                  sx: { fontSize: 22 },
                }}
              />
            </Box>

            <LoadingButton
              sx={{
                // flexGrow: 1,
                flexShrink: 0,
              }}
              variant="contained"
              loading={searchContext.isLoading}
              // onClick={handleSearch}
              onClick={handleNext}
            >
              {searchContext.isSearchExecuted ? (
                <Typography variant="h4">
                  <FormattedMessage
                    id="cars.searchbar.numberOfResults"
                    values={{
                      numberOfResults: searchContext.displayTotalResults,
                    }}
                  />
                </Typography>
              ) : (
                <Typography variant="h4">
                  <SearchIcon />
                </Typography>
              )}
            </LoadingButton>
          </Stack>
        ) : null}
      </StyledSearchbar>
    </>
  );
}
