import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// @mui
import { Typography } from '@mui/material';
// components
import SearchContext from '../../contexts/SearchContext';
import { ColorMultiPicker } from '../../components/color-utils';

CheckboxColorFilter.propTypes = {
  fieldName: PropTypes.string,
};

export default function CheckboxColorFilter({ fieldName }) {
  const searchContext = useContext(SearchContext);
  const [options, setOptions] = useState([]);

  const handleChange = (color) => {
    searchContext.updateCheckboxAndFilter(fieldName, color);
  };

  useEffect(() => {
    const options = searchContext.getOptionsByField(fieldName);
    setOptions(options)
  }, [searchContext.colorOptions])

  const checkedOptions = searchContext.searchOptions.filter(({ field }) => field === fieldName).map(({ value }) => value) || [];

  return options && options.length > 0 ? (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage id={`filter.${fieldName}.title`} />
      </Typography>
      <ColorMultiPicker
        name="colors"
        selected={checkedOptions}
        colors={options}
        onChangeColor={handleChange}
        sx={{ maxWidth: 38 * 4 }}
      />
    </div>
  ) : (
    false
  );
}
