import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// @mui
import { Typography, Stack } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CarsKeySpecsSection from './CarsKeySpecsSection';
import { EnginePower, EngineSize, Gearbox } from '../../components/car-labels';
import CarPriceInfo from './CarPriceInfo';

// ----------------------------------------------------------------------

CarBasicInfoMobile.propTypes = {
  car: PropTypes.object.isRequired,
};

export default function CarBasicInfoMobile({ car }) {
  return (
    <Stack>
      <Typography variant="h6">
        {car.shortDescription}
        {/* {car.description} */}
      </Typography>

      <Typography variant="section2" color="text.secondary">
        <><EngineSize engineSize={car.engineSize} />
          {car.gearboxType ? ' | ' : null}
          <Gearbox gearboxType={car.gearboxType} gearbox={car.gearbox} />
          {car.enginePowerHP || car.enginePowerKW ? ' | ' : null}
          <EnginePower powerValue={car.enginePowerHP} symbol="hp" powerValue2={car.enginePowerKW} symbol2="kw" />
        </>
      </Typography>
    </Stack>
  );
}
