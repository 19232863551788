const numFormatter = (num) => {
  // convert to K for number from > 1000 < 1 million
  if (num > 999 && num < 1000000) {
    return `${(num / 1000).toFixed(0)}K`;
  }
  // convert to M for number from > 1 million
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(0)}M`;
  }
  // if value < 1000, nothing to do
  return num.toFixed(0);
};

module.exports = {
  numFormatter,
};
