const { get, getResponse, getBlob } = require('../utils/fetch');
const config = require('../config/config');

const { BE_URL: beURL } = config;

const getImage = async (imagePath) =>
  // console.log('config', config)
  //  getResponse(`${beURL}/images/${imagePath}`)
  getBlob(`${beURL}/images/${imagePath}`);

module.exports = {
  getImage,
};
