import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
// @mui
import { Box, Card, Typography, Stack, Skeleton, Grid, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
// components
import Label from '../../components/label';
import SearchContext from '../../contexts/SearchContext';
import { CarsKeySpecsSection, CarPriceInfo, CarCarouselImages, CarLocationInfo } from '../car/index';
import { FavouritesIcon, ShareIcon } from '../../components/car-images';
import { Description } from '../../components/car-labels';

// ----------------------------------------------------------------------

const StyledCarImg = styled('img')({
  // top: 0,
  // width: '100%',
  // height: '100%',
  // // width: '400px',
  // // height: '400px',
  // // objectFit: 'cover',
  // objectFit: 'contain',
  // objectPosition: 'center',
  // position: 'absolute',
});

// ----------------------------------------------------------------------

CarsCardWeb.propTypes = {
  product: PropTypes.object,
};

export default function CarsCardWeb({ car, searchIndex }) {
  const searchContext = useContext(SearchContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToPage = () => {
    // console.log('navigateToPage')
    searchContext.setCurrentIndex(searchIndex);
    navigate(`/car/${car.vin}`);
  };

  if (!car) {
    return <Skeleton variant="rectangular" width={210} />;
  }

  const {
    make,
    model,
    description,
    shortDescription,
    // chassis,
    gearbox,
    firstRegistrationDate,
    firstRegistrationAsDate,
    firstRegistrationAsYear,
    mileage,
    bodyType,
    fuel,
    engineSize,
    co2,
    enginePowerHP,
    enginePowerKW,
    gearboxType,
    emissionClass,
    power,
    doors,
    numberOfPlaces,
    countryOrigin,
    linkToOriginalSite,
    auction,
    color,
    price,
    images,
  } = car;

  return (
    <Card
      sx={{
        // backgroundColor: 'red',
        // width: '400px',
        // width: '100%',

        width: {
          xs: '100%',
        },
        // maxWidth: '400px'
        p: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          width: '100%',
          // gap: 1
        }}
      >
        {/* // BOX IMAGE - to be dismissed */}
        {false && <Box
          sx={{
            // pt: '100%',
            flexGrow: 1,
            flexShrink: 1,
            position: 'relative',
            // backgroundColor: 'blue',
            backgroundColor: theme.palette.grey[900],
            // width: {
            //   // xs: '50%',
            //   // lg: '33%',
            //   // xl: '25%',
            // },
            minHeight: '18rem',
            // minWidth: '26rem',
            // maxWidth: '26rem',
            // width: 'auto',
            // width: '100%',
            backgroundImage: `url(${images[0]})`,
            // backgroundSize: 'contain',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {mileage && (
            <Label
              variant="filled"
              color={(mileage === 'sale' && 'error') || 'info'}
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              {`${mileage} km`}
            </Label>
          )}
          {firstRegistrationAsYear && (
            <Label
              variant="filled"
              color={(firstRegistrationAsYear === 'sale' && 'error') || 'primary'}
              sx={{
                zIndex: 9,
                top: 16,
                left: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              {`${firstRegistrationAsYear}`}
            </Label>
          )}
          {/* {false && images[0] ? (
            <StyledCarImg alt={description}
              src={`${images[0]}`}
              // src={`${BE_URL}/images/${images[0]}`}
              onClick={e => navigateToPage()}
              sx={{
                top: 0,
                width: '100%',
                height: '100%',
                // width: '400px',
                // height: '400px',
                // objectFit: 'cover',
                objectFit: 'contain',
                objectPosition: 'center',
                position: 'absolute',
              }} />
          ) : null} */}
        </Box>
        }
        {true &&
          <Box
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              position: 'relative',
              backgroundColor: theme.palette.grey[900],
              minHeight: '18rem',
            }}
          >
            <FavouritesIcon car={car} />
            <ShareIcon car={car} />

            <CarCarouselImages
              car={car}
              isCarsPage
              numberOfImagesAllowed={5}
              onClickHandler={() => { }}
              sx={{
                top: 0,
                width: '100%',
                height: '100%',
                // objectFit: 'cover',
                objectFit: 'contain',
                position: 'absolute',
              }}
            />
          </Box>
        }

        <Stack
          spacing={1}
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            maxWidth: '32rem',
            // backgroundColor: 'purple'
          }}
        >
          <Typography
            color="text.primary"
            variant="h5"
            noWrap
            sx={{
              p: 1,
              // maxHeight: '45px'
              // width: {
              //   xs: '20%'
              // },
              // backgroundColor: 'yellow'
            }}
          >
            {/* {shortDescription} */}
            {/* {car.description} */}
            <Description description={car.description} />
          </Typography>


          <Box px={1}>
            <CarPriceInfo car={car} />
          </Box>

          <CarsKeySpecsSection car={car} basicInfo />

          <Box pl={1}>
            <Divider />
          </Box>
          <Box px={1}>
            <CarLocationInfo car={car} />
          </Box>
        </Stack>
      </Box>
    </Card>
  );
}
