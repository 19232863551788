import { useContext, useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { useIntl } from 'react-intl';
import Carousel from 'react-material-ui-carousel';

// @mui
import {
  Box,
  Typography,
  Stack,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// utils
// components
import SearchContext from '../../contexts/SearchContext';
import { CarStep1Tv, CarCardTopTv, CarCardContactInfoTv, CarImagesTV } from '.';
import { carsService } from '../../services/index';
import { formatFuelForTV } from '../../utils/domainValues';

// ----------------------------------------------------------------------

const StyledCarImg = styled('img')({
  objectFit: 'cover',
});

// ----------------------------------------------------------------------

CarTv.propTypes = {};

export default function CarTv() {
  const intl = useIntl();

  const theme = useTheme();
  const searchContext = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const [carVm, setCarVm] = useState();
  const [images, setImages] = useState([]);
  const [header, setHeader] = useState();
  const [step1, setStep1] = useState();
  const [step2, setStep2] = useState();
  const [contactInfo, setContactInfo] = useState();
  const [optionals, setOptionals] = useState();
  const [carLink, setCarLink] = useState();

  useEffect(() => {
    setLoading(true);
    const car = searchContext.results[searchContext.currentIndex || 0];

    if (car) {
      const carVm = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(car)) {
        carVm[key] = value;
      }

      carVm.make = `${car.make} ${car.model}`;
      carVm.gearbox = `${car.gearboxType} (${car.gearbox})`;
      carVm.fuel = formatFuelForTV(`${car.fuel}`);
      carVm.power =
        car.enginePowerHP && car.enginePowerKW
          ? `${car.enginePowerHP} ${intl.formatMessage({ id: 'commons.hp' })}  ${car.enginePowerKW} ${intl.formatMessage({ id: 'commons.kw' })}`
          : null;
      carVm.enginePowerHP = car.enginePowerHP
        ? `${car.enginePowerHP} ${intl.formatMessage({ id: 'commons.hp' })}`
        : null;
      carVm.enginePowerKW = car.enginePowerKW
        ? `${car.enginePowerKW} ${intl.formatMessage({ id: 'commons.kw' })}`
        : null;
      // carVm.co2 = `${car.co2} ${intl.formatMessage({ id: 'commons.gkm' })}  (${car.emissionClass})`
      carVm.co2 = `${car.co2} ${intl.formatMessage({ id: 'commons.gkm' })}`;
      carVm.engineSize = car.engineSize ? `${car.engineSize} ${intl.formatMessage({ id: 'commons.cc' })}` : null;
      carVm.mileage = `${car.mileage} ${intl.formatMessage({ id: 'commons.km' })}`;
      carVm.price =
        car.price && car.price
          ? `${car.price.final} ${intl.formatMessage({ id: 'commons.eur' })} ${intl.formatMessage({ id: 'car.price.withVAT' })}`
          : null; // intl.formatMessage({ id: 'commons.na' })
      carVm.auctionType = car.auctionType
        ? intl.formatMessage({ id: `car.auctionType.${car.auctionType}.label` })
        : null;
      carVm.countryOrigin = car.countryOrigin
        ? intl.formatMessage({ id: `car.countryOrigin.${car.countryOrigin}.label` })
        : null;

      const { reseller } = car

      if (reseller) {
        carVm.contactName = reseller.name;
        carVm.logo = reseller.logoUrl;
        // carVm.resellerLink = reseller.link; // TODO
        carVm.contactPhone = reseller.phone;
      } else {
        // carVm.contactName = reseller.name;
        // carVm.logo = reseller.logoUrl;
        // carVm.contactPhone = reseller.phone;
      }
      carVm.carLink = car.url
      setCarLink(carVm.carLink)

      setCarVm(carVm);
      // setCarLink(car.link ? car.link : REACT_APP_CAR_BLIP_URL)
      // setImages(car.images.slice(0, 15))
      setImages(car.images.slice(0, 5));

      const loadOptionals = async (vin) => {
        const { optionals } = await carsService.getCarByVIN(vin);
        setOptionals(
          optionals.filter(({ starred }) => starred)
          // .sort((a, b) => b.starred ? b : a)
        );
      };
      try {
        loadOptionals(car.vin);
      } catch (e) {
        // ignored
      }
    } else {
      setImages([]);
      setCarVm(null);
    }

    setLoading(false);
  }, [searchContext.currentIndex]);

  useEffect(() => {
    if (carVm) {
      setHeader(
        [
          'make',
          'description',
          // 'price',
          // 'auctionType',
          // 'countryOrigin',
          'firstRegistrationAsYear',
          'mileage',
          // 'vin',
          'countryOrigin',
        ].map((x) => ({
          label: x,
          value: carVm[x],
        }))
      );

      setStep1(
        [
          // 'firstRegistrationAsYear',
          // 'mileage',
          'power',
          'engineSize',
          // 'enginePowerKW',
          'gearbox',
          'fuel',
          'co2',
          'emissionClass',
          // 'auctionType',
          'doors',
          'numberOfPlaces',
          // 'countryOrigin',
          'vin',
        ].map((x) => ({
          label: x,
          value: carVm[x],
        }))
      );

      setStep2(
        [
          // 'mileage',
          'engineSize',
          'enginePowerHP',
          'countryOrigin',
          'gearbox',
          // 'power',
          'doors',
          'numberOfPlaces',
          // 'color',
        ].map((x) => ({
          label: x,
          value: carVm[x],
        }))
      );

      setContactInfo(
        ['price', 'carLink', 'logo', 'contactName', 'contactPhone'].map((x) => ({
          label: x,
          value: carVm[x],
        }))
      );
    } else {
      setHeader([]);
      setStep1([]);
      setStep2([]);
      setContactInfo([]);
    }
  }, [carVm]);

  // useEffect(() => {
  //   preloadImages(images
  //     .slice(0, 20)
  //     .map(item => `${BE_URL}/images/${item}`)
  //   )
  // }, [images])

  if (!carVm) {
    return (
      <>
        <Skeleton variant="rectangular" width={'100%'} height={'60%'} />
        <Skeleton variant="rectangular" width={'100%'} height={'20%'} />
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 4,
          // backgroundColor: 'pink'
        }}
      >
        <Box
          sx={{
            width: '32%',
            // height: '96%',
            alignContent: 'space-between',
            // alignContent: 'flex-start',
            // backgroundColor: 'yellow',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
          }}
        >
          <Stack
            sx={{
              // height: '15vh',
              // maxHeight: '40vh',
              alignContent: 'top',
              // backgroundColor: 'red',
              flexGrow: 2,
            }}
          >
            <CarCardTopTv carLink={carLink} step={header} />

            <CarStep1Tv step={step1} optionals={optionals} />
            {/* <Carousel animation='fade' indicators={false}>
              <CarStep1Tv step={step2} /> 
            </Carousel> */}
            {false && optionals && optionals.length > 0 ? (
              <Stack
                spacing={1}
                sx={{
                  width: '100%',
                  px: 3,
                  // px: 3,
                  pt: 1,
                }}
              >
                <Carousel interval={1800} animation="fade" indicators={false}>
                  {optionals
                    // .filter(({ starred }) => starred)
                    .map(({ name, starred }) => (
                      <Box key={name} sx={{ pr: 5, display: 'flex', alignItems: 'baseline' }}>
                        <Typography component={'span'} variant="h6">
                          {/* {starred ? (
                            <StarIcon sx={{ color: 'gold' }} />
                          ) : null} */}
                          {name}
                        </Typography>
                      </Box>
                    ))}
                </Carousel>
                <Typography component={'div'} variant="body1">
                  Optionals
                </Typography>
              </Stack>
            ) : null}
          </Stack>
          {/* <Box sx={{
            flexGrow: 1,
            backgroundColor: 'green'
          }} /> */}
          <Box
            sx={{
              flexGrow: 1,
              // height: '20vh',
              alignContent: 'end',
              // backgroundColor: 'blue'
              mb: 1,
            }}
          >
            <CarCardContactInfoTv step={contactInfo} />
          </Box>
        </Box>

        <Box sx={{
          // backgroundColor: 'red',
          width: '65%'
        }}>
          {!loading ? (
            <CarImagesTV vin={carVm.vin} images={images} />
          ) : null
            // <Skeleton height={'100%'} width={'100%'} />
          }
        </Box>
      </Box>
    </>
  );
}
