import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { TextField, Button, Box, Divider, Typography, InputAdornment, IconButton } from '@mui/material';
import { resellersService } from '../../services';
import Iconify from '../../components/iconify';
import { passwordRegexp, email } from '../../config/config';
import PageContext from '../../contexts/PageContext';

const initialValues = {
  companyName: '',
  vat: '',
  addressLine1: '',
  addressLine2: '',
  country: '',
  phoneNumber: '',
  contactEmail: '',
  web: '',
  priceIncrementFlat: ''
}

const validationSchema = Yup.object({
  companyName: Yup.string().required('Company name e is required'),
  vat: Yup.string().required('VAT nyumber is required'),
  addressLine1: Yup.string().required('Address line 1 is required'),
  addressLine2: Yup.string(),
  country: Yup.string().required('Country is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  contactEmail: Yup.string().email().required('Contact email is required'),
  web: Yup.string(),
  priceIncrementFlat: Yup.number(),
});

const CompanyProfileForm = ({ reseller, setFormProcessed }) => {
  const navigate = useNavigate();
  const intl = useIntl()
  const pageContext = useContext(PageContext)
  const { enqueueSnackbar } = useSnackbar();

  const hasReseller = reseller.id
  const hasStatusRequested = reseller && ['REQUESTED'].includes(reseller.status)
  const hasStatusRejected = reseller && ['REJECTED'].includes(reseller.status)
  const hasStatusApproved = reseller && ['APPROVED'].includes(reseller.status)
  // const [effectiveInitialValues, setEffectiveInitialValues] = useState({})
  const [effectiveInitialValues, setEffectiveInitialValues] = useState({
    ...initialValues,
    ...(reseller ? reseller.contactInfo : {}),
    priceIncrementFlat: reseller && reseller.priceIncrement && reseller.priceIncrement.flat
  })

  useEffect(() => {
    if (reseller && reseller.contactInfo) {
      setEffectiveInitialValues({
        ...initialValues,
        ...reseller.contactInfo,
        priceIncrementFlat: reseller.priceIncrement && reseller.priceIncrement.flat
      })
    }
  }, [reseller])

  return (!reseller ? null : (
    <Formik
      initialValues={effectiveInitialValues}
      // initialValues={effectiveInitialValues(reseller)}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors = {};
        const isFormFilled = Object.values(values).some(value => value !== '');
        if (isFormFilled) {
          // You can add custom validation here if needed
          // For example, you might want to validate pre-filled values differently
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('Form Data:', values);
        const {
          companyName,
          vat,
          addressLine1,
          addressLine2,
          country,
          phoneNumber,
          contactEmail,
          web,
          priceIncrementFlat,
        } = values
        try {
          if (hasReseller) {
            if (hasStatusApproved) {
              const response = await resellersService.patchReseller(reseller.id,
                {
                  contactInfo: {
                    phoneNumber,
                    contactEmail,
                  },
                  priceIncrement: {
                    flat: priceIncrementFlat
                  }
                })
              enqueueSnackbar(intl.formatMessage({ id: 'page.company-profile.snackbar.price-updated' }))
            } else if (hasStatusRequested) {
              const response = await resellersService.patchReseller(reseller.id,
                {
                  contactInfo: {
                    companyName,
                    vat,
                    addressLine1,
                    addressLine2,
                    country,
                    phoneNumber,
                    contactEmail,
                    web,
                  }
                })
              setFormProcessed(true)
            }

          } else {
            const response = await resellersService.postReseller({
              contactInfo: {
                companyName,
                vat,
                addressLine1,
                addressLine2,
                country,
                phoneNumber,
                contactEmail,
                web,
              }
            })
            setFormProcessed(true) // navigate from parent component
            enqueueSnackbar(intl.formatMessage({ id: 'page.company-profile.snackbar.confirmed' }))
            // console.log('company-profile response', response)
          }
          setSubmitting(false); // Manually set submitting to false after submission
        } catch (err) {
          // navigate('/');
          console.log('err', err)
        }
      }}
    >
      {({ values, isSubmitting, isValid, errors, touched, setFieldValue }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          Object.keys(effectiveInitialValues)
            .forEach(field => setFieldValue(field, effectiveInitialValues[field], false))
        }, [effectiveInitialValues])

        return <Form>
          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              disabled={hasStatusApproved || hasStatusRejected}
              name="companyName"
              type="text"
              label="Company Name"
              variant="outlined"
              helperText={<ErrorMessage name="companyName" />}
              error={errors.companyName}
              touched={touched.companyName}
            />
          </Box>
          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              disabled={hasStatusApproved || hasStatusRejected}
              name="vat"
              type="text"
              label="VAT number"
              variant="outlined"
              helperText={<ErrorMessage name="vat" />}
              error={errors.vat}
              touched={touched.vat}
            />
          </Box>
          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              disabled={hasStatusApproved || hasStatusRejected}
              name="addressLine1"
              type="text"
              label="Address Line 1"
              variant="outlined"
              helperText={<ErrorMessage name="addressLine1" />}
              error={errors.addressLine1}
              touched={touched.addressLine1}
            />
          </Box>
          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              disabled={hasStatusApproved || hasStatusRejected}
              name="addressLine2"
              type="text"
              label="Address Line 2"
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              disabled={hasStatusApproved || hasStatusRejected}
              name="country"
              type="text"
              label="Country"
              variant="outlined"
              helperText={<ErrorMessage name="country" />}
              error={errors.country}
              touched={touched.country}
            />
          </Box>
          <Box mb={2}>
            <Field
              as={TextField}
              disabled={hasStatusApproved || hasStatusRejected}
              fullWidth
              name="web"
              type="text"
              label="Web page"
              variant="outlined"
            />
          </Box>

          <Divider sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <FormattedMessage id='page.company-profile.subtitle.contact-info' />
            </Typography>
          </Divider>
          <Box sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <FormattedMessage id='page.company-profile.subtitle2.contact-info' />
            </Typography>
          </Box>

          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              disabled={hasStatusRejected}
              type="email"
              name="contactEmail"
              label="Contact Email"
              variant="outlined"
              helperText={<ErrorMessage name="contactEmail" />}
              error={errors.contactEmail}
              touched={touched.contactEmail}
            />
          </Box>
          <Box mb={2}>
            <Field
              as={TextField}
              fullWidth
              disabled={hasStatusRejected}
              name="phoneNumber"
              label="Phone Number"
              type="text"
              variant="outlined"
              helperText={<ErrorMessage name="phoneNumber" />}
              error={errors.phoneNumber}
              touched={touched.phoneNumber}
            />
          </Box>


          {hasStatusRequested || hasStatusRejected ? (
            <FormattedMessage id="page.company-profile.amend.message" values={{ email: email.registration }} />
          ) : null
          }

          {hasStatusApproved ? (
            <>
              <Divider sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  <FormattedMessage id='page.company-profile.subtitle.price-settings' />
                </Typography>
              </Divider>
              <Box mb={2}>
                <Field
                  as={TextField}
                  fullWidth
                  name="priceIncrementFlat"
                  type="number"
                  label="Price Increment"
                  variant="outlined"
                />
              </Box>
              <Box mt={3}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  loading={isSubmitting}>
                  <FormattedMessage id="page.company-profile.button.price-increment" />
                </LoadingButton>
              </Box>
            </>
          ) : null
          }

          {!hasReseller || hasStatusRequested ? (
            <Box mt={3}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isValid}
                loading={isSubmitting}>
                {hasStatusRequested ? (
                  <FormattedMessage id="page.company-profile.button.update-requested-status" />
                ) : (
                  <FormattedMessage id="page.company-profile.button.register" />
                )}
              </LoadingButton>
            </Box>
          ) : null}

        </Form >
      }}
    </Formik >
  ))
};

export default CompanyProfileForm;
