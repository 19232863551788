import ReactDOM from 'react-dom/client';
//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------
// see https://duckduckgo.com/?q=react+locale+change&t=brave&ia=web&iax=qa for a complete solution
// React-Intl how to switch locale and messages from variable
// http://stackoverflow.com/questions/44635584/ddg#44747555

// init({
//     // options
// })

// init({
//     debug: false,
//     visualDebug: false
//   });
// initNavigation({
//     debug: true,
//     visualDebug: true
// });

// // Optional
// setKeyMap({
//     'left': 9001,
//     'up': 9002,
//     'right': 9003,
//     'down': 9004,
//     'enter': 9005
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
