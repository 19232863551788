import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Label from '../label';
import { Price, Mileage, Fuel, EmissionClass, FirstRegistration, EnginePower, Gearbox } from '../car-labels';

KeySpecsStack.propTypes = {
    car: PropTypes.object.isRequired,
    big: PropTypes.bool,
};

export default function KeySpecsStack({ intl, car, sx }) {
    const { mileage, fuel, emissionClass, firstRegistrationAsYear, enginePowerHP, gearboxType } = car
    const labelVariant = "ghost"
    return (
        <Stack sx={sx} spacing={.1}>
            {firstRegistrationAsYear ? <Label
                dimension="small"
                variant="filled"
                color="primary">
                <FirstRegistration firstRegistrationAsYear={firstRegistrationAsYear} />
            </Label> : null}
            {mileage ? <Label
                dimension="small"
                variant="filled"
                color="primary">
                <Mileage mileage={mileage} />
            </Label> : null}
            {fuel ? <Label
                dimension="small"
                variant="filled"
                color="primary">
                <Fuel fuel={fuel} />
            </Label> : null}
            {gearboxType ? <Label
                dimension="small"
                variant="filled"
                color="primary">
                <Gearbox gearboxType={gearboxType} />
            </Label> : null}
            {enginePowerHP ? <Label
                dimension="small"
                variant="filled"
                color="primary">
                <EnginePower powerValue={enginePowerHP} symbol={'hp'} />
            </Label> : null}
            {false && emissionClass ? <Label
                dimension="small"
                variant="filled"
                color="primary">
                <EmissionClass emissionClass={emissionClass} />
            </Label> : null}
        </Stack>
    );
}