import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
// @mui
import { Typography, Slider } from '@mui/material';
// components
import SearchContext from '../../contexts/SearchContext';
import { filterUnique } from '../../utils/filterUnique';
import { DEFAULT_FIRST_REGISTRATION_RANGE } from '../../config/config';

// ----------------------------------------------------------------------

FirstRegistrationYearFilter.propTypes = {};

export default function FirstRegistrationYearFilter() {
  const searchContext = useContext(SearchContext);
  const { firstRegistrationYearRange, setFirstRegistrationYearRange, setFirstRegistrationYearFilters } = searchContext;
  const [firstRegistrationYearMin, firstRegistrationYearMax] = DEFAULT_FIRST_REGISTRATION_RANGE;

  const marks = [
    {
      value: firstRegistrationYearMin,
      label: firstRegistrationYearMin,
    },
    {
      value: firstRegistrationYearMin + Math.round((firstRegistrationYearMax - firstRegistrationYearMin) * 0.25),
      label: firstRegistrationYearMin + Math.round((firstRegistrationYearMax - firstRegistrationYearMin) * 0.25),
    },
    {
      value: firstRegistrationYearMin + Math.round((firstRegistrationYearMax - firstRegistrationYearMin) * 0.5),
      label: firstRegistrationYearMin + Math.round((firstRegistrationYearMax - firstRegistrationYearMin) * 0.5),
    },
    {
      value: firstRegistrationYearMin + Math.round((firstRegistrationYearMax - firstRegistrationYearMin) * 0.75),
      label: firstRegistrationYearMin + Math.round((firstRegistrationYearMax - firstRegistrationYearMin) * 0.75),
    },
    {
      value: firstRegistrationYearMax,
      label: firstRegistrationYearMax,
    },
  ];

  const registrationYearValuetext = (value) => `${value}`;
  const registrationYearLabel = (registrationYearRange) => {
    const years = registrationYearRange.filter(filterUnique);
    return years.length > 1 ? `Registration years: ${years.join('->')}` : `Registration year: ${years.join('->')}`;
  };

  const handleFirstRegistrationYearRangeChange = (event, newRange) => {
    setFirstRegistrationYearRange(newRange);
    searchContext.updateRangeAndFilter(newRange, DEFAULT_FIRST_REGISTRATION_RANGE, 'firstRegistrationYear');
  };

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
      <FormattedMessage id="filter.firstRegistration.title" />
      </Typography>
      {/* <Box>&nbsp;</Box> */}
      <Slider
        valueLabelDisplay="auto"
        min={firstRegistrationYearMin}
        max={firstRegistrationYearMax}
        step={1}
        getAriaLabel={() => 'Registration year range'}
        value={firstRegistrationYearRange}
        onChange={handleFirstRegistrationYearRangeChange}
        marks={marks}
        getAriaValueText={registrationYearValuetext}
      />
      {/* <Typography variant="subtitle2" gutterBottom>
        {registrationYearLabel(firstRegistrationYearRange)}
      </Typography> */}
    </div>
  );
}
