import { Box } from '@mui/material';
import PropTypes from 'prop-types';
// @mui

// ----------------------------------------------------------------------

EmissionClass.propTypes = {
  emissionClass: PropTypes.string,
};

export default function EmissionClass({ intl, emissionClass }) {
  return (
    <>{emissionClass}</>
  );
}
