import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { useTheme } from '@emotion/react';
import { Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import Header from './header/HeaderWeb';
import Footer from './footer/FooterWeb';
import { getUITarget } from '../../services/asyncStorage.service';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  // minHeight: '20vh',
  minHeight: '40vh',
  // height: '40vh',
  // minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    // minHeight: '70vh',
    // paddingTop: APP_BAR_DESKTOP,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

const Secondary = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  // minHeight: '20vh',
  // minHeight: '30vh',
  // height: '30vh',
  // backgroundColor: 'turquoise',
  // minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE,
  // paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    // minHeight: '70vh',
    // paddingTop: APP_BAR_DESKTOP,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

// const StyledHeader = styled('header')(({ theme }) => ({
//   top: 0,
//   left: 0,
//   lineHeight: 0,
//   width: '100%',
//   position: 'absolute',
//   padding: theme.spacing(3, 3, 0),
//   [theme.breakpoints.up('sm')]: {
//     padding: theme.spacing(5, 5, 0),
//   },
// }));

// const ProductHeroLayout = styled('section')(({ theme }) => ({
//   color: theme.palette.common.white,
//   position: 'relative',
//   display: 'flex',
//   alignItems: 'center',
//   [theme.breakpoints.up('sm')]: {
//     height: '80vh',
//     minHeight: 500,
//     maxHeight: 1300,
//   },
// }));

// ----------------------------------------------------------------------

export default function SimpleLayoutMobile(props) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const location = useLocation();

  // useEffect(() => {
  //   const pathAsArray = location.pathname.split('/').filter(x => !!x)
  //   const lastPathPart = pathAsArray.pop()
  //   const previousPathPart = pathAsArray.pop()
  //   setIsCarsPage(['cars'].includes(lastPathPart) || ['car'].includes(previousPathPart))
  // }, [location])

  const uiTarget = getUITarget();

  return (
    <>
      {/* <Nav openNav={open} onCloseNav={() => setOpen(false)} /> */}
      <Header
        onOpenNav={() => setOpen(true)}
        onChangeLanguage={props.onChangeLanguage}
        currentLanguage={props.currentLanguage}
      />
      <Main>
        <Secondary>
          {/* <Box sx={{
            display: {
              xs: 'block',
              md: 'none',
              lg: 'block'
            }
          }}>
            <CarsFeaturedSection />
          </Box> */}
        </Secondary>
        <Box xs={{ width: '100%' }}>
          <Outlet />
        </Box>
        {/* <FooterTv /> */}
      </Main>
      <Footer />
    </>
  );
}
