import { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Popover } from '@mui/material';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg',
  },
  {
    value: 'de',
    label: 'German',
    icon: '/assets/icons/ic_flag_de.svg',
  },
  {
    value: 'fr',
    label: 'French',
    icon: '/assets/icons/ic_flag_fr.svg',
  },
  {
    value: 'it',
    label: 'Italian',
    icon: '/assets/icons/ic_flag_it.svg',
  },
  {
    value: 'nl',
    label: 'Dutch',
    icon: '/assets/icons/ic_flag_nl.svg',
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover({ onChangeLanguage, currentLanguage, isTv = false }) {
  const [open, setOpen] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(LANGS[0]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLanguageChange = (lang) => {
    onChangeLanguage(lang);
    handleClose();
  };

  useEffect(() => {
    setSelectedLanguage(LANGS.find(({ value }) => value === currentLanguage) || LANGS[0])
  }, [currentLanguage])

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={isTv ? {
          // width: 'auto',
          // height: '100%',
          // bgcolor: 'red'
        } : {
          padding: 0,
          width: 44,
          height: 32,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        {isTv
          ? <img src={selectedLanguage.icon} alt={selectedLanguage.label} height={32} />
          : <img src={selectedLanguage.icon} alt={selectedLanguage.label} />
        }
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLanguage}
              onClick={() => handleLanguageChange(option.value)}
            >
              <Box component="img" alt={option.label} src={option.icon} sx={isTv ? { height: 32, mr: 2 } : { width: 28, mr: 2 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
